import { authenticationService } from "../Services/auth.service";

export class CategoryModel {

    products = [];
    constructor(categoryObject){
        this.status = categoryObject.status || 'success';
        this.categoryName = categoryObject.categoryName || '';
        this.hide = categoryObject.hide || false;
        this.id = categoryObject.categoryId || categoryObject.id || null;
        this.activeTime = categoryObject.activeTime || '';
        this.userId = categoryObject.userId || '';
        this.action = categoryObject.action || null;
        if(categoryObject.timestamp) {
            this.timestamp = categoryObject.timestamp 
        }
        if(categoryObject.products && categoryObject.products.length) {
            let allProducts = categoryObject.products
            allProducts.forEach((product, index) => {
                this.products.push(new ProductModel(product));
            });

        } else {
            this.products = [];
        }
        // this.products = new Product(categoryObject.products) || [];
    }

}

export class ProductModel {
    constructor(productObject){
        this.status = productObject.status || 'success';
        this.id = productObject.id || null;
        this.categoryId = productObject.categoryId || '';
        this.activeTime = productObject.activeTime || '';
        this.extraInformation = productObject.extraInformation || '';
        this.hide = productObject.hide || false;
        this.icons = productObject.icons || [];
        this.images = productObject.images || [];
        this.information = productObject.information || '';
        this.name = productObject.name || '';
        this.price = productObject.price || '';
        this.productNum = productObject.productNum || '';
        this.duration = productObject.duration || '';
        this.action = productObject.action || null;
        if(productObject.timestamp) {
            this.timestamp = productObject.timestamp;
        }
    }

}


export class TableModel {
    orders = [];
    constructor(tableObject) {
        this.PK = tableObject.PK || `TABLEOBJECT#${tableObject.userId}#${tableObject.tableName}`;
        this.id = tableObject.PK || null;
        this.SK =  tableObject.SK || null;
        this.allOrdersFinished = tableObject.allOrdersFinished || false;
        this.hasCustomerCall = tableObject.hasCustomerCall || false;
        this.isOverTime =  tableObject.isOverTime || false;
        this.hasNotification = tableObject.hasNotification || false;
        this.tableName = tableObject.tableName;
        this.userId = tableObject.userId || authenticationService.userID;
        if(tableObject.orders && tableObject.orders.length) {
            let allOrders = tableObject.orders;
            let orderOvertime = false; 
            let isCooking = false;
            allOrders.forEach((order, index) => {
                let orderModel = new OrderModel(order);
                if(orderModel.overTime) {
                    orderOvertime = true;
                    if(orderModel.isCooking){
                        isCooking = true;
                    }
                }
                this.orders.push(orderModel);
            });
            this.isOverTime = (!this.allOrdersFinished && orderOvertime && isCooking) ? true : false; 
            // this.isOverTime = tableObject.isOverTime ? true : (!this.allOrdersFinished && orderOvertime && isCooking) ? true : false; 

        } else {
            this.orders = [];
        }
    }
}

export class OrderModel {
    preparationStartTime = 0;
    overTime = false;
    cookingTime = null;
    constructor(orderObject) {
        this.orderId = orderObject.orderId; 
        this.finishedItemTs = orderObject.finishedItemTs; 
        this.incomingOrderTs = orderObject.incomingOrderTs;
        this.isCompleted = orderObject.isCompleted;
        this.payMethod = orderObject.payMethod;
        this.price = orderObject.price;
        // this.processedTime = orderObject.processedTime;
        // this.preparationStartTime = orderObject.productDurationTime || 0;
        
    
        
        this.productId = orderObject.productId;
        this.productName = orderObject.productName;
        this.productNum = orderObject.productNum;
        this.quantity = orderObject.quantity;
        this.tableName = orderObject.tableName;
        this.timestampStart = orderObject.timestampStart;
        this.userId = orderObject.userId || authenticationService.userID;
        this.wish = orderObject.wish;
        this.isCooked = orderObject.isCooked || false;
        this.isCooking = orderObject.isCooking || false;
        this.isPaid = orderObject.isPaid ;
        this.productDurationTime = orderObject.productDurationTime

        let now = new Date().getTime();
        // productDurationTime is in seconds
        const timeDifference = Math.floor((now - orderObject.incomingOrderTs) / 1000); // in seconds
        // If order started on time 
        // if order viewed after 5 min
        // if order views after all order duration
        this.overTime = orderObject.isOverTime || false;
        this.overTimeDurationTime = 0;
        this.itemCookedTime = 0;

        if(orderObject.productDurationTime && orderObject.productDurationTime !== 0 && orderObject.productDurationTime !== 1 ) {
            this.cookingTime = orderObject.productDurationTime;
            let startTime = orderObject.productDurationTime > timeDifference ? (orderObject.productDurationTime - timeDifference ) : 0;
            // console.log(startTime);
            this.preparationStartTime = startTime * 1000;

            // Overriding overtime if it is not calculated before
            // no status for order if product Duration is not there 
            // if start time is zero that means product is ovetime 
            // for already cooked products
           /*  if(orderObject.orderId === '2OIP32Y')
            debugger */
            this.overTime = (this.preparationStartTime === 0) ? true : false;
            // console.log(this.preparationStartTime)

            if(this.finishedItemTs){
                // console.log((this.finishedItemTs - this.incomingOrderTs)/1000);
                // console.log(this.productDurationTime);
                this.overTime = ((this.finishedItemTs - this.incomingOrderTs)/1000) > this.productDurationTime ? true : false;
            }

            this.productFinishTime = orderObject.incomingOrderTs + (orderObject.productDurationTime * 1000);

            if(this.overTime) {
                // to check , is product not finished and timer need to run and product finihsed and only overtime to be shown
                this.overTimeDurationTime = timeDifference > orderObject.productDurationTime ? (Math.floor((now - this.productFinishTime)) ) : 0;
                if(this.isCooked) {
                    this.overTimeDurationTime = Math.floor((this.finishedItemTs - this.incomingOrderTs) - (orderObject.productDurationTime*1000))
                }
                // console.log(this.overTimeDurationTime/1000);
            }

            
            // Overide preparation time if order item was prepared within time
            if(this.preparationStartTime === 0 && !this.overTime && this.finishedItemTs){
                // console.trace("----");
                this.preparationStartTime = Math.floor(this.finishedItemTs - this.incomingOrderTs);
            }
        }else {
            this.cookingTime = null;
        }

        

        this.orderDurationTime = orderObject.orderDurationTime || null;

        
        
        
      
    }
}


export class Overtime { 
    static isTableOvertime(orders) {
        let allOrders = orders;
        let orderOvertime = false; 
        let isCooking = false;
        let isOverTime = false;
        allOrders.forEach((order, index) => {
            let orderModel = new OrderModel(order);
            if(orderModel.overTime) {
                orderOvertime = true;
                if(orderModel.isCooking){
                    isCooking = true;
                }
            }
        });
        isOverTime = (orderOvertime && isCooking) ? true : false;
        return isOverTime; 
    }
}