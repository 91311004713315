import { APP_CONFIG } from '../constants/Config'

const imagePath = APP_CONFIG.IMAGE_PATH;

export const ICONS = {
    LOGO_ICON: `${imagePath}/logo.svg`,
    EDIT_ICON_DARK: `${imagePath}/editAsset-1.svg`,
    HIDE_ICON: `${imagePath}/hideAsset-1.svg`,
    // UNHIDE_ICON: `${imagePath}/unhideAsset-1.svg`,
    DELETE_ICON: `${imagePath}/trash.svg`,
    TRASH_DARK_ICON: `${imagePath}/tarshB2_dAsset-7.svg`,
    LEFT_ARROW_ICON: `${imagePath}/left.svg`,
    RIGHT_ARROW_ICON: `${imagePath}/right.svg`,
    INFO_ICON: `${imagePath}/info_lAsset-1.svg`,
    HOME_ICON_LIGHT: `${imagePath}/home_lAsset-5.svg`,
    BELL_ICON: `${imagePath}/bell_lAsset-1.svg`,
    CLOSE_ICON: `${imagePath}/close_dAsset-1.svg`,
    CLOSE_RED_ICON: `${imagePath}/achtung_rAsset-1.svg`,
    QUESTION_ICON: `${imagePath}/why_gAsset-1.svg`,
    QUESTION_CIRCLE_ICON: `${imagePath}/ask_dAsset-2.svg`,
    UNHIDE_ICON: `${imagePath}/unhide_lAsset-2.svg`,
    HOME_ICON: `${imagePath}/home_s_dAsset-6.svg`, // DUPLICATE
    USER_ICON: `${imagePath}/accountAsset-1.svg`,
    USER_HOVER_ICON: `${imagePath}/user_full_dAsset-1.svg`,
    MINUS_ICON: `${imagePath}/minus_dark.svg`,
    PLUS_ICON: `${imagePath}/plus_dark.svg`,
    QR_CODE_ICON: `${imagePath}/qr_lAsset-5.svg`,
    QR_CODE_DARK_ICON: `${imagePath}/qr_dAsset-6.svg`,
    EDIT_ICON: `${imagePath}/edit_lAsset-2.svg`,
    HIDE_LIGHT_ICON: `${imagePath}/hide_lAsset-1.svg`,
    HIDE_DARK_ICON: `${imagePath}/hideB_dAsset-1.svg`,
    TRASH_LIGHT_ICON: `${imagePath}/trash_light.svg`,
    LOCK_ICON: `${imagePath}/lock_dAsset-1.svg`,
    TRASH_RED: `${imagePath}/trash_rAsset-2.svg`,
    GLOBE_ICON: `${imagePath}/globe_l_bAsset-6.svg`,
    THUMBS_UP_ICON: `${imagePath}/thumbsup_lAsset-1.svg`,
    BELL_DARK_ICON: `${imagePath}/bell_dAsset-3.svg`,
    CHECK_LIGHT_ICON: `${imagePath}/check.svg`,
    CHECK_DARK_ICON: `${imagePath}/check_dark.svg`,
    TIMER_RED_ICON: `${imagePath}/time_redAsset-1.svg`,
    TIMER_DARK_ICON: `${imagePath}/time_dAsset-3.svg`,
    CURRENCY_EURO_ICON: `${imagePath}/pay-asset.svg`,
    CURRENCY_DOLLAR_ICON: `${imagePath}/paycash2Asset-1.svg`,
    CURRENCY_DOLLAR_WHITE: `${imagePath}/paycash2Asset-white.svg`,
    SETTINGS_ICON: `${imagePath}/settings_dark.svg`,
    HOME_LIGHT_ICON: `${imagePath}/home.svg`,
    LINES_ICON: `${imagePath}/bars_lAsset-1.svg`,
    CART_ICON: `${imagePath}/cart.svg`,
    GASTRON_ICON: `${imagePath}/gastro.png`,
    SETTINGS_DARK: `${imagePath}/settings_baldAsset-4.svg`, // NEED TO CHANGE
    PAYPAL_ICON: `${imagePath}/paypal2Asset-1.svg`,
    PAYPAL_COLORED_ICON: `${imagePath}/paypalAsset-1.svg`,
    GLOBE_OUTLINE_LIGHT: `${imagePath}/globe_l_tAsset-5.svg`,
    EARTH_ICON: `${imagePath}/globeAsset-1.svg`,
    YOUTUBE_ICON: `${imagePath}/youtube_icon_rAsset-1.svg`,
    INSTAGRAM_ICON: `${imagePath}/insta_iconAsset-2.svg`,
    FACEBOOK_ICON: `${imagePath}/facebook_iconAsset-1.svg`,
    CALENDER_ICON: `${imagePath}/calAsset-1.svg`,
    REFRESH_LIGHT_ICON: `${imagePath}/refresh_lAsset-1.svg`,
    REFRESH_ICON: `${imagePath}/refresh_dAsset-3.svg`,
    COMMENT_ICON: `${imagePath}/comment.svg`,
    IMAGE_PLACEHOLDER: `${imagePath}/picture_dAsset-1.svg`,
    BAR_CODE_TILTED: `${imagePath}/qr_l_30Asset-2.svg`,
    ARROW_LEFT: `${imagePath}/arrow_left_gAsset-1.svg`,
    FEATHER_ICON: `${imagePath}/feather_lAsset-4.svg`,
    HOTLINE_ICON: `${imagePath}/phone_lAsset-1.svg`,
    CASH_ICON: `${imagePath}/cash_pay_lAsset-1.svg`,
    GASTRON_ICON_BLACK: `${imagePath}/Logo_Main_DAsset-1.svg`,
    TABLE_ICON: `${imagePath}/table_one_dAsset-1.svg`,
    GASTRON_LOGO2: `${imagePath}/myin_logo_clear_lAsset-2.svg`,
    HOME_ICON_LANDING: `${imagePath}/house_small_lightAsset-1.svg`,
    LOCK_ICON_LIGHT: `${imagePath}/lock_lAsset-2.svg`,
    BACK_ICON: `${imagePath}/arrow_back.svg`,
    BELL_ICON_WHITE: `${imagePath}/bell_small_lAsset-1.svg`,
    KEY_ICON_WHITE: `${imagePath}/key_lAsset-1.svg`,
    EMAIL_ICON: `${imagePath}/letter_dAsset-2.svg`,
    CLOCK_WHITE: `${imagePath}/clock_lAsset-1.svg`
    


}