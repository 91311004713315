import React from 'react';
import MenuContext from '../../Providers/Menu/MenuContext';
import { ICONS } from '../../Helpers/Icon.service';
import { Logger } from '../../Helpers/Logger.service';
import { USER_ACTIONS, SYNC_STATUS } from '../../constants/app.constant';
import { validateActiveDay, validateActiveTime } from '../../Helpers/Utils';
import { Trans } from 'react-i18next';


export const Product = (props) => {
    const editIcon = ICONS.SETTINGS_DARK;
    const hideIcon = ICONS.HIDE_DARK_ICON;
    const deleteIcon = ICONS.TRASH_DARK_ICON;
    const unhideIcon = ICONS.UNHIDE_ICON;
    const timerIcon = ICONS.TIMER_DARK_ICON;
    const refreshIcon = ICONS.REFRESH_ICON;
    const clockIcon = ICONS.CLOCK_WHITE;

    const ACTIONS = USER_ACTIONS;
    const product = props.item;
    let isSelected = props.isSelected;
    let isHidden = props.item.hide;

    const selectProduct = (event, action = ACTIONS.SELECT) => {
        event.stopPropagation();
        props.selectProduct(product, action);
    }

    const onEditProduct = (event) => {
        selectProduct(event, ACTIONS.EDIT);
    }

    const onHideProduct = (event) => {
        selectProduct(event, ACTIONS.HIDE);
    }

    const onDeleteProduct = (event) => {
        selectProduct(event, ACTIONS.DELETE);

    }

    const onSync = (event) => {
        Logger.log("::onSync::");
        selectProduct(event, ACTIONS.SYNC);
    }


    const getImageStyles = () => {
        let styles = {};
        if(product.images && product.images.length) {
            styles = {
                backgroundImage: `url(${product.images[0]}`
            }
        }
        return styles;

    }

    const getProductAvailability = (product) => {

       const activeDaysAndTime = (weekdays, timeBegin, timeEnd) => {
            let mergedWeekdays = Object.keys(weekdays).filter(item => weekdays[item] === true ).map(w => w.charAt(0).toUpperCase() + w.slice(1) ).join('; ');

            const hiddenOverlay = <div className="limited_product">
                <img src={clockIcon} loading="lazy" width="22" height="22" alt="" className="image-208" />
                <div className="text-block-63-hidden">{/* {product.name}<br /><Trans>pages.menu.limited</Trans><br /><br /> */}{mergedWeekdays}<br />{timeBegin} - {timeEnd}</div>
            </div>;

            return hiddenOverlay;
       }

        if(product.activeTime && product.activeTime.weekdays && !validateActiveDay(product.activeTime.weekdays)) {
            return activeDaysAndTime(product.activeTime.weekdays, product.activeTime.timeBegin, product.activeTime.timeEnd);
        }

        // timeBegin, timeEnd
        if(product.activeTime && !validateActiveTime(product.activeTime.timeBegin, product.activeTime.timeEnd)) {
            return activeDaysAndTime(product.activeTime.weekdays, product.activeTime.timeBegin, product.activeTime.timeEnd);
        }

        return null
    }


    return (
        <React.Fragment>
            <MenuContext.Consumer>
                {context => (
                    <React.Fragment>
                        {
                            console.log(context)
                        }
                        <div af-el="item" onClick={(e) => selectProduct(e)} style={getImageStyles()} className={"div-block-402" + ((!product.images || !product.images.length) ? ' placeholder-nopicture' : '')}>
                            <div className="text-block-442">{product.price || 0}€</div>
                            <div className="text-block-442-copy">{product.productNum}</div>
                            <div className={"div-block-403" + (isSelected ? '-selected' : '-copy')}>
                                <div af-sock="text" className="text-block-63">{product.name}</div>
                                <div className="div-block-405-copy">
                                    {product.status === SYNC_STATUS.INPROGRESS && <div className="div-block-996">
                                        <img src={timerIcon} className="syncing" loading="lazy" width="22" height="22" data-w-id="915cced2-eb0a-236c-2ef0-7028dc40b996" />
                                    </div>}
                                    <div af-sock="edititem" onClick={(e) => onEditProduct(e)} className="button_edit_item">
                                        <img src={editIcon} loading="lazy" width="19" height="19" />
                                    </div>
                                    <div af-sock="hide" onClick={(e) => onHideProduct(e)} className="button_hide2">
                                        <img src={hideIcon} loading="lazy" height="19" />
                                    </div>
                                    <div af-sock="delete" onClick={(e) => onDeleteProduct(e)} className="button_delete2">
                                        <img src={deleteIcon} loading="lazy" width="19" height="19" />
                                    </div>
                                   
                                    {(product.status === SYNC_STATUS.ERROR || product.status === SYNC_STATUS.SYNC)  && <div className="div-block-996-error">
                                        <div af-sock="text" className="errortxt">error</div>
                                        <img src={refreshIcon} className={"image-158 " + (product.status === SYNC_STATUS.SYNC ? 'syncing': '')}
                                            onClick={(e) => onSync(e)} loading="lazy" width="22" height="22" data-w-id="7668d0d1-215a-f063-4226-9c7070475468"  />
                                        </div>}
                                </div>
                            </div>
                            {getProductAvailability(product)}
                            {product && product.hide && <div className={"hidespe-2"+ (isSelected ? '-selected' : '')}>
                            <div af-sock="text" className="text-block-63">{product.name}</div>
                            <img src={unhideIcon} onClick={(e) => onHideProduct(e)} loading="lazy" width="17" className="image-145" />
                            </div>}
                            {/* {isSelected && product.hide && <div className="hidespe-2-selected">
                                <div af-sock="text" className="text-block-63">{product.name}</div>
                                <img src={unhideIcon} onClick={(e) => onHideProduct(e)} loading="lazy" width="17" className="image-145" />
                            </div>} */}

                        </div>
                    </React.Fragment>
                )}
            </MenuContext.Consumer>

        </React.Fragment>
    )
}

export default Product;
