import React from 'react';
import { ROUTES_PATH } from "../../constants/Routes";
import ArchiveWidget from './ArchiveWidget';
import CustomCategoryPriceWidget from './CustomCategoryPriceWidget';
import QRCodeWidget from './QRCodeWidget';
import SurveysWidget from './SurveysWidget';
import Bookings from './Bookings';
import Specials from './Specials';
import CheckIn from './CheckIn';
import ArchiveWidgetAnalytics from './ArchiveWidgetAnalytics';
import ManageProvider from './../../Providers/Manage/ManageProvider';
import ManageContext from './../../Providers/Manage/ManageContext';


export const Manage = (props) => {
    const PATH_ROUTES = ROUTES_PATH;

    return (
        <React.Fragment>
            <ManageProvider>
            <div className="body-4" style={{width:"-webkit-fill-available"}}>
                <div className="divdashboard-copy">
                    <div className="div-block-768">
                        <ArchiveWidgetAnalytics />
                    </div>
                    {<div className="div-block-768">
                        {/* <CustomCategoryPriceWidget /> */}
                    </div>}
                { <div className="div-block-768">
                    {/*  <Bookings /> */}
                    </div>}
                    {<div className="div-block-768">
                        {/* <QRCodeWidget /> */}
                    </div>}
                    {/* <div className="div-block-768">
                        <SurveysWidget />
                    </div> */}
                    {<div className="div-block-768">
                        {/* <CheckIn /> */}
                    </div>}
                    {/* <div className="div-block-768">
                        <Specials />
                    </div> */}
                </div>
                </div>
            </ManageProvider>
            
        </React.Fragment>
    )
}

export default Manage

/* const withContext = (Component) => {
    return (props) => {
      return (
        <ManageContext.Consumer>
          {(context) => {
            return <Component {...props} context={context} />;
          }}
        </ManageContext.Consumer>
      );
    };
  };
  
  export default withContext(Manage); */