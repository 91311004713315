import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ActivateDeactivateConfirm = (props) => {
  // const name = props.name || '';
  const { dialogContent } = props;
  const { t } = useTranslation();

  const confirm = () => {
    props.action("yes");
  };

  const cancel = () => {
    props.action("no");
  };

  const getStyle = () => {
    let styles = {};
    if (props.show) {
      styles = { display: "flex" };
    } else {
      styles = { display: "none" };
    }
    return styles;
  };

  return (
    <React.Fragment>
      <div className="deactivate_dialog_paypal" style={getStyle()}>
        <div>
          <div
            className="text-block-499"
            dangerouslySetInnerHTML={{ __html: t(dialogContent.message) }}
          ></div>
          <div
            data-w-id="3a274a0e-74e4-bbb4-49ab-c93a75a71268"
            className="no_deactivate_paypal"
            onClick={cancel}
          >
            {t(dialogContent.noText)}
          </div>
          <div
            data-w-id="3a274a0e-74e4-bbb4-49ab-c93a75a71266"
            className="yes_deactivate_paypal"
            onClick={confirm}
          >
            {t(dialogContent.yesText)}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ActivateDeactivateConfirm.propTypes = {
  action: PropTypes.func.isRequired,
  // cancel: PropTypes.func.isRequired,
  dialogContent: PropTypes.shape({
    yesText: PropTypes.string,
    noText: PropTypes.string,
    message: PropTypes.string,
  }),
};

ActivateDeactivateConfirm.defaultProps = {
  dialogContent: {
    message: "shared.messages.confirmText",
    yesText: "shared.actions.yes",
    noText: "shared.actions.no",
  },
};

export default ActivateDeactivateConfirm;
