

import React, { forwardRef, useState } from 'react';
import Moment from 'moment';
import { Overlayinfo } from '../../SharedComponents/OverlayInfo';

/**
 * React Date Picker with Locale
 */
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import enGb from 'date-fns/locale/en-GB';
import { ICONS } from '../../Helpers/Icon.service';
registerLocale('en-gb', enGb);



const archiveData = [
    {
        date: '01.02.2002',
        amount: '3666€',
        visitorCount: '47 Visitors',
        showMore: false
    },
    {
        date: '01.02.2002',
        amount: '3666€',
        visitorCount: '47 Visitors',
        showMore: false
    },
    {
        date: '01.02.2002',
        amount: '3666€',
        visitorCount: '47 Visitors',
        showMore: false
    },
    {
        date: '01.02.2002',
        amount: '3666€',
        visitorCount: '47 Visitors',
        showMore: false
    }
]


export const ArchiveWidget = (props) => {
    const questionIcon = ICONS.QUESTION_ICON;

    const [displayHistory, setDisplayHistory] = useState(false);
    const [archiveRecords, setarchiveRecords] = useState(archiveData);


    let startDateRef = null;
    let endDateRef = null;
    const [startDateOpen, toggleStartDate] = useState(false);
    const [endDateOpen, toggleEndDate] = useState(false);
    const [startDate, setStartDate] = useState(new Date('01/03/2021'));
    const [endDate, setEndDate] = useState(new Date('03/03/2021'));

    const [showInfoOverlay, setShowInfoOverlay] = useState(false);
    const QRCodeInfoTitle = "DONEC ACCUMSAN";
    const QRCodeInfoDesc = "Donec accumsan pharetra turpis sodales facilisis. Nam finibus ante sed arcu scelerisque, ut finibus libero aliquet. Interdum et malesuada ";


    const StartDateInput = forwardRef(
        ({ value, onClick }, ref) => (
            <React.Fragment>
                <div className="dropdown-toggle-15 w-dropdown-toggle" onClick={() => { startDateRef.setOpen(!startDateOpen) }} >
                    <div className="text-block-355">
                        From
                    </div>
                </div>
            </React.Fragment>
        ),
    );
    /* const StartDateInput = ({ value, onClick }) => (
        <React.Fragment>
            <div className="dropdown-toggle-15 w-dropdown-toggle" onClick={onClick} >
                <div className="text-block-355">
                    From
                </div>
            </div>
        </React.Fragment>
    ); */

    const EndDateInput = forwardRef(
        ({ value, onClick }, ref) => (
            <React.Fragment>
                <div className="dropdown-toggle-15 w-dropdown-toggle" onClick={() => { endDateRef.setOpen(!endDateOpen) }} >
                    <div className="text-block-355">
                        To
                    </div>
                </div>
            </React.Fragment>
        ),
    );
    /* const EndDateInput = ({ value, onClick }) => (
        <React.Fragment>
            <div className="dropdown-toggle-15 w-dropdown-toggle" onClick={onClick} >
                <div className="text-block-355">
                    To
                </div>
            </div>
        </React.Fragment>
    ); */

    const toggleRecords = () => {
        setDisplayHistory(!displayHistory)
    }

    const toggleInnerRecords = (index) => {
        console.log('index: ' + index);

        let newArr = [...archiveRecords]; // copying the old datas array
        newArr[index] = { ...newArr[index], showMore: !newArr[index].showMore }; // replace e.target.value with whatever you want to change it to

        setarchiveRecords(newArr); // ??
    }

    return (
        <React.Fragment>
            <div className="history">
                <div className="div-block-885">
                    <div data-hover="" data-delay="0" className="dropdown-17 w-dropdown">
                        <div className="dropdown-toggle-15 w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-355">from</div>
                        </div>
                        <nav className="dropdown-list-15 w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2" >
                            <div className="month">
                                <div className="div-block-732">
                                    <div className="div-block-734">
                                        <img src="images/left.svg" loading="lazy" width="15" alt="" />
                                    </div>
                                    <div className="text-block-238">Februar 2021</div>
                                    <div className="div-block-733">
                                        <img src="images/right.svg" loading="lazy" width="15" alt="" /></div>
                                </div>
                                <div className="w-layout-grid grid-30">
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">2</div>
                                    <div className="text-block-228">3</div>
                                    <div className="text-block-228">4</div>
                                    <div className="text-block-228">5</div>
                                    <div className="text-block-228">6</div>
                                    <div className="text-block-228">7</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">11</div>
                                    <div className="text-block-228">12</div>
                                    <div className="text-block-228">13</div>
                                    <div className="text-block-228">14</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228-copy">20</div>
                                    <div className="resfor1">1</div>
                                    <div className="resfor2">1</div>
                                    <div className="text-block-228">23</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">31</div>
                                </div>
                            </div>
                        </nav>
                    </div>


                    <div data-hover="" data-delay="0" className="dropdown-18 w-dropdown">
                        <div className="dropdown-toggle-15 w-dropdown-toggle" id="w-dropdown-toggle-3" aria-controls="w-dropdown-list-3" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-355">to</div>
                        </div>
                        <nav className="dropdown-list-15 w-dropdown-list" id="w-dropdown-list-3" aria-labelledby="w-dropdown-toggle-3">
                            <div className="month">
                                <div className="div-block-732">
                                    <div className="div-block-734">
                                        <img src="images/left.svg" loading="lazy" width="15" alt="" />
                                    </div>
                                    <div className="text-block-238">Februar 2021</div>
                                    <div className="div-block-733">
                                        <img src="images/right.svg" loading="lazy" width="15" alt="" />
                                    </div>
                                </div>
                                <div className="w-layout-grid grid-30">
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">2</div>
                                    <div className="text-block-228">3</div>
                                    <div className="text-block-228">4</div>
                                    <div className="text-block-228">5</div>
                                    <div className="text-block-228">6</div>
                                    <div className="text-block-228">7</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">11</div>
                                    <div className="text-block-228">12</div>
                                    <div className="text-block-228">13</div>
                                    <div className="text-block-228">14</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228-copy">20</div>
                                    <div className="resfor1">1</div>
                                    <div className="resfor2">1</div>
                                    <div className="text-block-228">23</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">1</div>
                                    <div className="text-block-228">31</div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="text-block-359">from 01.02.2003 <br />to 31.09.2021</div>
                    <div data-w-id="TextBlock 41" className="text-block-368">show / hide</div>
                </div>
                <div className="togglehistory">
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-5" aria-controls="w-dropdown-list-5" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-5" aria-labelledby="w-dropdown-toggle-5">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-6" aria-controls="w-dropdown-list-6" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-6" aria-labelledby="w-dropdown-toggle-6">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-7" aria-controls="w-dropdown-list-7" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-7" aria-labelledby="w-dropdown-toggle-7">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-8" aria-controls="w-dropdown-list-8" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-8" aria-labelledby="w-dropdown-toggle-8">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-9" aria-controls="w-dropdown-list-9" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-9" aria-labelledby="w-dropdown-toggle-9">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-10" aria-controls="w-dropdown-list-10" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-10" aria-labelledby="w-dropdown-toggle-10">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-11" aria-controls="w-dropdown-list-11" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-11" aria-labelledby="w-dropdown-toggle-11">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-12" aria-controls="w-dropdown-list-12" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-12" aria-labelledby="w-dropdown-toggle-12">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-13" aria-controls="w-dropdown-list-13" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-13" aria-labelledby="w-dropdown-toggle-13">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-14" aria-controls="w-dropdown-list-14" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-14" aria-labelledby="w-dropdown-toggle-14">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-15" aria-controls="w-dropdown-list-15" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-15" aria-labelledby="w-dropdown-toggle-15">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-16" aria-controls="w-dropdown-list-16" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-16" aria-labelledby="w-dropdown-toggle-16">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-17" aria-controls="w-dropdown-list-17" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-17" aria-labelledby="w-dropdown-toggle-17">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-18" aria-controls="w-dropdown-list-18" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-18" aria-labelledby="w-dropdown-toggle-18">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-19" aria-controls="w-dropdown-list-19" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-19" aria-labelledby="w-dropdown-toggle-19">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-20" aria-controls="w-dropdown-list-20" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-20" aria-labelledby="w-dropdown-toggle-20">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-21" aria-controls="w-dropdown-list-21" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-21" aria-labelledby="w-dropdown-toggle-21">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-22" aria-controls="w-dropdown-list-22" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-22" aria-labelledby="w-dropdown-toggle-22">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-23" aria-controls="w-dropdown-list-23" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-23" aria-labelledby="w-dropdown-toggle-23">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="" data-delay="0" className="dropdown-19 w-dropdown">
                        <div className="dropdown-toggle-16 w-dropdown-toggle" id="w-dropdown-toggle-24" aria-controls="w-dropdown-list-24" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div className="text-block-369">01.02.2002 / 3666€ / 47 Visitors</div>
                        </div>
                        <nav className="dropdown-list-16 w-dropdown-list" id="w-dropdown-list-24" aria-labelledby="w-dropdown-toggle-24">
                            <div className="div-block-909">
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                                <div className="text-block-370">This is some text inside of a div block.</div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="helphistory">
                    <img src="images/achtung_rAsset-1.svg" loading="lazy" width="22" height="22" data-w-id="638efd02-c2b2-95a7-153d-851d74d03866" alt="" className="image-136" />
                    <div className="text-block-409-copy">Donec accumsan</div>
                    <div className="text-block-409">Donec accumsan pharetra turpis sodales facilisis. Nam finibus ante sed arcu scelerisque, ut finibus libero aliquet. Interdum et malesuada fames ac ante ipsum primis in faucibus. In diam dolor, facilisis non condimentum ut, aliquet in lectus. Ut elementum nisi id ultrices accumsan.</div>
                </div>
                <div className="div-block-970">
                    <div className="div-block-1009">
                        <div className="div-block-972">
                            <div data-w-id="052e4e8b-0306-c88a-74c9-af7d79560def" className="text-block-440">reset</div>
                        </div>
                        <div className="div-block-972">
                            <div data-w-id="052e4e8b-0306-c88a-74c9-af7d79560df2" className="text-block-440">download</div>
                        </div>
                        <div className="div-block-972">
                            <div data-w-id="052e4e8b-0306-c88a-74c9-af7d79560df5" className="text-block-440">help</div>
                        </div>
                    </div>
                    <div className="div-block-971"></div>
                    <div className="text-block-439">Archive</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ArchiveWidget