import React, { useContext, useEffect, useState } from 'react';
import ProfileContext from '../../../Providers/Profile/ProfileContext';
import CashInfo from './CashInfo';
import PaypalInfo from './PaypalInfo';
import { PaypalIntegration } from './paypal';


const Payments = ({ profileInfo, ...rest }) => {
    const profileService = useContext(ProfileContext);
    
    const updateProfileInfo = (paypalAccount) => {
        profileInfo.paypalAccount = {...paypalAccount};
        rest.updateInfo(profileInfo);
    }


    return (
        <div data-w-tab="Payments" className="mybusinesstab">
            <div className="grid-26">
                <CashInfo cashDetails={profileInfo.cashInfo} />
                <PaypalInfo updateInfo={updateProfileInfo} paypalDetails={profileInfo.paypalAccount} />
            </div>
            
        </div>
    )
}

export default Payments;