export const USER_ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    HIDE: 'hide',
    UNHIDE: 'unhide',
    SELECT: 'select',
    SYNC: 'sync'
}

export const SYNC_STATUS = {
    INPROGRESS : 'inprogress',
    ERROR: 'error',
    SUCCESS: 'success',
    SYNC: 'syncing'
}

export const WEEK_DAYS = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
]

export const LOCALSTORAGE_KEYS = {
    i18: 'i18nextLng',
    paypayl_seller: 'PP_Seller',
    earnings_total: 'earnings_total',
    daily_average: 'daily_average',
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/;

/* export const LANGUAGE_CODES = {
    EN: "en",
    DE: "de",
  }; */
  
export const INTERNATIONAL_LOCALES = {
    EN: {
      locale: 'en-US',
      currency: 'USD',
      code: 'en'
    },
    DE: {
      locale: 'de-DE',
      currency: 'EUR',
      code: 'de'
    },
  };