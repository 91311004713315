import React, { Component, createRef } from "react";
import ManageContext from "./ManageContext";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as query from "../../graphql/query";

import { authenticationService } from "../../Services/auth.service";

export default class ManageProvider extends Component {
  state = {
    isPageLoaded: false, // Should be called once
  };

  setPageLoaded = (isLoaded) => {
    this.setState({
      isPageLoaded: isLoaded,
    });
  };

  getArchiveAnalytics = (startDate, endDate) => {
    let userId = authenticationService.userID;

    let payload = {
      from: startDate, //Moment(startDate).format("YYYY-MM-DD"),
      to: endDate, //Moment(endDate).format("YYYY-MM-DD"),
      userId: userId,
    };
    return API.graphql({
      query: query.archiveAnalytics,
      variables: {
        input: payload,
      },
    })
      .then((res) => {
        console.log("Archive Widget", res);
        let data = {};
        if(res?.data?.manageArchive) {
            data = res.data.manageArchive;
        }
        return data;
       /*  this.setState({
          itemList: res.data.TopSellerProducts.topSellers,
        });
        return res.data.TopSellerProducts.topSellers; */
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  render() {
    return (
      <ManageContext.Provider
        value={{
          isPageLoaded: this.state.isPageLoaded,
          setPageLoaded: this.setPageLoaded,
          getArchiveAnalytics: this.getArchiveAnalytics,
        }}
      >
        {this.props.children}
      </ManageContext.Provider>
    );
  }
}
