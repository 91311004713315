import React from 'react';
import StatsGraph from './StatsGraph';
import GuestsGraph from './GuestsGraph';
import './graphSection.css';

const GraphSection = () => {

    return (
        <React.Fragment>
            <div  className="div-block-877">
                <GuestsGraph />
            </div>
            <div id="w-node-d3ba4482-98c0-fda9-5e3c-7f1cc2892c97-999f542c" className="div-block-877">
                <StatsGraph />
            </div>
        </React.Fragment>
    )
}

export default GraphSection;