import React, { useContext, useEffect, useState } from "react";
import ProfileContext from "../../../Providers/Profile/ProfileContext";
import { APP_CONFIG } from "../../../constants/Config";
import { ICONS } from "../../../Helpers/Icon.service";
import { useForm, Controller } from "react-hook-form";
import { WEEK_DAYS } from "../../../constants/app.constant";
import LoadingIndicator from '../../../SharedComponents/Loading';
import { Trans } from "react-i18next";
import { useTranslation } from 'react-i18next';

const OpeningTimings = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
  } = useForm({ mode: "onChange" });
  const [editForm, setEditform] = useState(false);
  const { t, i18n } = useTranslation();
  const profileService = useContext(ProfileContext);
  const { timingsInfo } = profileService;
  console.log(timingsInfo);
  const imgPath = APP_CONFIG.IMAGE_PATH;
  const settings = ICONS.SETTINGS_DARK;

  const onEditForm = () => {
    setEditform(true);
    if(timingsInfo && Object.keys(timingsInfo).length) {
      console.log(timingsInfo);
      reset({...timingsInfo });
    }
  }

  const onSaveTimings = (data) => {
    console.log("Timings", data);
    profileService.updateTimings(data);
    setEditform(false);
  };

  const getTimingsPreview = () => {
    if(!timingsInfo || !(Object.keys(timingsInfo).length)){
      return null;
    }

    return <React.Fragment>
      <div className="w-layout-grid grid-33">
              <div className="dayoftheweek"><Trans>pages.profile.timings.day</Trans></div>
              <div className="text-block-391"><Trans>pages.profile.timings.opening</Trans></div>
              <div className="text-block-391"><Trans>pages.profile.timings.closing</Trans></div>
              {WEEK_DAYS.map((weekday, index) => {
                  const capitalizeWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);;
                  return <React.Fragment key={index}>
                    <div className="dayoftheweek">{t(`shared.days.${weekday.substr(0, 3)}`)}</div>
                    <div className="text-block-391">
                      {timingsInfo[capitalizeWeekday]?.["opening"]}
                    </div>
                    {
                      <div className="text-block-391">
                        {timingsInfo[capitalizeWeekday]?.["closing"]}
                      </div>
                    }
                  </React.Fragment>
                
                  })}
            </div>
    </React.Fragment>
  }

  const renderTimingsPreView = () => {
    return (
      <React.Fragment>
        <img
          src={settings}
          loading="lazy"
          width="17"
          height="17"
          data-w-id="1ade66ec-fc8e-5fb9-806b-0c5adeb969bc"
          alt=""
          onClick={onEditForm}
          className="image-128"
        />
        <div className="div-block-951-times">
          <div className="text-block-390"><Trans>pages.profile.timings.openingTime</Trans></div>
          {getTimingsPreview()}
          
        </div>
      </React.Fragment>
    );
  };

  const renderEditTimings = () => {
    return (
      <React.Fragment>
        <div className="w-form">
          <form
            id="email-form-2"
            name="email-form-2"
            data-name="Email Form 2"
            className="form-138"
            onSubmit={handleSubmit(onSaveTimings)}
          >
            <div className="w-layout-grid grid-53">
              {WEEK_DAYS.map((weekday, index) => (
                <React.Fragment key={index}>
                  <div className="dayoftheweek">{t(`shared.days.${weekday.substr(0, 3)}`)}</div>
                  <input
                    type="text"
                    className="text-field-181 w-input"
                    maxLength="256"
                    name={weekday}
                    data-name={weekday}
                    placeholder="00:00"
                    id={"input-c"+weekday}
                    {...register(weekday + ".opening")}
                    defaultValue="09:00"
                  />
                  <input
                    type="text"
                    className="text-field-181 w-input"
                    maxLength="256"
                    name="monday-4"
                    data-name="Monday 4"
                    placeholder="00:00"
                    id="input-d"
                    {...register(weekday + ".closing")}
                    defaultValue="09:00"
                  />
                </React.Fragment>
              ))}
            </div>
            <div className="div-block-952">
              <div
                data-w-id="1ade66ec-fc8e-5fb9-806b-0c5adeb969cf"
                className="close_opening_times"
                onClick={() => setEditform(false)}
              >
                <Trans>shared.actions.cancel</Trans>
              </div>
              <input
                type="submit"
                value={t('shared.actions.save')}
                data-wait="Please wait..."
                className="submit-button-25 w-button"
              />
              
            </div>
          </form>
          <div className="success-message-10 w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="error-message-10 w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  if(!profileService.isPageLoaded ){
    return <div className="data-content-section"><LoadingIndicator /></div>;
  }

  return (
    <div
      id="w-node-_1ade66ec-fc8e-5fb9-806b-0c5adeb969bb-999f542a"
      className="div-block-667 data-content-section"
    >
      <img
        src={`${imgPath}/user_fullAsset-2.svg`}
        loading="lazy"
        width="55"
        height="55"
        alt=""
        className="image-194"
      />
      {!editForm && renderTimingsPreView()}
      {editForm && renderEditTimings()}
    </div>
  );
};

export default OpeningTimings;
