import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { months, graphFeatures, filterConst, scaleFeatures } from '../constant';
import { weekData, todayData } from '../data';

const GuestsGraph = () => {
    const [graphState, setGraphState] = useState({
        labels: [...months],
        datasets: [
            {
                ...graphFeatures,
                label: filterConst.Today,
                data: [...todayData]
            }
        ]
    });
    const [showInspect, setShowInspect] = useState(false);

    const changeGraphFields = (type) => {
        let graphData = { ...graphState };
        graphData.datasets = [];
        switch (type) {
            case filterConst.Today: graphData.datasets = [
                {
                    ...graphFeatures,
                    label: filterConst.Today,
                    data: [...todayData]
                }
            ]
                break;
            case filterConst.Weekly: graphData.datasets = [
                {
                    ...graphFeatures,
                    label: filterConst.Today,
                    data: [...todayData]
                },
                {
                    ...graphFeatures,
                    borderColor: '#0b0bb0',
                    label: filterConst.Weekly,
                    data: [...weekData]
                }
            ]
                break;
            default: graphData.datasets = [
                {
                    ...graphFeatures,
                    label: filterConst.Today,
                    data: [...todayData]
                }
            ]
                break;
        }
        setGraphState({ ...graphData });
    }

    return (
        <React.Fragment>
            <div className="div-block-877">
                <div className="div-block-879">
                    <div className="div-block-924">
                        <div className="w-layout-grid grid-56">
                            <div className="div-block-917">
                                <div className="div-block-1059">
                                    <div data-w-id="adc2a7b1-cf22-189f-721b-6bdf00cb924d" data-animation-type="lottie" data-src="documents/lf30_editor_ribe4lqg-1.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="5" data-duration="22" className="lottie-animation-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 1334" width="750" height="1334" preserveAspectRatio="xMidYMid meet"
                                        style={{ width: '100%', height: '100%', transform: 'translate3d(0px, 0px, 0px)' }}><defs><clipPath id="__lottie_element_4"><rect width="750" height="1334" x="0" y="0"></rect></clipPath><linearGradient id="__lottie_element_8" spreadMethod="pad" gradientUnits="userSpaceOnUse" x1="0" y1="474" x2="0" y2="-204"><stop offset="0%" stop-color="rgb(23,166,183)"></stop><stop offset="50%" stop-color="rgb(23,166,183)"></stop><stop offset="100%" stop-color="rgb(23,166,183)"></stop></linearGradient></defs><g clip-path="url(#__lottie_element_4)"><g transform="matrix(1,0,0,1,65.44049072265625,664)" opacity="0.8"
                                            style={{ display: 'block' }}><g opacity="1" transform="matrix(1,0,0,1,-3,-520.0079956054688)"><path fill="url(#__lottie_element_8)" fill-opacity="1" d=" M750,-147.99200439453125 C750,-147.99200439453125 749.5009765625,75.00700378417969 749.5009765625,75.11699676513672 C594,85.00700378417969 531,185.00799560546875 378,185.11700439453125 C250.96800231933594,185.20799255371094 190,74.00800323486328 -0.7490000128746033,76.24199676513672 C-128.38600158691406,77.73699951171875 -244.35499572753906,185.11700439453125 -372,185.11700439453125 C-500.34100341796875,185.11700439453125 -561.5,75.00800323486328 -750.5,74.99199676513672 C-750.5,75.13300323486328 -750,-147.99200439453125 -750,-147.99200439453125 C-750,-147.99200439453125 750,-147.99200439453125 750,-147.99200439453125z"></path><path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4" stroke="rgb(255,255,255)" stroke-opacity="1" stroke-width="0" d="M0 0"></path></g></g></g></svg></div>
                                </div>
                                <div className="text-block-511">42</div>
                                <div className="text-block-512">checked in</div>
                                <div className="div-block-1066">
                                    <div className="text-block-521-copy">133</div>
                                    <div className="text-block-521">Guests this Week </div>
                                    <div className="text-block-521-copy2">696</div>
                                    <div className="text-block-521">Month Total</div>
                                    <svg style={{ fill: '#17a6b7', height: '15px', width: '15px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.77 373.21">
                                        <path class="a" d="M115.9,220.63l21.89,20.84,75.6,72,2.52,2.43c12.78,12.4,12.77,23.09.06,35.4-4.9,4.76-9.67,9.65-14.5,14.47-9.79,9.77-21.18,9.91-31.2.1-12.14-11.89-24.1-23.95-36.13-36C91.83,287.71,49.68,245.29,7.07,203.33a23.19,23.19,0,0,1-.55-32.79l.64-.64C61.46,116.31,115.24,62.2,169.21,8.27,177.75-.26,185.6-2,194.84,2.2A21.8,21.8,0,0,1,201.21,7c5,5.08,10.09,10.16,15.19,15.19,12.22,12,12,23.18-.55,35.14q-48.33,46-96.58,92c-.73.69-1.54,1.28-2.6,2.16,2.18,1.5,4.2.92,6,.92q118,0,235.93-.06c7.8,0,14.42,1.88,19.29,8.35a17.15,17.15,0,0,1,3.83,10.58c0,9.32.1,18.65,0,28-.08,13.45-7.89,21.27-21.51,21.34-22.49.11-45,.06-67.48.07H115.9Z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="div-block-917">
                                <div className="div-block-1059-copy">
                                    <div data-w-id="79b04ac8-dbf1-9f96-3441-e7804e06bbca" data-animation-type="lottie" data-src="documents/lf30_editor_ribe4lqg-1.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="5" data-duration="11" className="lottie-animation-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 1334" width="750" height="1334" preserveAspectRatio="xMidYMid meet"
                                        style={{ width: '100%', height: '100%', transform: 'translate3d(0px, 0px, 0px)' }}><defs><clipPath id="__lottie_element_9"><rect width="750" height="1334" x="0" y="0"></rect></clipPath><linearGradient id="__lottie_element_13" spreadMethod="pad" gradientUnits="userSpaceOnUse" x1="0" y1="474" x2="0" y2="-204"><stop offset="0%" stop-color="rgb(23,166,183)"></stop><stop offset="50%" stop-color="rgb(23,166,183)"></stop><stop offset="100%" stop-color="rgb(23,166,183)"></stop></linearGradient></defs><g clip-path="url(#__lottie_element_9)"><g transform="matrix(1,0,0,1,126.87953186035156,664)" opacity="0.8"
                                            style={{ display: 'block' }}><g opacity="1" transform="matrix(1,0,0,1,-3,-520.0079956054688)"><path fill="url(#__lottie_element_13)" fill-opacity="1" d=" M750,-147.99200439453125 C750,-147.99200439453125 749.5009765625,75.00700378417969 749.5009765625,75.11699676513672 C594,85.00700378417969 531,185.00799560546875 378,185.11700439453125 C250.96800231933594,185.20799255371094 190,74.00800323486328 -0.7490000128746033,76.24199676513672 C-128.38600158691406,77.73699951171875 -244.35499572753906,185.11700439453125 -372,185.11700439453125 C-500.34100341796875,185.11700439453125 -561.5,75.00800323486328 -750.5,74.99199676513672 C-750.5,75.13300323486328 -750,-147.99200439453125 -750,-147.99200439453125 C-750,-147.99200439453125 750,-147.99200439453125 750,-147.99200439453125z"></path><path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4" stroke="rgb(255,255,255)" stroke-opacity="1" stroke-width="0" d="M0 0"></path></g></g></g></svg></div>
                                </div>
                                <div className="text-block-511-waiting">13</div>
                                <div className="text-block-512-waiting">waiting</div>
                                <div className="div-block-1066">
                                    <div className="text-block-521-copy">223</div>
                                    <div className="text-block-521">Guests this Week </div>
                                    <div className="text-block-521-copy2">1277</div>
                                    <div className="text-block-521">Month Total</div>
                                    <svg style={{ fill: '#17a6b7', height: '15px', width: '15px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.77 373.21">
                                        <path class="a" d="M115.9,220.63l21.89,20.84,75.6,72,2.52,2.43c12.78,12.4,12.77,23.09.06,35.4-4.9,4.76-9.67,9.65-14.5,14.47-9.79,9.77-21.18,9.91-31.2.1-12.14-11.89-24.1-23.95-36.13-36C91.83,287.71,49.68,245.29,7.07,203.33a23.19,23.19,0,0,1-.55-32.79l.64-.64C61.46,116.31,115.24,62.2,169.21,8.27,177.75-.26,185.6-2,194.84,2.2A21.8,21.8,0,0,1,201.21,7c5,5.08,10.09,10.16,15.19,15.19,12.22,12,12,23.18-.55,35.14q-48.33,46-96.58,92c-.73.69-1.54,1.28-2.6,2.16,2.18,1.5,4.2.92,6,.92q118,0,235.93-.06c7.8,0,14.42,1.88,19.29,8.35a17.15,17.15,0,0,1,3.83,10.58c0,9.32.1,18.65,0,28-.08,13.45-7.89,21.27-21.51,21.34-22.49.11-45,.06-67.48.07H115.9Z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="div-block-917">
                                <div className="div-block-1059-copy-copy">
                                    <div data-w-id="b600f7f0-2fa6-7184-0327-59334fea563a" data-animation-type="lottie" data-src="documents/lf30_editor_ohyrqg03.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="5" data-duration="33" className="lottie-animation-5"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 1334" width="750" height="1334" preserveAspectRatio="xMidYMid meet"
                                        style={{ width: '100%', height: '100%', transform: 'translate3d(0px, 0px, 0px)' }}><defs><clipPath id="__lottie_element_14"><rect width="750" height="1334" x="0" y="0"></rect></clipPath><linearGradient id="__lottie_element_18" spreadMethod="pad" gradientUnits="userSpaceOnUse" x1="0" y1="474" x2="0" y2="-204"><stop offset="0%" stop-color="rgb(255,65,77)"></stop><stop offset="50%" stop-color="rgb(255,65,77)"></stop><stop offset="100%" stop-color="rgb(255,65,77)"></stop></linearGradient></defs><g clip-path="url(#__lottie_element_14)"><g transform="matrix(1,0,0,1,44.96036148071289,664)" opacity="0.8"
                                            style={{ display: 'block' }}><g opacity="1" transform="matrix(1,0,0,1,-3,-520.0079956054688)"><path fill="url(#__lottie_element_18)" fill-opacity="1" d=" M750,-147.99200439453125 C750,-147.99200439453125 749.5009765625,75.00700378417969 749.5009765625,75.11699676513672 C594,85.00700378417969 531,185.00799560546875 378,185.11700439453125 C250.96800231933594,185.20799255371094 190,74.00800323486328 -0.7490000128746033,76.24199676513672 C-128.38600158691406,77.73699951171875 -244.35499572753906,185.11700439453125 -372,185.11700439453125 C-500.34100341796875,185.11700439453125 -561.5,75.00800323486328 -750.5,74.99199676513672 C-750.5,75.13300323486328 -750,-147.99200439453125 -750,-147.99200439453125 C-750,-147.99200439453125 750,-147.99200439453125 750,-147.99200439453125z"></path><path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4" stroke="rgb(255,255,255)" stroke-opacity="1" stroke-width="0" d="M0 0"></path></g></g></g></svg></div>
                                </div>
                                <div className="text-block-511-overtime">5</div>
                                <div className="text-block-512-copy">overtimes</div>
                                <div className="div-block-1066">
                                    <div className="text-block-521-copy">13</div>
                                    <div className="text-block-521">Guests this Week </div>
                                    <div className="text-block-521-copy2">73</div>
                                    <div className="text-block-521">Month Total</div>
                                    <svg style={{ fill: '#17a6b7', height: '15px', width: '15px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.77 373.21">
                                        <path class="a" d="M115.9,220.63l21.89,20.84,75.6,72,2.52,2.43c12.78,12.4,12.77,23.09.06,35.4-4.9,4.76-9.67,9.65-14.5,14.47-9.79,9.77-21.18,9.91-31.2.1-12.14-11.89-24.1-23.95-36.13-36C91.83,287.71,49.68,245.29,7.07,203.33a23.19,23.19,0,0,1-.55-32.79l.64-.64C61.46,116.31,115.24,62.2,169.21,8.27,177.75-.26,185.6-2,194.84,2.2A21.8,21.8,0,0,1,201.21,7c5,5.08,10.09,10.16,15.19,15.19,12.22,12,12,23.18-.55,35.14q-48.33,46-96.58,92c-.73.69-1.54,1.28-2.6,2.16,2.18,1.5,4.2.92,6,.92q118,0,235.93-.06c7.8,0,14.42,1.88,19.29,8.35a17.15,17.15,0,0,1,3.83,10.58c0,9.32.1,18.65,0,28-.08,13.45-7.89,21.27-21.51,21.34-22.49.11-45,.06-67.48.07H115.9Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-block-878">
                    <div className="text-block-345">Guests</div>
                    <div className="text-block-346-today-guests">Inspect</div>
                </div>
            </div>
            <script src="js/webflow.js" type="text/javascript"></script>
        </React.Fragment>
    )
}

export default GuestsGraph;