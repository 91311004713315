import React, { useContext, useEffect, useState } from "react";
import ProfileContext from "../../../Providers/Profile/ProfileContext";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { ICONS } from "../../../Helpers/Icon.service";
import ActivateDeactivateConfirm from "../../../SharedComponents/ActivateDeactivateConfirm";
import LoadingIndicator from "../../../SharedComponents/Loading";

const CashInfo = ({ cashDetails }) => {
  const cashIcon = ICONS.CASH_ICON;
  const payCashActiveIcon = ICONS.CURRENCY_DOLLAR_ICON;
  const payCashInActiveIcon = ICONS.CURRENCY_DOLLAR_WHITE;
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const { t } = useTranslation();
  const profileService = useContext(ProfileContext);
  const { cashInfo } = profileService;

  const [dialogContent, setDialogContent] = useState({
    message: "pages.profile.payment.cashDeactiveText",
    yesText: "shared.actions.deactivate",
    noText: "shared.actions.cancel",
  });

  const hideConfirmDialog = () => {
    setDisplayConfirmDialog(false);
  };

  const showConfirmDialog = () => {
    const dialogContentForActivate = {
      message: "pages.profile.payment.cashActiveText",
      yesText: "shared.actions.activate",
    } 
    const dialogContentForDeactivate = {
      message: "pages.profile.payment.cashDeactiveText",
      yesText: "shared.actions.deactivate",
    }
    const contentText = (cashInfo && !cashInfo.isActive) ?  dialogContentForActivate : dialogContentForDeactivate
    setDialogContent({...dialogContent, ...contentText})
    setDisplayConfirmDialog(true);
  };


  const getUserAction = (decision) => {
    if (decision === "yes") {
      toggleActivationStatus();
      hideConfirmDialog();
    } else {
      hideConfirmDialog();
    }
  };

  const getIconStatusIndicator = () => {
    console.log(cashInfo,"??????????????????????????????????");
    const cashIcon = (cashInfo && !cashInfo.isActive) ? payCashInActiveIcon : payCashActiveIcon ;
    return (
      <img
        src={cashIcon}
        loading="lazy"
        width="55"
        height="55"
        alt=""
        className="image-194"
      ></img>
    );
  };

  const toggleActivationStatus = () => {
    setDisplayLoader(true);
    profileService
      .updatePaymentInfo({
        informationType: "PAYMENTS",
        cash: {
          isActive: !cashInfo.isActive,
        },
      })
      .then((res) => {
        console.info(res);
        setDisplayLoader(false);
      });
  };

  const getButtonStatus = () => {
    const butLabel = 
        (cashInfo && !cashInfo.isActive) ? <Trans>shared.actions.enable</Trans> : <Trans>shared.actions.disable</Trans> ;
    return (
      <div className="activenow" onClick={() => showConfirmDialog()}>
        {butLabel}
      </div>
    );
  };

  if (!profileService.isPageLoaded || displayLoader) {
    return (
      <div className="data-content-section">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div
      id="w-node-adfd71f8-2b4b-ff98-1994-c650fa686d2c-fe54d577"
      className="cashblock"
    >
      <div className="div-block-951">
        <div className="text-block-390">
          <Trans>pages.profile.payment.cash</Trans>
        </div>
        <div className="text-block-393">
          <Trans>pages.profile.payment.cashPayingText</Trans>
        </div>
        <div className="text-block-391">
          {cashDetails ? t(cashDetails) : null}
        </div>
      </div>
      {getIconStatusIndicator()}
      {getButtonStatus()}
      <ActivateDeactivateConfirm
        show={displayConfirmDialog}
        dialogContent={dialogContent}
        action={getUserAction}
      />
      {/* <div className="text-block-392-copy"  onClick={() => setEditform(!editForm)} ><Trans>shared.actions.enable</Trans></div> */}
    </div>
  );
};

export default CashInfo;
