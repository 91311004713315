export const environment = {
    production: false,
    isDebugMode: true
}    

export const API_BASE_URL = '';

export const API_PATH_NAME = {
    MENU: 'MenuApi',
    ORDER: 'OrderApi',
    PROFILE: 'ProfileApi'
}

export const API = {
}

