
import React from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from "../Helpers/Utils";
import { APP_CONFIG } from '../constants/Config';

const CurrencyFormatter = (props) => {

    const defaults = { locale: APP_CONFIG.LOCALE, currency: APP_CONFIG.CURRENCY }

    // TODO - Should add Validations
    const getFormatter = options => {
        if(options && !options.locale) {
            delete options.locale;
        }
        if(options && !options.currency) {
            delete options.currency;
        }
        const config = Object.assign({}, defaults, options);
        return config;
    }
    const {
        price,
        currency,
        locale
    } = props;

    return (
        <React.Fragment>
            {toCurrency(price, getFormatter({
               locale,
               currency 
            }))}
        </React.Fragment>
    )
}

CurrencyFormatter.defaultProps = {
    currency: APP_CONFIG.CURRENCY
};

CurrencyFormatter.propTypes = {
    price: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
    currency: PropTypes.string,
    locale: PropTypes.string
};

export default CurrencyFormatter;