import React, { Component } from "react";

import OrderContext from "../../Providers/Order/OrderContext";
import TablesBox from "./TablesBox";
import OrdersBox from "./OrdersBox";
import WishesBox from "./WishesBox";


class OrderContainer extends Component {
  static contextType = OrderContext;
  constructor(props) {
    super(props);

    this.state = {
      orderTables: [],
      selectedTableOrder: [],
    };
  }

  componentDidMount() {
    const orderService = this.context;
    // const { isPageLoaded } = this.context;

    orderService.getAllTablesApi().then((tables) => {
      console.log("tables API==", tables);
      orderService.setPageLoaded(true);
    });

    // Below code not required as service is not single ton
    setTimeout(() => {
      orderService.subscribeCallWaiterApi();
      orderService.subscribeForNewOrder();
    }, 2000);

  }

  // TO be called before destroying component
  componentWillUnmount() {
    const orderService = this.context;
    orderService.destroySubscriptions();
  }


  componentDidUpdate(prevProps, prevState) {
    const orderService = this.context;
    // Will need to refresh state whenever this flag is true
    if (orderService.refreshRequired) {
      console.log("+++++Tables Data Updated+++++");
      console.log(orderService.tablesList);
      this.setState({
        orderTables: [...orderService.tablesList],
      });
      orderService.setRefreshRequired(false);
      // this.getOrdersForSelectedTable();
    }

  }
  

  // Need refactor. unuseful conditions
  onNotificationSeen = (tableName) => {
    let copiedTables = [...this.state.orderTables];
    const itemIndex = copiedTables.findIndex((element) => element.tableName === tableName);
    if (itemIndex > -1 && copiedTables[itemIndex].hasNotification) {
      console.log("::onNotificationSeen::");
      const orderService = this.context;
      const attributesToUpdate = { tableName: copiedTables[itemIndex].tableName, hasNotification: false};
      orderService.updateTableStatus(attributesToUpdate, true);
    }


  };




  render() {
    const { orderTables } = this.state;
    const { selectedTable, isPageLoaded, selectedTableOrders } = this.props.context;
/*     console.log(orderTables);
    console.log(this.props);
    console.log(selectedTable); */
    return (
      <React.Fragment>
        <div className="w-layout-grid griddashboard">
          {/** +++++++++++++ Ordered Items ++++++++++++++++ */}
          <TablesBox
            selectedTable={selectedTable}
            tables={orderTables}
            isLoading={!isPageLoaded}
            notificationSeen={this.onNotificationSeen}
          />

          {/** +++++++++++++ Ordered Items ++++++++++++++++ */}
          <OrdersBox
            selectedTable={selectedTable}
            orders={selectedTableOrders}
           /*  allCooked={this.isAllItemsCooked}
            isOverTime={this.onOrderGoingOverTime}
            updateSingleOrderStatus={this.singleOrderStatus}
            overtimeItemCooked={this.onOvertimeItemCooked} */
            isLoading={!isPageLoaded}
          />

          {/* ++++++++++++++ Wishes ++++++++++++ */}
          <WishesBox selectedTable={selectedTable} orders={selectedTableOrders} isLoading={!isPageLoaded} />
        </div>
      </React.Fragment>
    );
  }
}

const withContext = (Component) => {
  return (props) => {
    return (
      <OrderContext.Consumer>
        {(context) => {
          return <Component {...props} context={context} />;
        }}
      </OrderContext.Consumer>
    );
  };
};

export default withContext(OrderContainer);
