import React, { useContext, useRef } from "react";
import Timer from "react-compound-timer";
import OrderContext from "../../Providers/Order/OrderContext";
import { ICONS } from "../../Helpers/Icon.service";

const OrderSingleItem = (props) => {
  const { orderItem, order, timerFormatter, secondsFormatter } = props;
  const checkIcon = ICONS.CHECK_LIGHT_ICON;
  const checkDarkIcon = ICONS.CHECK_DARK_ICON;
  const sandBoxTimerIcon = ICONS.TIMER_RED_ICON;
  const payByCashIcon = ICONS.CURRENCY_DOLLAR_ICON;
  const secondsInHour = 3600;

  const orderService = useContext(OrderContext);
  const overtimeTimerRef = useRef();
  const orderItemTimerRef = useRef();

  const changeOrderItemStatus = (
    orderId,
    orderItemId,
    propToChange,
    callApi = true
  ) => {
    console.log("::changeOrderItemStatus::");
    // const orderService = this.context;
    orderService.updateOrderItemStatus(
      orderId,
      orderItemId,
      propToChange,
      callApi
    );
  };

  const itemHasCooked = (orderId, orderItem) => {
    const isPaid = orderItem.payMethod === "CASH" ? false : true;
    changeOrderItemStatus(orderId, orderItem.productId, {
      isCooked: true,
      isCooking: false,
      isPaid,
      productName: orderItem.productName,
      finishedItemTs: new Date().getTime(),
    });
    if (overtimeTimerRef && overtimeTimerRef.current) {
      overtimeTimerRef.current.pause();
    }
  };

  const itemHasUnCooked = (order, orderItem) => {
    if (orderItem.finishedItemTs) {
      if (orderItemTimerRef && orderItemTimerRef.current) {
        orderItemTimerRef.current.stop();
      }
      return;
    }

    const isPaid = orderItem.payMethod === "CASH" ? false : true;
    if (overtimeTimerRef && overtimeTimerRef.current) {
      overtimeTimerRef.current.start();
    }
    changeOrderItemStatus(order.orderId, orderItem.productId, {
      isCooked: false,
      isCooking: true,
      isPaid,
      productName: orderItem.productName,
    });
  };

  const orderItemOverTime = (orderId, orderItem) => {
    const isPaid = orderItem.payMethod === "CASH" ? false : true;
    changeOrderItemStatus(
      orderId,
      orderItem.productId,
      {
        overTime: true,
        isPaid,
        productName: orderItem.productName,
      },
      false
    );
  };

  const orderItemPaid = (orderId, orderItem) => {
    changeOrderItemStatus(orderId, orderItem.productId, {
      isCooked: true,
      isCooking: false,
      isPaid: true,
      productName: orderItem.productName,
    });
  };

  return (
    <React.Fragment>
      <div className="ordereditemblock">
        <Timer
          lastUnit="h"
          direction="backward"
          ref={orderItemTimerRef}
          onPause={() => {
            itemHasCooked(order.orderId, orderItem);
          }}
          onResume={() => {
            itemHasUnCooked(order, orderItem);
          }}
          checkpoints={[
            {
              time: 0,
              callback: () => {
                console.log(
                  "------------------Overtime-------------------------"
                );
                orderItemOverTime(order.orderId, orderItem);
              },
            },
          ]}
          initialTime={orderItem.preparationStartTime}
          formatValue={(value) => `${value < 10 ? `0${value}` : value} `}
          startImmediately={orderItem.isCooking}
        >
          {({ start, resume, pause, stop, reset, getTimerState, getTime }) => (
            <React.Fragment>
              {/** Items isCooked = true but not Paid  */}
              <div
                data-w-id="522869a9-d8d3-859e-743c-1b8a0da3f5b2"
                style={
                  orderItem.isCooked && !orderItem.isPaid
                    ? { display: "flex" }
                    : { display: "none" }
                }
                className="notpayed"
                onClick={() => {
                  orderItemPaid(order.orderId, orderItem);
                }}
              >
                <img
                  src={payByCashIcon}
                  loading="lazy"
                  width="33"
                  alt=""
                  className="image-30"
                />
              </div>

              {/** Items isCooked = true but not Paid  */}
              <div
                data-w-id="522869a9-d8d3-859e-743c-1b8a0da3f5b2"
                style={
                  orderItem.isCooked && orderItem.isPaid
                    ? { display: "flex" }
                    : { display: "none" }
                }
                className="payed"
                onClick={() => {
                  stop();
                }}
              >
                <img
                  src={checkDarkIcon}
                  loading="lazy"
                  width="33"
                  alt=""
                  className="image-30"
                />
              </div>

              {/** Product name */}
              <div af-sock="text" className="ordereditemtxt-2">
                {orderItem.quantity} x {" "}
                {orderItem.productName}
                <br />
              </div>

              {/** Order item Countdown timer */}
              {/*  Not to use this==> <Timer.Minutes initialTime={}
                              formatValue={(value) => `${value}:`}
                            />
                            <Timer.Seconds /> */}
              {orderItem.cookingTime && (
                <div className="div-block-777">
                  <div className="text-block-275">
                    {!orderItem.overTime && (
                      <div className="overtimetxt">
                        <Timer.Minutes formatValue={(value) => `${value}:`} />
                        <Timer.Seconds />
                      </div>
                    )}
                  </div>
                  {/**
                   * Order Going overtime
                   */}
                  {orderItem.overTime && (
                    <div className="overtimetxt-red">
                      -
                      <Timer
                        lastUnit="h"
                        formatValue={timerFormatter}
                        ref={overtimeTimerRef}
                        initialTime={orderItem.overTimeDurationTime}
                        startImmediately={
                          orderItem.overTime && orderItem.isCooking
                        }
                      >
                        {orderItem.overTimeDurationTime / 1000 >
                          secondsInHour && <Timer.Hours />}
                        <Timer.Minutes />
                        <Timer.Seconds formatValue={secondsFormatter} />
                      </Timer>
                    </div>
                  )}
                </div>
              )}

              {/** isCooking */}
              <div
                data-w-id="c645e24d-8c45-b283-cf77-755a492e6589"
                className="checkon"
                style={
                  orderItem.isCooking && !orderItem.overTime
                    ? { display: "flex" }
                    : { display: "none" }
                }
                onClick={() => {
                  pause();
                }}
              >
                <img
                  src={checkIcon}
                  loading="lazy"
                  width="33"
                  height="33"
                  alt=""
                  className="image-45"
                />
              </div>

              {/** Ovetime and isCooking */}
              <div
                data-w-id="1bb721c2-4e64-5c17-d3d8-8f2b586c1099"
                className="checkon"
                style={
                  orderItem.overTime && orderItem.isCooking
                    ? { display: "flex" }
                    : { display: "none" }
                }
                onClick={() => {
                  pause();
                }}
              >
                <img
                  src={sandBoxTimerIcon}
                  loading="lazy"
                  width="22"
                  alt=""
                  className="image-45"
                />
              </div>
            </React.Fragment>
          )}
        </Timer>
      </div>
    </React.Fragment>
  );
};

export default OrderSingleItem;
