// This should have absolute paths Only
export const ROUTES_PATH = {
    ROOT: "/",
    LANDING: '/landing',
    LOGIN: '/login',
    REGISTER : '/register',
    PASSWORD: '/password',
    HOME: '/home',
    MENU: '/menu',
    ORDER: '/order',
    MANAGE: '/manage',
    PROFILE: '/profile',
    get FORGOT_PASSWORD() { 
        return this.PASSWORD + '/recover' ;
    },
    get RESET_PASSWORD() { 
        return this.PASSWORD + '/reset' ;
    },
    get SETTINGS() {
        return this.PROFILE ;
    },
    get PAYMENT(){
        return this.PROFILE + '/payments';
    },
    get SUPPORT(){
        return this.PROFILE + '/support';
    },
    get LOGOUT() {
        return '/logout';
    }  
}  


export const ROUTES = {
    PUBLIC: [
        ROUTES_PATH.LOGIN,
        ROUTES_PATH.REGISTER,
        ROUTES_PATH.LANDING,
        ROUTES_PATH.PASSWORD
    ],
    AUTH: [
        ROUTES_PATH.LOGIN,
        ROUTES_PATH.REGISTER,
        ROUTES_PATH.LANDING,
        ROUTES_PATH.PASSWORD
    ],
    PROTECTED: [
        ROUTES_PATH.ORDER,
        ROUTES_PATH.MANAGE,
        ROUTES_PATH.HOME,
        ROUTES_PATH.MENU,
        ROUTES_PATH.PROFILE,
    ],
    DEFAULT_IF_LOGGED_IN:  ROUTES_PATH.HOME,
    DEFAULT_IF_NOT_LOGGED_IN: ROUTES_PATH.ROOT,
    SUCCESS_LOGGED_IN: ROUTES_PATH.HOME,

}

export const NAV_LINK = {

}