import Moment from "moment";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { LOCALSTORAGE_KEYS } from "../../../constants/app.constant";
import "./paypal.css";
// import i18n from '../i18n';

const PAYPAL_AUTH_TOKEN_SECRET = process.env.REACT_APP_PAYPAL_AUTH_TOKEN;
const PAYPAL_PAYPAL_AUTH_API = process.env.REACT_APP_PAYPAL_AUTH_API;
const PAYPAL_MERCHANT_API = process.env.REACT_APP_PAYPAL_MERCHANT_API;
const PAYPAL_SELLER_ACCESS_TOKEN_API =
  process.env.REACT_APP_PAYPAL_SELLER_ACCESS_TOKEN_API;
const PAYPAL_MERCHANT_ONBOARD_JS = process.env.REACT_APP_MERCHANT_ONBOARD_JS;
const PAYPAL_MERCHANT_RETURN_URL =
  process.env.REACT_APP_PAYPAL_MERCHANT_RETURN_URL;

export class PaypalIntegration {
  /* constructor(){

    } */

  getPaypalAuthToken = async () => {
    try {
      const data = "grant_type=client_credentials";
      const rawResponse = await fetch(PAYPAL_PAYPAL_AUTH_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Accept-Language": "en_US",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${PAYPAL_AUTH_TOKEN_SECRET}`,
        },
        body: data,
      });

      if (
        rawResponse.status &&
        rawResponse.status !== 201 &&
        rawResponse.status !== 200
      ) {
        return Promise.reject({
          message: "Couldn't authorize",
          error: rawResponse,
        });
      }
      const tokenResponse = await rawResponse.json();
      if (tokenResponse && tokenResponse.access_token) {
        return await this.generatePartnerReferralLink(
          tokenResponse.access_token
        );
      } else {
        return Promise.reject({ message: "No access token provided" });
      }
    } catch (error) {
      // console.error(error);
      const error_message = error.message || "Couldn't authorize";
      const completeError = error.error || error;
      return Promise.reject({ message: error_message, error: completeError });
    }
  };

  generatePartnerReferralLink = (token) => {
    return new Promise(async (resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open("POST", PAYPAL_MERCHANT_API);

      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          // console.info(xhr.status);
          // console.info(xhr.responseText);
          if (xhr.status === 201) {
            return resolve(JSON.parse(xhr.responseText));
          } else {
            return reject({
              message: "Couldn't generate link",
              error: JSON.parse(xhr.responseText),
            });
          }
        }
      };

      const tracking_id =
        uuidv4() +
        "-" +
        new Date().getTime() +
        "-" +
        process.env.REACT_APP_ENVIRONMENT;

      const paypal_return_url = PAYPAL_MERCHANT_RETURN_URL;

      const payload = `{
          "operations": [
            {
              "operation": "API_INTEGRATION",
              "api_integration_preference": {
                "rest_api_integration": {
                  "integration_method": "PAYPAL",
                  "integration_type": "FIRST_PARTY",
                  "first_party_details": {
                    "features": [
                      "PAYMENT",
                      "REFUND"
                    ],
                    "seller_nonce": "2020-02-05T15-43-54ZiBnhkZ7DMRJpzXd_AhUCfHgT2fPBWicqo1r7A2zbAj8"
                  }
                }
              }
            }
          ],
          "products": [
            "EXPRESS_CHECKOUT"
          ],
          "legal_consents": [
            {
              "type": "SHARE_DATA_CONSENT",
              "granted": true
            }
          ],
          "partner_config_override": {
              "return_url": "${paypal_return_url}"
          }
      }`;

      xhr.send(payload);

      /*  const rawResponse = fetch(PAYPAL_MERCHANT_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      });
      const tokenResponse = await rawResponse;
      console.info(tokenResponse);

      // return rawResponse;
      // const tokenResponse = rawResponse.json(); */
    });
  };

  static getSellerCredentials = async (
    seller_access_token,
    mercantIdPaypal
  ) => {
    let url = PAYPAL_SELLER_ACCESS_TOKEN_API;
    url = url.replace("{PARTNER_MERCHANT_ID}", mercantIdPaypal);

    const rawResponse = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Accept-Language": "en_US",
        Authorization: `Bearer ${seller_access_token}`,
      },
    });
    const credentials = await rawResponse.json();
    // remove localstorage secrets
    localStorage.removeItem(LOCALSTORAGE_KEYS.paypayl_seller); // used on index.html file
    return credentials;
  };

  generatetPaypalAuthlink = async () => {
    // window.PAYPAL.apps.Signup.MiniBrowser.closeFlow()
    try {
      const paypalSignupLinkPromise = await this.getPaypalAuthToken();
      // console.info(paypalSignupLinkPromise);
      return paypalSignupLinkPromise.links.filter(
        (url) => url.rel === "action_url"
      )[0].href;
    } catch (error) {
      // throw new Error(error);
      return Promise.reject(error);
    }

    // this.generatePartnerReferralLink(token);
    /* paypalSignupLinkPromise
      .then((data) => {
      
        let actionUrl = data.links.filter((url) => url.rel === "action_url")[0]
          .href;
        // setPaypalUrl(actionUrl)
        console.info("actionUrl>>> ", actionUrl, data);
      })
      .catch((err) => {
        console.error(err);
      }); */
  };
}
