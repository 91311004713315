import React, { useContext, useEffect, useState } from 'react';
import SideNavBar from './SideNavBar';
import Settings from './settings/Settings';
import Payments from './payment/Payments';
import Support from './support/Support';
import Logout from './Logout';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { data } from './constant';
import GastronView from './Gastron';
import { useHistory } from "react-router-dom";
import ProfileContext from '../../Providers/Profile/ProfileContext';



const Profile = () => {
    const [profileInfo, setProfileInfo] = useState(data);
    const [viewLogout, setViewLogout] = useState(false);
    let { path, url } = useRouteMatch();
    const profileService = useContext(ProfileContext);
    
    useEffect(() => {
        profileService.getProfileInfo().then( res => {
            profileService.setPageLoaded(true);
        })
    },[])

    const updateInfo = (info) => {
        setProfileInfo({ ...info })
        console.log({ ...profileInfo });
    }

    return (
        <React.Fragment>
                <div className="bodyadmin">
                    <div className="tabs">
                        <SideNavBar profileInfo={profileInfo} viewLogout={viewLogout} setViewLogout={(value) => setViewLogout(value)} />
                        <div className="tabs-content w-tab-content">
                            <Switch>
                                <Route path={`${path}/payments`} render={() => <Payments profileInfo={profileInfo} updateInfo={updateInfo} />} />
                                <Route path={`${path}/support`} render={() => <Support profileInfo={profileInfo} />} />
                                {/* <Route path={`${path}/logout`} render={() => } /> */}
                                <Route path={`${path}/gastron`} render={() => <GastronView />} />
                                <Route path='/' render={() => <Settings profileInfo={profileInfo} updateInfo={updateInfo} />} />
                            </Switch>
                        </div>
            
                    </div>
                </div>
        </React.Fragment>
    )
}

export default Profile;