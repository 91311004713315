import React, { Component } from "react";

export default class Wishes extends Component {
  render() {
    const { orders } = this.props;
    
    return (
      <React.Fragment>
        <div className="wishblockmain" style={{ display: "flex" }}>
          {orders &&
           orders.map((order, OIndex) => {
              return !order.isCompleted && order.wish ? (
                <React.Fragment key={OIndex}>
                  <div className="wishblock" key={OIndex}>
                    <div className="txtwish">{order.orderId}</div>
                    <div className="text-block-157">{order.wish}</div>
                  </div>
                </React.Fragment>
              ) : null;
            })}
        </div>
      </React.Fragment>
    );
  }
}
