import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { ROUTES, ROUTES_PATH } from '../../constants/Routes';
import { authenticationService } from '../../Services/auth.service';
import { Trans } from 'react-i18next';

const Logout = ({props}) => {
    const PATH_ROUTES = ROUTES_PATH;
    const default_route_if_not_logged_in = ROUTES.DEFAULT_IF_NOT_LOGGED_IN;
    /* const [isLoggedOut, setLoggedOut] = useState(false)

    const handleLogout = async () => {
        alert()
        await Auth.signOut();
        setLoggedOut(true);
      
        // userHasAuthenticated(false);
      }

    return (
        <React.Fragment>
            {console.log(isLoggedOut)}
            {(!isLoggedOut)} ? (
                <div data-w-tab="logout" className="logouttab">
                    <div className="div-block-949">
                        <div className="text-block-396">Before You Go</div>
                        <div className="text-block-396-copy">You can easily export your history in &quot;manage&quot;</div>
                        <div className="text-block-396-copy">Check our news for this week</div>
                        <div className="text-block-395" onClick={handleLogout}>LogOut</div>
                        <div data-w-id="TextBlock 11" className="text-block-397">6 hours in Duty<br />335 Sells today</div>
                    </div>
                </div>
            ) : (
                <Redirect to={'/home'} push={true} />
            )
        
        </React.Fragment>
    ) */



    const [loggedOut, setLoggedOut] = useState(false)

    const handleLogout = async () => {
        authenticationService.logout();
        // props.history.push(default_route_if_not_logged_in);
        
        setLoggedOut(true);
      
        // userHasAuthenticated(false);
      }

    if (loggedOut) {
        return <Redirect to={default_route_if_not_logged_in} push={true} />
    }

    return (
        <div data-w-tab="logout" className="logouttab">
            <div className="div-block-949">
                <div className="text-block-396"><Trans>pages.logout.beforeGo</Trans></div>
                <div className="text-block-396-copy"><Trans>pages.logout.exporthistoryText</Trans></div>
                <div className="text-block-396-copy"><Trans>pages.logout.newsChecktext</Trans></div>
                <div className="text-block-395 cursor-pointer" onClick={handleLogout}><Trans>topMenu.logout</Trans></div>
                {/* <div data-w-id="TextBlock 11" className="text-block-397">6 hours in Duty<br />335 Sells today</div> */}
            </div>
        </div>
    )


}

export default Logout;