import Moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

export class CustomValidator {

    static t = i18n.t; // = this.props.t;

    static validateTimings = ({...timings}) => {
        let state = {
            error: false,
            message: ''
        }
    
        // Begin and End time should not be equal and should not be initial values
        if((timings['timeBegin'] == '00:00' && timings['timeEnd'] == '00:00') || timings['timeBegin'] == timings['timeEnd']) {
            // alert("Begin time and End time cannot be same.");
            state.message = i18n.t('pages.menu.errorBeginTime');
            state.error = true;
            return state;
        }
    
        // End time cannot be 00:00
        if(timings['timeEnd'] == '00:00') {
            state.message = i18n.t('pages.menu.errorEndTime');
            state.error = true;
            return state;
        }
    
    
        // End time cannot come before Start time
        let beginningTime = Moment(timings['timeBegin'], 'hh:mm');
        let endTime = Moment(timings['timeEnd'], 'hh:mm');       
        if(endTime.isBefore(beginningTime)) {
            state.message = i18n.t('pages.menu.errorBeginStartsBefore');
            state.error = true;
            return state;
        }
    
        return state;
    
    }
    
    static validateDays =  ({...timings}) => {
        // console.log(i18n);
        // console.log(this.props);
        // console.log(i18n.t('pages.menu.errorDaySelection'));
        let state = {
            error: false,
            message: ''
        }
    
        let keys = Object.keys(timings);
        let activeDay = false;
        keys.forEach( (key,index) => {
            if(timings[key] !== 'timeBegin' && timings[key] !== 'timeEnd' && timings[key] === true) {
                activeDay = true;
            }
        })
    
        if(!activeDay) {
            state.message = i18n.t('pages.menu.errorDaySelection');
            state.error = true;
            // console.log(state);
            return state;
        }
    
        return state;
    
    }

    static validateProductNumber = (num, prodId, categoryList) => {
        let isExist = false;
        let state = {
            error: false,
            message: ''
        };
        if(categoryList && categoryList.length) {
            categoryList.forEach( (category, index) => {
                let products = category.products;
                if(products && products.length) {
                    products.forEach(product => {
                        if(product.productNum == num && product.id !== prodId) {
                            isExist = true;
                        }
                    });
                }
            })
        }

        if(isExist) {
            state.message = i18n.t('pages.menu.errorProductNumExist');
            state.error = true;
        }

        return state;

    }

}

export default withTranslation()(CustomValidator);

