
import React, { useState } from 'react';
import { Logger } from '../../Helpers/Logger.service';
import { USER_ACTIONS, SYNC_STATUS } from '../../constants/app.constant';
import { ICONS } from '../../Helpers/Icon.service';

import { validateActiveDay, validateActiveTime } from '../../Helpers/Utils';


export const Category = (props) => {
    const editIcon = ICONS.SETTINGS_DARK;
    const hideIcon = ICONS.HIDE_DARK_ICON;
    const deleteIcon = ICONS.TRASH_DARK_ICON;
    const unhideIcon = ICONS.UNHIDE_ICON;
    const timerIcon = ICONS.TIMER_DARK_ICON;
    const refreshIcon = ICONS.REFRESH_ICON;
    const clockIcon = ICONS.CLOCK_WHITE;

    const ACTIONS = USER_ACTIONS;
    const menu = props.menu;
    let isSelected = props.isSelected;
    // const [isSelected, setSelected] = useState(false);

    // setSelected(props.selectedCategory.id === props.menu.id);

    /* useEffect(() => {
        if(props.Selected === true){
            setPlanetData(props.Planet)
            console.log(planetData)
        }
    }, [props.Selected, props.Planet, setPlanetData]) */

    const selectCategory = (event, action = ACTIONS.SELECT) => {
        event.stopPropagation();
        props.selectCategory(menu, action);
        // setSelected(true);
    }

    const onEditCategory = (event) => {
        selectCategory(event, ACTIONS.EDIT);
    }

    const onHideCategory = (event) => {
        selectCategory(event, ACTIONS.HIDE);
    }

    const onDeleteCategory = (event) => {
        selectCategory(event, ACTIONS.DELETE);

    }

    const onSync = (event) => {
        Logger.log("::onSync::");
        selectCategory(event, ACTIONS.SYNC);
    }

    // hidden
    // hidden-selected


    const getCategoryAvailability = (category) => {

        const activeDaysAndTime = (weekdays, timeBegin, timeEnd) => {
             let mergedWeekdays = Object.keys(weekdays).filter(item => weekdays[item] === true ).map(w => w.charAt(0).toUpperCase() + w.slice(1) ).join('; ');
 
             const hiddenOverlay = <div className="limited_product">
                 {/* <img src={clockIcon} loading="lazy" width="22" height="22" alt="" className="image-208" /> */}
                 <div className="text-block-63-hidden">{mergedWeekdays}<br />{timeBegin} - {timeEnd}</div>
             </div>;
 
             return hiddenOverlay;
        }
 
         if(category.activeTime && category.activeTime.weekdays && !validateActiveDay(category.activeTime.weekdays)) {
             return activeDaysAndTime(category.activeTime.weekdays, category.activeTime.timeBegin, category.activeTime.timeEnd);
         }
 
         // timeBegin, timeEnd
         if(category.activeTime && !validateActiveTime(category.activeTime.timeBegin, category.activeTime.timeEnd)) {
             return activeDaysAndTime(category.activeTime.weekdays, category.activeTime.timeBegin, category.activeTime.timeEnd);
         }
 
         return null
     }



    return (
        <React.Fragment>

            <div af-el="category" className={"_403-2-2" + (isSelected ? '-selected' : '') } onClick={(e) => selectCategory(e)} >
                <div className="catnameblock">
                    <div af-sock="text" className="text-block-63">{menu.categoryName}</div>
                </div>
                <div className="div-block-405-copy">
                    {menu.status === SYNC_STATUS.INPROGRESS && <div className="div-block-996">
                        <img src={timerIcon} className="syncing" loading="lazy" width="22" height="22" data-w-id="915cced2-eb0a-236c-2ef0-7028dc40b996" />
                    </div>}
                    <div af-sock="edit" onClick={(e) => onEditCategory(e)} className="button_edit">
                        <img src={editIcon} loading="lazy" width="19" height="19" />
                    </div>
                    <div af-sock="hide" onClick={(e) => onHideCategory(e)} className="button_copy1">
                        <img src={hideIcon} loading="lazy" height="19" />
                    </div>
                    <div af-sock="delete" onClick={(e) => onDeleteCategory(e)} className="button_delete">
                        <img src={deleteIcon} loading="lazy" width="19" height="19" />
                    </div>
                    {(menu.status === SYNC_STATUS.ERROR || menu.status === SYNC_STATUS.SYNC)  && <div className="div-block-996-error">
                        <div af-sock="text" className="errortxt">error</div>
                        <img src={refreshIcon} className={"image-158 " + (menu.status === SYNC_STATUS.SYNC ? 'syncing': '')}
                            onClick={(e) => onSync(e)} loading="lazy" width="22" height="22" data-w-id="7668d0d1-215a-f063-4226-9c7070475468"  />
                    </div>}
                </div>
                {getCategoryAvailability(menu)}
                {menu && menu.hide === "true" && <div className={"hidespe-2"+ (isSelected ? '-selected' : '')}>
                    <div af-sock="text" className="text-block-63">{menu.categoryName}</div>
                    <img src={unhideIcon} onClick={(e) => onHideCategory(e)} loading="lazy" width="17" className="image-145" />
                </div>}
               {/*  {isSelected && menu.hide === "true" && <div className="hidespe-2-selected">
                    <div af-sock="text" className="text-block-63">{menu.categoryName}</div>
                    <img src={unhideIcon} onClick={(e) => onHideCategory(e)} loading="lazy" width="17" className="image-145" />
                </div>} */}
            </div>

        </React.Fragment>
    )
}

export default Category
