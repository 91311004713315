import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../Helpers/Icon.service';
import ProfileContext from '../../../Providers/Profile/ProfileContext';

const ContactUs = ({ contactInfo }) => {
    const { t, i18n } = useTranslation();
    const profileService = useContext(ProfileContext);
    const featherIcon = ICONS.FEATHER_ICON;
    const [contactForm, setContactForm] = useState({
        subject: '',
        bodyText: ''
    })

    const handleInputChange = (e, type) => {
        let tempForm = { ...contactForm };
        let value = e.target.value;
        tempForm[type] = value;
        tempForm[`${type}Error`] = null;
        let result = handleValidation(tempForm, type, value);
        setContactForm(result[1]);
    }

    const handleValidation = (tempForm, type, value) => {
        let valid = true;
        if (value === '') {
            valid = false;
            tempForm = {
                ...tempForm,
                [`${type}Error`]: `${(type.charAt(0).toUpperCase() + type.slice(1)).split(/(?=[A-Z])/).join(" ")} Required`
            }
        } else if (value.toString().length < 5) {
            valid = false;
            tempForm = {
                ...tempForm,
                [`${type}Error`]: `${(type.charAt(0).toUpperCase() + type.slice(1))} must be 5 character`
            }
        }
        else {
            valid = true;
            tempForm = {
                ...tempForm,
                [`${type}Error`]: null
            }
        }

        return [valid, tempForm]
    }

    const formValidation = (contactForm) => {
        let tempContactForm = { ...contactForm };
        let valid = true;
        let tempValid = false;
        let contactProps = Object.entries(contactForm);
        contactProps.map(([key, value]) => {
            let result = [false, tempContactForm];
            if (key.includes('Error')) {
                return [key, value];
            }
            else {
                result = handleValidation(tempContactForm, key, value);
            }
            tempValid = result[0];
            tempContactForm = result[1];
            if (valid && !tempValid) {
                valid = tempValid;
            }
            return [key, value];
        });
        setContactForm({ ...tempContactForm });
        return valid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let result = formValidation(contactForm);
        if(result){
            profileService.sendSupportService({
                text: contactForm.bodyText,
                subject: contactForm.subject
            })
        }
    }

    return (
        <div id="w-node-_875c5b9c-5eec-b57e-771d-894f367d49b0-fe54d577" className="div-block-667 data-content-section">
            <div className="text-block-390"><Trans>pages.profile.support.contactUs</Trans></div>
            <div className="text-block-391">{contactInfo.address1}<br />{contactInfo.address2}<br />
                {`${contactInfo.zipcode} ${contactInfo.city}`}<br />
                <Trans>pages.profile.shared.tel</Trans> {contactInfo.phone1}<br />{contactInfo.web}<br /><Trans>pages.profile.shared.umts</Trans> {contactInfo.umsTs}</div>
            <div className="form-block-47 w-form">
                <form id="email-form" name="email-form" data-name="Email Form" className="form-137">
                    <input type="text" value={contactForm.subject} className="text-field-178 w-input" maxLength="256" name="field-3" data-name="Field 3"
                        placeholder={t('pages.profile.support.subject')} id="field-3" onChange={e => handleInputChange(e, 'subject')} />
                    {contactForm.subjectError ? <p className='validationsError'>*{contactForm.subjectError}</p> : null}
                    <textarea placeholder={t('pages.profile.support.textMsg')} value={contactForm.bodyText} maxLength="5000" id="field" name="field"
                        className="textarea-13 w-input" onChange={e => handleInputChange(e, 'bodyText')}></textarea>
                    {contactForm.bodyTextError ? <p className='validationsError'>*{contactForm.bodyTextError}</p> : null}
                    <input type="submit" value={t('shared.actions.submit')} data-wait="Please wait..." className="submit-button-24 w-button" onClick={e => handleSubmit(e)} />
                </form>
            </div>
            <img src={featherIcon} loading="lazy" width="55" height="55" alt="" className="image-194"></img>
        </div>
    )
}

export default ContactUs;