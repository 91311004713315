import React, { useContext, useEffect, useRef, useState } from "react";
import { ICONS } from "../../../Helpers/Icon.service";
import { APP_CONFIG } from "../../../constants/Config";
import ProfileContext from "../../../Providers/Profile/ProfileContext";
import { deleteImageS3, uploadImageToS3 } from "../../../Helpers/Utils";
import OutSideCloseHelper from "../../../SharedComponents/OutSideCloseHelper";
import LoadingIndicator from '../../../SharedComponents/Loading';
import { Trans } from "react-i18next";


const SettingsTopHeader = () => {
  const modalRef = useRef(null);
  const settingsIcon = ICONS.SETTINGS_DARK;
  const [backImg, setBackImg] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const paypalIcon = ICONS.PAYPAL_ICON;
  const globeIcon = ICONS.GLOBE_OUTLINE_LIGHT;
  const profileService = useContext(ProfileContext);
  const { businessInfo, paymentInfo, languageInfo, setBusinessInfo } = profileService;

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      onUploadBannerImage(img);
      setShowDropdown(false);
      /* profileService.uploadImage({
        businessImage: URL.createObjectURL(img),
      });
      setBackImg(URL.createObjectURL(img)); */
    }
  }; 

  useEffect(() => {
    if(businessInfo) {
      const image_path = businessInfo.businessImage || "";
      setBackImg(image_path);
    }
  }, [businessInfo]);

  OutSideCloseHelper(modalRef, () => setShowDropdown(false));

  const deleteImagePath = () => {
    profileService.uploadImage({
      businessImage: '',
    });
    updateImagePath();
  };

  const onUploadBannerImage = (file) => {
    uploadImageToS3(file)
      .then((data) => {
        const imagePath = data.location;
        profileService.uploadImage({
          businessImage: imagePath,
        });
        updateImagePath(imagePath)
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateImagePath = (path = '') => {
    setBackImg(path);
    const updatedBusinessInfo = {...businessInfo, businessImage: path}
    setBusinessInfo(updatedBusinessInfo);
  }

  const onDeleteBannerImage = () => {
    const imagePath = businessInfo.businessImage;
    if (imagePath.indexOf("http") > -1) {
      let fileNameUrl = imagePath;
      let fileName = fileNameUrl.split("/").pop();
      // Delete Image from server if uploaded to server
      deleteImageS3(fileName)
        .then((data) => {
          console.log(data);
          deleteImagePath();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setShowDropdown(false);
  };

  console.log("businessInfo", { ...businessInfo });
  // const imgPath = APP_CONFIG.IMAGE_PATH;

  const backgroundImage = `linear-gradient(133deg, #002d40 29%, hsla(0, 0%, 100%, 0) 55%), url('${backImg}')`;
  console.log(backgroundImage);

  if(!profileService.isPageLoaded ){
    return <div id="w-node-_9c8615d7-7a05-59cc-0811-285ab7159d90-999f542a" className="div-block-700 businessImage-section"><LoadingIndicator /></div>;
  }

  return (
    <div
      id="w-node-_9c8615d7-7a05-59cc-0811-285ab7159d90-999f542a"
            style={{ backgroundImage: backImg ? `${backgroundImage}` : '' }}
      className="div-block-700 businessImage-section"
    >
      {businessInfo && <React.Fragment><div className="text-block-220">
        {businessInfo && businessInfo.businessName}
      </div>
      <div className="text-block-221">
        {businessInfo && businessInfo.businessStreetNr}
        <br />
        {businessInfo && businessInfo.businessTownZip} {
          (businessInfo.businessCountry ? businessInfo.businessCountry : "")
        }
        <br />
        <Trans>pages.profile.shared.tel</Trans> {businessInfo && businessInfo.businessPhone}
      </div>
      <div className="div-block-953">
        <img src={globeIcon} loading="lazy" width="17" height="17" alt="" />
        <div className="text-block-401">
          {languageInfo && languageInfo.language}
        </div>
        {paymentInfo && paymentInfo.isActive && (
          <img
            src={paypalIcon}
            loading="lazy"
            height="17"
            alt=""
            className="image-131"
          />
        )}
        <div className="text-block-401">
          {businessInfo && businessInfo.businessEmail}
        </div>
      </div></React.Fragment>}
      <div
        data-hover=""
        data-delay="0"
        className="dropdown-24 w-dropdown"
        ref={modalRef}
      >
        <div
          className="dropdown-toggle-21 w-dropdown-toggle"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <img
            src={settingsIcon}
            loading="lazy"
            width="22"
            height="22"
            alt=""
          />
        </div>
        {showDropdown ? (
          <nav
            className="dropdown-list-22 w-dropdown-list w--open"
            aria-labelledby="w-dropdown-toggle-2"
            style={{ display: showDropdown ? "block" : "none" }}
            id="w-dropdown-list-2"
          >
            <div className="text-block-417"><Trans>pages.profile.bannerImage.welcomeText</Trans></div>
            <label className="text-block-416" htmlFor={"imageUpload"}>
            <Trans>pages.profile.bannerImage.upload</Trans>
            </label>
            <input
              type="file"
              name="image"
              onChange={onImageChange}
              hidden={true}
              id={"imageUpload"}
            ></input>
            {backImg && (
              <div
                className="text-block-416-copy"
                onClick={onDeleteBannerImage}
              >
                <Trans>pages.profile.bannerImage.delete</Trans>
              </div>
            )}
          </nav>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SettingsTopHeader;
