import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { MainLayout } from "./Layouts/MainLayout";
// import Home from "./Views/Home";

import './App.css';
import { ROUTES, ROUTES_PATH } from "./constants/Routes";
import PrivateRoute from "./Shared/Protected";
import NoMatch from "./pages/Public/404";
import { LoginLayout } from "./Layouts/LoginLayout";
import { authenticationService } from './Services/auth.service';
import Landing from "./pages/Public/Landing";

const loading = () => {
  <p>Loading...</p>;
};
class App extends Component {

  public_routes = ROUTES.PUBLIC
  protected_routes = ROUTES.PROTECTED
  default_route_if_not_logged_in = ROUTES.DEFAULT_IF_NOT_LOGGED_IN;
  default_route_if_logged_in = ROUTES.DEFAULT_IF_LOGGED_IN;


  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    status: false,
    // isLoggedIn: true
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  async componentWillMount() {
    authenticationService.currentUser.subscribe( user => {
      if(user){
        authenticationService.isLoggedIn = true
        this.setAuthStatus(true);
      }else{
        localStorage.clear();
        authenticationService.isLoggedIn = false
        this.setAuthStatus(false);
      }
    })
    /* try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log(session);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
    } catch (error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    } */

    this.setState({ isAuthenticating: false });
  }


  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }


    return (
      <React.Fragment>
        <Router>
          <Switch>

          <Route path={this.public_routes } render={ (props) => <LoginLayout auth={authProps} isLoggedIn={this.state.isAuthenticated} {...props} />} />
         {/*  {<Route
              path={this.protected_routes}
              render={() => <MainLayout isLoggedIn={this.state.isLoggedIn} />} />}    */}

            <PrivateRoute path={this.protected_routes}
              isLoggedIn={this.state.isAuthenticated}
              component={MainLayout} />

            {/**
             * Following condition will execute when User has enterred on Root Path i.e "/"
             */}  
            {<Route exact path={ROUTES_PATH.ROOT}
              render={
                props => this.state.isAuthenticated ? (
                  <Redirect
                    to={{
                      pathname: this.default_route_if_logged_in,
                      state: { from: this.props.location }
                    }}
                  />
                ) : (
                  <Landing {...this.props} />
                  )
              } />}

            <Route exact render={NoMatch} />
            
          </Switch>
        </Router>

      </React.Fragment>
    );
  }
}
export default App;


