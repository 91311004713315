import React, { useState } from "react";
import Overlayinfo from "../../SharedComponents/OverlayInfo";
import { ICONS } from "../../Helpers/Icon.service";
import LanguageSelector from "../../SharedComponents/LanguageSelector";
import { authenticationService } from "../../Services/auth.service";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "../../constants/app.constant";
import ErrorMessage from "../../SharedComponents/ErrorMessage";
import LoadingIndicator from "../../SharedComponents/Loading";
import { useHistory } from "react-router-dom";
import { ROUTES_PATH } from "../../constants/Routes";

const ForgotPassword = (props) => {
  const { showHelp } = props;
  let history = useHistory();
  const keyIcon = ICONS.KEY_ICON_WHITE;
  const emailIcon = ICONS.EMAIL_ICON;

  const [serverError, setserverError] = useState({
    status: null,
    message: "",
  });
  const [serverSuccess, setserverSuccess] = useState(false);
  const [loading, setloading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
  } = useForm({ mode: "onSubmit" });

  const resetServerMessages = () => {
    setserverError({
      status: null,
      message: "",
    });
    setserverSuccess(false);
  };

  const onRecover = (data) => {
    console.log(data);
    resetServerMessages();
    setloading(true);

    authenticationService.recoverPassword(data.email).then(
      (user) => {
        setloading(false);
        resetServerMessages();
        history.push({ 
          pathname: ROUTES_PATH.RESET_PASSWORD,
          state: {email: data.email}
         })
        // setserverSuccess(true);
      },
      (error) => {
        console.log(error);
        setloading(false);
        setserverError({
          status: "error",
          message: error.message,
        });
      }
    );
  };

  const validationOptions = {
    email: {
      required: "Please enter your registred email",
      pattern: {
        value: EMAIL_REGEX,
        message: "Email is invalid",
      },
    },
  };


  const getLoader = () =>{
    if(loading) {
      return <LoadingIndicator />;
    }
  }

  // const [showHelpInfo, toggleHelpInfo] = useState(false);

  return (
    <React.Fragment>
      <div className="div-block-1052">
        <div className="div-block-1053">
          <div
            data-hover=""
            data-delay="0"
            className="dropdown-29-copy w-dropdown"
          >
            <LanguageSelector type="dark" />
          </div>
          <img
            src={keyIcon}
            loading="lazy"
            height="66"
            alt=""
            className="image-206"
          />
          <div className="text-block-507">Let us recover your log-in</div>
          <div className="form-block-59 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-143"
              onSubmit={handleSubmit(onRecover)}
            >
              <input
                type="text"
                className="text-field-185 w-input"
                maxLength="256"
                name="eMail"
                data-name="eMail"
                placeholder="eMail"
                {...register( 'email' , validationOptions.email)}
              />
              <input
                type="submit"
                value="Next"
                data-wait="Please wait..."
                data-w-id="73922387-d7f7-db4f-4881-f04febb6b999"
                className="submit-button-29 w-button d-block"
              />
            </form>
            {errors.email && <ErrorMessage text={errors?.email?.message} />}
            
            {/* <div className={"success-message-13 w-form-done " + (serverSuccess ? 'd-block': '')}>
            <img
                src={emailIcon}
                loading="lazy"
                width="33"
                height="33"
                alt=""
              />
              
              <div className="text-block-508">
                Thank you!
                <br />
                Please check your eMail
                <br />
                and follow the link we send.
              </div>
            </div> */}
            <div className={"error-message w-form-fail " + (serverError.status ? 'd-block' : '')}>
              <div className="text-block-57">{serverError.message}</div>
            </div>

          </div>
        </div>
        {getLoader()}
      </div>
      <Overlayinfo
        className="helpme_pop"
        overwriteDefaultClass={true}
        title=""
        info=""
        show={showHelp}
        onHide={() => props.hideHelpinfo(!showHelp)}
      >
        <div className="rich-text-block-3 w-richtext">
          <h4>What’s a Rich Text element?</h4>
          <p>
            The rich text element allows you to create and format headings,
            paragraphs, blockquotes, images, and video all in one place instead
            of having to add and format them individually. Just double-click and
            easily create content.
          </p>
          <h6>Static and dynamic content editing</h6>
          <p>
            A rich text element can be used with static or dynamic content. For
            static content, just drop it into any page and begin editing. For
            dynamic content, add a rich text field to any collection and then
            connect a rich text element to that field in the settings panel.
            Voila!
          </p>
          <h6>How to customize formatting for each rich text</h6>
          <p>
            Headings, paragraphs, blockquotes, figures, images, and figure
            captions can all be styled after a class is added to the rich text
            element using the "When inside of" nested selector system.
          </p>
        </div>
      </Overlayinfo>
    </React.Fragment>
  );
};

export default ForgotPassword;
