import React, { Component, createRef } from "react";
import OrderContext from "./OrderContext";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";
import * as mutations from "../../graphql/mutations";
import { authenticationService } from "../../Services/auth.service";
import { Logger } from "../../Helpers/Logger.service";
import { API_PATH_NAME } from "../../Config/";
import { ICONS } from "../../Helpers/Icon.service";
import { OrderModel, Overtime, TableModel } from "../../Models/ProductAndCategory";

import QRCodeStyling from "qr-code-styling";
import { APP_CONFIG } from "../../constants/Config";

import _ from "lodash";
import { awsRequestInterceptor } from "../../Helpers/Interceptors";

// import { callWaiterSubscription } from "../../graphql/subscriptions";

const ORDER_API = {
  TABLE_CREATE: "/order/table/create",
  TABLE_UPDATE: "/order/table/rename",
  DELETE_TABLE: "/order/table/delete",
  GET_ALL: "/order/table/getAll/",
};

const NOTIFY_INTERVAL = 10000; // 10 seconds


export default class OrderProvider extends Component {
  qrCodeRef = createRef();
  waiterCallSubscription = null;
  createOrderSubscription = null;
  gastronIcon = ICONS.GASTRON_ICON_BLACK;
  tableOrderIntervalId = null;


  // ref = useRef<HTMLDivElement>(null);

  state = {
    selectedTable: null,
    tablesList: [],
    isPageLoaded: false, // Should be called once
    refreshRequired: true,
    isCallWaiterSubscribed: false,
    isCreateOrderSubscribed: false,
  };

  get allTables() {
    return this.state.tablesList;
  }

  get selectedTable() {
    return this.state.selectedTable;
  }

  get selectedTableOrders() {
    return this.state.selectedTableOrders;
  }

  beginTableOvertimeCron () {
    if(!this.tableOrderIntervalId) {
      this.tableOrderIntervalId = setInterval(() => this.checkIfOrderGoingOvertime(), NOTIFY_INTERVAL)
    }
  }

  terminateTableOvertimeCron () {
    if(this.tableOrderIntervalId) {
      clearInterval(this.tableOrderIntervalId);
    }
  }

  checkIfOrderGoingOvertime () {
    const tList = [...this.allTables];

    tList.forEach( (table, index) => {
      // let tableOrders = [...table.orders];
      const {orders , ...tableDetails} = table;
      const isOverTime = Overtime.isTableOvertime(orders);
      // Update if Object is not yet updated and then make API call
      if(orders.length && !tableDetails.isOverTime && isOverTime) {
        const updatedTable = { ...tableDetails, isOverTime, orders: orders };
        tList.splice(index, 1, { ...updatedTable });
        this.setState({
          tablesList: tList,
          refreshRequired: true
        });
        const tableAttributes = {allOrdersFinished: false, tableName: tableDetails.tableName, isOverTime }
        this.updateTableAttributesApi(tableAttributes)
        // this.updateTableStatus(updatedTable, true);
      }

      /* {
        overTime: true,
        isPaid,
        productName: orderItem.productName,
      },
      */
      /* this.updateOrderItemStatus(
        orderId,
        orderItemId,
        propToChange,
        callApi
      ); 
      
      updateTableAttributesApi
      */

      // updateTableStatus
      // this.updateOrderAttributesApi(propToChange);
    });

    
  }



  setPageLoaded = (isLoaded) => {
    this.setState({
      isPageLoaded: isLoaded,
    });
  };

  setRefreshRequired = (isRequired) => {
    this.setState({
      refreshRequired: isRequired,
    });
  };

  setTableList = (list, tableToSelect = null) => {
    console.warn("%c setTableList", list, tableToSelect)
    console.warn(list, tableToSelect)
    let selectedTableOrders = [];
    if (tableToSelect) {
      selectedTableOrders = this.getOrdersForSelectedTable(tableToSelect);
    }
    if (list) {
      this.setState(
        {
          tablesList: list,
          selectedTable: tableToSelect,
          refreshRequired: true,
          selectedTableOrders,
        },
        () => {
          console.log("table List Updated:", this.allTables);
        }
      );
    }
  };

  setSelectedTable = (table) => {
    console.log("%c setSelectedTable", "color:yellow", table);
    table = new TableModel(table);
    let selectedTableOrders = [];
    if (table) {
      selectedTableOrders = this.getOrdersForSelectedTable(table);
    }
    this.setState({
      selectedTable: table,
      selectedTableOrders,
    }, () => {
      console.log("=================> selectedTable", table)
    });

  };

  mapOrderTables = (tableList) => {
    let mappedOrderTables = [];
    if (tableList && tableList.length) {
      tableList.forEach((table) => {
        mappedOrderTables.push(new TableModel(table));
      });
    }
    return mappedOrderTables;
  };

  setDefaultSelected = (tableList) => {
    if (tableList && tableList.length) {
      this.setSelectedTable(tableList[0]);
    }
  };

  findTableIndexById = (pk) => {
    if (!pk) {
      throw "Table Id is required";
    }

    let tList = [...this.allTables];
    return tList.findIndex((table) => table.tableName === pk);
  };

  createUpdateTable = (index, { ...serverTableObj }) => {
    console.log(index);
    // index 0 is valid for array, we need to check only null and undefined
    if (index == null || index === undefined) {
      let id = serverTableObj.tableName || serverTableObj.id;
      index = this.findTableIndexById(id);
    }

    if (index === -1) {
      throw "Table Doesn't exist";
    }

    let tList = [...this.allTables];
    // serverTableObj = { ...tList[index], ...serverTableObj }
    tList.splice(index, 1, new TableModel(serverTableObj));
    const tableToSelect = tList[index];
    this.setTableList([...tList], tableToSelect);
  };

  deleteTableFromList = (id) => {
    let index = this.findTableIndexById(id);
    if (index === -1) {
      throw "Table Doesn't exist";
    }
    let tList = [...this.allTables];
    tList.splice(index, 1);
    this.setTableList([...tList]);
    // this.setSelectedTable(null);
  };


  // Update Table List and order when new order arrived
  onNewOrderArrived = (order) => {
    console.log("New Order Arrived", order);
    // Find out for which table
    // Update notification
    // Get order details.

    // Cases
    // If no table selected then should not select any table
    // If selected table is the one which has got the order
    // If Selected table is not the one which got the order

    // this is done because selected Table Could be null after following API
    /* const selectedTable = this.selectedTable ? _.cloneDeep(this.selectedTable) : null;

    this.getAllTablesApi().then((tableList) => {
      // Pre select the already selected table if or not same table has the new order
      if (tableList && tableList.length && selectedTable) {
        const index = this.findTableIndexById(selectedTable.tableName);
        if (index > -1) {
          // this.setTableList(tableList);
          this.setSelectedTable(tableList[index]);
        }
      }
      // const tableToSelect = tableList[index];
      // this.setSelectedTable
    }); */


    if (order?.value?.data?.onCreateOrder) {
      let completeOrderDetails = order.value.data.onCreateOrder;
      const { products, ...tableDetails } = completeOrderDetails;

      // let orderedItems = completeOrderDetails.products;
      const newOrderedItems = products.map((item, index) => {
        return new OrderModel({ ...item, ...tableDetails })
      });

      // scenarios to handle
      // 1) If New Order Table is selected - Update Table and orders as well using splice
      // 2) If New order Table is not selected - Update Table order objects only in background

      // // 1) If New Order Table is selected - Update Table and orders as well using splice
      if (this.state.selectedTable?.tableName === tableDetails.tableName) {
        let tableOrders = [...this.state.selectedTable.orders, ...newOrderedItems];
        const isOverTime = Overtime.isTableOvertime(tableOrders);
        this.createUpdateTable(null, { ...tableDetails, allOrdersFinished: false, hasNotification: true, isOverTime, orders: tableOrders });
        // this.setSelectedTable
        /**
         * 
         * allOrdersFinished: false
          hasCustomerCall: false
          hasNotification: false
          isOverTime: true
         */
      } else {
        // 2) If New order Table is not selected - Update Table order objects only in background
        // this.setSelectedTable
        const tablePK = `TABLEOBJECT#${tableDetails.userId}#${tableDetails.tableName}`
        const tableIndex = this.findTableIndexById(tableDetails.tableName);
        const tList = [...this.allTables];
        let tableOrders = [...tList[tableIndex].orders, ...newOrderedItems];
        const isOverTime = Overtime.isTableOvertime(tableOrders);
        const updatedTable = { ...tableDetails, allOrdersFinished: false, hasNotification: true, isOverTime, orders: tableOrders };
        tList.splice(tableIndex, 1, { ...updatedTable });
        this.setState({
          tablesList: tList,
          refreshRequired: true
        });

      }


      // this.setTableList

      // tempOrdersList.splice(orderIndex, 1, new OrderModel({...orderSelectedTable[orderIndex], ...propToChange}) )

    }




  };

  onWaiterCallReceived = (tableObject) => {
    console.log(tableObject);
    const index = this.findTableIndexById(tableObject.tableName);
    if (index > -1) {
      const hasCustomerCall = tableObject.hasCustomerCall;

      let tList = [...this.allTables];
      const updatedTable = { ...tList[index], hasCustomerCall };
      // serverTableObj = { ...tList[index], ...serverTableObj }
      tList.splice(index, 1, { ...updatedTable });
      this.setTableList([...tList], this.selectedTable);
    }
  };

  /**
   *
   * API Calls
   */

  // Get all Tables
  getAllTablesApi = (selectDefault = false) => {
    Logger.log("::getAllTables::");
    const userId = authenticationService.userID;

    return API.get(API_PATH_NAME.ORDER, ORDER_API.GET_ALL + userId)
      .then((response) => {
        console.log(response);
        let list = this.mapOrderTables(response);
        let tableToSelect = null;
        if (selectDefault && list.length > 0) {
          tableToSelect = list[0];
        }
        this.setTableList(list, tableToSelect);
        /* if (selectDefault) {
          this.setDefaultSelected(list);
        } */
        return list;
      })
      .catch((error) => {
        console.log(error);
        if (
          error === "No current user" ||
          error.status === 400 ||
          error.code === "NotAuthorizedException"
        ) {
          authenticationService.logout();
          // this.props.history.push(this.default_route_if_not_logged_in);
          // return Promise.reject(error);
        }
      });
  };

  // Create Table
  createTableApi = ({ ...table }) => {
    const userId = authenticationService.userID;
    let options = {
      body: {
        ...table,
        userId: userId,
        isOverTime: false,
        allOrdersFinished: false,
        hasCustomerCall: false,
      },
    };
    return API.post(
      API_PATH_NAME.ORDER,
      ORDER_API.TABLE_CREATE,
      awsRequestInterceptor(options)
    )
      .then((tableObject) => {
        // console.log(tableObject);
        const listCount = this.allTables;
        const index = listCount.length > 0 ? listCount.length + 1 : 0;
        this.createUpdateTable(index, tableObject);
        return tableObject;
      })
      .catch((error) => {
        console.log(error);
        if (
          error === "No current user" ||
          error.status === 400 ||
          error.code === "NotAuthorizedException"
        ) {
          authenticationService.logout();
        }
      });
  };

  // Edit/Update Table -- ONLY tableName
  updateTableApi = ({ ...table }) => {
    const userId = authenticationService.userID;
    console.log(table);

    let index = this.findTableIndexById(table.tableNameOld);
    console.log("index", index);

    let options = {
      body: {
        ...table,
        userId: userId,
      },
    };
    return API.post(
      API_PATH_NAME.ORDER,
      ORDER_API.TABLE_UPDATE,
      awsRequestInterceptor(options)
    )
      .then((response) => {
        console.log(response);
        this.createUpdateTable(index, { ...table, ...response });
        return response;
      })
      .catch((error) => {
        console.log(error);
        if (
          error === "No current user" ||
          error.status === 400 ||
          error.code === "NotAuthorizedException"
        ) {
          authenticationService.logout();
        }
      });
  };

  // Delete Table
  deleteTableApi = ({ ...tableObject }) => {
    const userId = authenticationService.userID;
    let options = {
      body: {
        userId,
        tableName: tableObject.tableName,
      },
    };
    return API.post(
      API_PATH_NAME.ORDER,
      ORDER_API.DELETE_TABLE,
      awsRequestInterceptor(options)
    )
      .then((response) => {
        console.log(response);
        this.deleteTableFromList(tableObject.tableName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Download QR Code
  createDownloadQRCode = (tableName) => {
    const userId = authenticationService.userID;
    const encodedUrl = window.btoa(`userId=${userId}&&tableName=${tableName}`);
    const url =
      APP_CONFIG.MOBILE_APP_URL + `?${encodedUrl}`;
    console.log("QR Code Styling", url);  
    const qrCode = new QRCodeStyling({
      width: 400,
      height: 400,
      type: "png",
      // image: "7",
      data: url,
      dotsOptions: {
        color: "#002D40",
        type: "extra-rounded"
      },
      backgroundOptions: {
        color: "#e9ebee",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
      cornersSquareOptions: {
        color: '#002D40',
        type: 'extra-rounded'
      }
    });

    // center image
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.width = 300;
    canvas.height = 300;
    ctx.fillStyle = "#002d40";
    ctx.font = "900 200pt Montserrat";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    // ctx.scale(1.2, 1.2);
    ctx.fillText(tableName, canvas.width / 2, canvas.height / 2);
    ctx.strokeStyle = "#002d40";

    ctx.rect(0, 0, canvas.width, canvas.height);
    let fullQuality = canvas.toDataURL("image/png", 1.0);
    qrCode.update({
      image: fullQuality
    });

    qrCode.append(document.getElementById("qrCodeCanvas"));
    qrCode.download({ name: `qr-table-${tableName}`, extension: "png" });
    setTimeout(() => {
      document.getElementById("qrCodeCanvas").innerHTML = "";
    });
  };

  //Subscribe to Call to Waiter API - graph QL
  subscribeCallWaiterApi = () => {
    if (this.state.isCallWaiterSubscribed) {
      return;
    }
    const userId = authenticationService.userID;

    const onWaiterCallSubscription = 
      `subscription onCallWaiter {
            onWaiterCall(userId: "${userId}") {
              table {
                allOrdersFinished
                hasCustomerCall
                isOverTime
                tableName
                userId
              }
            }
          } 
        `;

    this.waiterCallSubscription = API.graphql(
      graphqlOperation(onWaiterCallSubscription)
    ).subscribe({
      next: (res) => {
        console.log(res);
        if (res && res.value.data)
          this.onWaiterCallReceived(res.value.data.onWaiterCall.table);
        // Do something with the data
      },
    });

    setTimeout(() => {
      this.setState({
        isCallWaiterSubscribed: true,
      });
    }, 500);
  };

  //subscribe to New Order on Table - Graph QL
  subscribeForNewOrder = () => {
    // Automate job to look for any overtime order/table
    this.beginTableOvertimeCron();

    if (this.state.isCreateOrderSubscribed) {
      return;
    }
    const userId = authenticationService.userID;

    const onCreateOrderSubscription =
      `subscription onCreateOrder {
        onCreateOrder(userId: "${userId}") {
          incomingOrderTs
          orderId
          payMethod
          products {
            price
            productId
            productName
            productNum
            quantity
            timestampStart
            productDurationTime
            isCooked
            isCooking
            isPaid
            imageUrl
          }
          tableName
          userId
          wish
          isCompleted
          isPaid
        }
    }`;

    this.createOrderSubscription = API.graphql(
      graphqlOperation(onCreateOrderSubscription)
    ).subscribe({
      next: (table) => {
        console.log(table);
        this.onNewOrderArrived(table);
        // Do something with the data
      },
    });

    setTimeout(() => {
      this.setState({
        isCreateOrderSubscribed: true,
      });
    }, 1000);
  };

  // Destroy All subscriptions

  destroySubscriptions = () => {
    console.log("Unsubscribed");
    if (this.waiterCallSubscription) this.waiterCallSubscription.unsubscribe();
    if (this.createOrderSubscription)
      this.createOrderSubscription.unsubscribe();

    if(this.tableOrderIntervalId) {
      this.terminateTableOvertimeCron();
    }  
  };

  //  Listen for Update Order on Table - Graph QL

  checkIfSingleOrderCompleted = () => {};

  checkIfAllItemsCooked = () => {};

  updateOrderStatus = (orderId, orderItemId, property) => {
    // Make API call here..
    // checkIfSingleOrderCompleted
    // checkIfAllItemsCooked
    // onOrderGoingOverTime
    // onOvertimeItemCooked
    // itemHasUnCooked
    // checkIfAllItemsCooked
  };

  confirmWaiterCall = ({ ...tableAttributesToUpdate }) => {
    console.log("%c confirmWaiterCall", "color:blue", tableAttributesToUpdate);
    if (!tableAttributesToUpdate) {
      return;
    }
    const userId = authenticationService.userID;
    let payload = {
      tableName: tableAttributesToUpdate.tableName,
      userId: userId,
    };
    // Update local state without waiting for server response

    const index = this.findTableIndexById(tableAttributesToUpdate.tableName);
    if (index > -1) {
      this.updateTableList(index, tableAttributesToUpdate);
    }
    return API.graphql({
      query: mutations.confirmWaiterCall,
      variables: {
        input: payload,
      },
    })
      .then((res) => {
        console.log("confirm waiter data", res);
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  updateTableList(index, attributesToUpdate) {
    let tList = [...this.allTables];

    const tableObject = { ...tList[index], ...attributesToUpdate };
    tList.splice(index, 1, new TableModel(tableObject));
    this.setTableList([...tList], tList[index]);
  }


  updateTableStatus = ({...tableAttributesToUpdate}, apiUpdate = false) => {
    console.log("%updateTableStatus", "color:red", tableAttributesToUpdate);
    if (!tableAttributesToUpdate) {
      return;
    }
    

    // Update local state without waiting for server response
    const index = this.findTableIndexById(tableAttributesToUpdate.tableName);
    if (index > -1) {
      this.updateTableList(index, tableAttributesToUpdate);
    }

    if(apiUpdate){
      this.updateTableAttributesApi(tableAttributesToUpdate)
    }

    
  }


  /**
   * 
   * tableName and updateType and userId is required to pass in Payload
   * 
   */
  updateTableAttributesApi = (tableAttributesToUpdate) => {
    console.log("%updateTableAttributesApi", "color:red", tableAttributesToUpdate);
    
    const userId = authenticationService.userID;
    let tablePayload = {
      tableAttributes: { ...tableAttributesToUpdate },
      userId,
      updateType: "TABLE",
    };

  
    return API.graphql({
      query: mutations.updateTableAttributes,
      variables: {
        input: tablePayload,
      },
    })
      .then((res) => {
        console.log("Update Table Attributes", res);
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  /**
   * 
   * orderId, productName, updateType and userId is required to pass in Payload
   * 
   */
  updateOrderAttributesApi = ({ ...orderAttributesToUpdate }) => {
    if (!orderAttributesToUpdate) {
      return;
    }
    console.log("updateOrderAttributesApi",orderAttributesToUpdate);
    const userId = authenticationService.userID;
    let orderPayload = {
      productAttributes: { ...orderAttributesToUpdate },
      userId,
      updateType: "PRODUCT",
    };
    return API.graphql({
      query: mutations.updateOrderAttributes,
      variables: {
        input: orderPayload,
      },
    })
      .then((res) => {
        console.log("data Update Order attributes API", res);
        /* if(res.data && res.data.updateAttributes && res.data.updateAttributes.orderAttributes) {
        const orderAttributes = res.data.updateAttributes.orderAttributes;
        const index = this.findTableIndexById(orderAttributesToUpdate.tableName);
        if(index > -1) {
          this.updateTableList(index, orderAttributes)
        }
      } */

        // this.createUpdateTable()
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  /**
   * conditions to manage
   * if order item going over time then set isOvertime on table
   * if item [isCooked, isCooking] = [true, false] the check if all items of table been cooked or not and change color accordingly -- DONE
   * timer issue
   * Handle isPaid as well -- DONE
   * allOrderFinished -- DONE but have Issues
   *
   *
   * Things to check
   * Table List
   * Selected Table
   * grouped selected orders
   *
   */

   updateOrderItemStatus = (orderId, orderItemId, propToChange, callApi) => {
    console.log("propToChange>>>>>>>>", propToChange);
    // console.log(this.state.selectedTable);
    /* let selectedTableOrders = this.state.selectedTable.orders;

    const index = selectedTableOrders.findIndex(
      (orderItems) =>
        orderItems.orderId === orderId && orderItems.productId === orderItemId
    ); */

    let orderSelectedTable = this.selectedTable.orders;
    const orderIndex = orderSelectedTable.findIndex(
      (order) =>
      order.orderId === orderId && order.productId === orderItemId
    );
    if (orderIndex > -1) {
      let tempOrdersList = _.cloneDeep(orderSelectedTable);
      // console.log(orderSelectedTable);
      console.log("OLD DATA", orderSelectedTable[orderIndex]);
      
      console.log("==========================================");
      // console.log({...orderSelectedTable[index], ...propToChange});
      tempOrdersList.splice(orderIndex, 1, new OrderModel({...orderSelectedTable[orderIndex], ...propToChange}) )
      
      let isTableOverTime = false;
      if (propToChange.overTime) {
        // if overtime then update Table isOvertime
        isTableOverTime = true;
      }
      
      const allOrdersFinished = tempOrdersList.every(order => order.isCooked &&  order.isPaid  )
      console.log("isTableOverTime", isTableOverTime);
      console.log("allOrdersFinished", allOrdersFinished);
      
      let tList = _.cloneDeep(this.allTables);
      const tableIndex = this.findTableIndexById(orderSelectedTable[orderIndex].tableName);
      const selectededTable = tList[tableIndex];
      
      const tableObject = { ...selectededTable,  isOverTime: isTableOverTime, allOrdersFinished: allOrdersFinished, orders: tempOrdersList };
      tList.splice(tableIndex, 1, new TableModel(tableObject));
      
      console.log("tempOrdersListtempOrdersList>>>", tempOrdersList)
      
      console.log("=========================================")
      this.setTableList(tList, new TableModel({...tableObject, orders: tempOrdersList}))
      

      /*  tempOrdersList.splice(index, 1, {...selectedTableOrders[index], ...propToChange });
      const selectedTable = this.state.selectedTable;
      // console.log();
      this.setSelectedTable({...selectedTable, orders: [...tempOrdersList]}); */

      propToChange = {...propToChange, orderId: orderId};
      console.log("propToChange==> ", propToChange);
      if(callApi){
        this.updateOrderAttributesApi(propToChange);
      }

      // TODO: be Done
      // Handle product item unchecked after marking cooked
      
      
      if(allOrdersFinished || (selectededTable.allOrdersFinished)) {
        const isFinished = allOrdersFinished;
        this.updateTableAttributesApi({allOrdersFinished: isFinished, tableName: selectededTable.tableName })
      }
    }
    // this.updateOrderStatus(orderId, orderItemId);
  };

  completeOrder = (order, timestamp) => {
    const userId = authenticationService.userID;
    let orderPayload = {
      orderAttributes: { orderId: order.orderId, orderDurationTime: timestamp  },
      userId,
      updateType: "ORDER",
    };
    return API.graphql({
      query: mutations.updateOrderCompleteDuration,
      variables: {
        input: orderPayload,
      },
    })
      .then((res) => {
        console.log("data Order Complete API", res);
      })
      .catch((err) => {
        console.log("error", err);
      });

  }

  /**
   * 
   *  isCooked: false,
      isCooking: true,
      overTime: false,

   */

  groupOrdersById = (orders = []) => {
    let allOrders = orders;
    var groupedOrders = _(allOrders)
      .groupBy((x) => x.orderId)
      .map(function(value, key){
        return {
          orderId: key,
        bookingTime: value && value.length ? value[0].incomingOrderTs : "",
        orderItems: value,
        processedTimeStamp: Math.floor((new Date().getTime() - value[0].incomingOrderTs) ),
        orderCompletionTime: value[0].orderDurationTime || null, // When Order is finished 
        isCompleted: value.every((item) => item.isCooked === true),
        wish: value && value.length ? value[0].wish : "",
        orderTotal: value.reduce(
          (sum, item) => sum + item.price * item.quantity,
          0
        ),
        orderTimer: function() {
          return this.isCompleted && this.orderCompletionTime ? (this.orderCompletionTime - this.bookingTime ) : this.processedTimeStamp
        }
        }
      })
      .value();
    console.log(groupedOrders);
    if(groupedOrders) {
      groupedOrders = groupedOrders.slice().sort( (a,b) => b.bookingTime - a.bookingTime)
    }
    return groupedOrders;
    // console.log(grouped2);
  };

  getOrdersForSelectedTable = (table) => {
    if (!table) {
      return;
    }

    let unGroupedOrder = table.orders;
    let allOrders = this.groupOrdersById(unGroupedOrder);
    console.log(
      "========should be called only once=================",
      allOrders
    );
    return allOrders;
  };

  render() {
    return (
      <OrderContext.Provider
        value={{
          isLoading: this.state.isLoading,
          refreshRequired: this.state.refreshRequired,
          isPageLoaded: this.state.isPageLoaded,
          setSelectedTable: this.setSelectedTable,
          selectedTable: this.selectedTable,
          selectedTableOrders: this.selectedTableOrders,
          tablesList: this.state.tablesList,
          setPageLoaded: this.setPageLoaded,
          setRefreshRequired: this.setRefreshRequired,
          getAllTablesApi: this.getAllTablesApi,
          createTableApi: this.createTableApi,
          updateTableApi: this.updateTableApi,
          deleteTableApi: this.deleteTableApi,
          setTableList: this.setTableList,
          updateTableStatus: this.updateTableStatus,
          createDownloadQRCode: this.createDownloadQRCode,
          subscribeCallWaiterApi: this.subscribeCallWaiterApi,
          subscribeForNewOrder: this.subscribeForNewOrder,
          destroySubscriptions: this.destroySubscriptions,
          updateOrderItemStatus: this.updateOrderItemStatus,
          confirmWaiterCall: this.confirmWaiterCall,
          completeOrder: this.completeOrder,
          beginTableOvertimeCron: this.beginTableOvertimeCron,
          checkIfOrderGoingOvertime: this.checkIfOrderGoingOvertime
        }}
      >
        {this.props.children}
      </OrderContext.Provider>
    );
  }
}
