import React, { useState } from "react";
import Overlayinfo from "../../SharedComponents/OverlayInfo";
import { ICONS } from "../../Helpers/Icon.service";
import LanguageSelector from "../../SharedComponents/LanguageSelector";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { authenticationService } from "../../Services/auth.service";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../SharedComponents/ErrorMessage";
import LoadingIndicator from "../../SharedComponents/Loading";
import { ROUTES_PATH } from "../../constants/Routes";
import { PASSWORD_REGEX } from "../../constants/app.constant";

const ResetPassword = (props) => {
  const location = useLocation();
  const history = useHistory();
  // console.log(props);
  // console.log(history);
  console.log(location);
  const { showHelp } = props;
  const lockIcon = ICONS.LOCK_ICON_LIGHT;
  const keyIcon = ICONS.KEY_ICON_WHITE;
  const homeIcon = ICONS.HOME_ICON_LANDING;
  const [loading, setloading] = useState(false);
  const [stepCount, setstepCount] = useState(0);

  const [serverError, setserverError] = useState({
    status: null,
    message: "",
  });
  const [serverSuccess, setserverSuccess] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    trigger,
    control,
  } = useForm({ mode: "onSubmit" });

  const resetServerMessages = () => {
    setserverError({
      status: null,
      message: "",
    });
    setserverSuccess(false);
  };

  const resetPassword = (data) => {
    console.log(data);
    resetServerMessages();
    setloading(true);
    const email = location.state.email;

    authenticationService.resetPassword(email, data.code, data.password).then(
      (res) => {
        console.log(res);
        setloading(false);
        resetServerMessages();
        setserverSuccess(true);
      },
      (error) => {
        console.log(error);

        setloading(false);
        if (error.name === "CodeMismatchException") {
          setstepCount(0);
        }
        setserverError({
          status: "error",
          message: error.message,
        });
      }
    );
  };

  const validationOptions = {
    username: {
      required: "Username is required",
    },
    code: {
      required: "Code is required",
      minLength: {
        value: 6,
        message: 'Not sufficient characters'
      }
    },
    password: {
      required: "Password is required",
      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Should have at least One Uppercase, one lower case, One digit and One special character",
      },
    },
    cPassword: {
      required: "Retype Password is required",
      validate: (value) =>
        value === getValues("password") || "The passwords do not match",
    },
  };

  const moveNext = async () => {
    resetServerMessages()
    const isValid = await trigger("code");
    console.log(isValid);
    if (isValid) {
      setstepCount(stepCount + 1);
    }
  };

  const getLoader = () => {
    if (loading) {
      return <LoadingIndicator />;
    }
  };

  const renderResetCode = () => {
    /* if (stepCount !== 0) {
      return null;
    } */
    if(serverSuccess) {
      return null
    }

    return (
      <React.Fragment>
        <div className="text-block-507-code d-block">
          Check Your eMail
          <br />
          Type the Activation Code in
        </div>
        <div className="form-block-59 w-form">
          <div className="form-143">
            <input
              type="text"
              className="text-field-185-code w-input d-block"
              maxLength="256"
              name="code"
              data-name="code"
              placeholder="Code"
              id="code"
              {...register("code", validationOptions.code)}
            />
           {/*  <div
              onClick={moveNext}
              data-w-id="451f55e6-0a9c-60e7-4a33-315f7363aa7b"
              className="text-block-517"
            >
              next
            </div> */}
          </div>
          {errors.code && <ErrorMessage text={errors?.code?.message} />}
        </div>
      </React.Fragment>
    );
  };

  const renderResetPassword = () => {
    /* if (stepCount === 0) {
      return null;
    } */
    if(serverSuccess) {
      return null
    }
    return (
      <React.Fragment>
        {/* <div className="text-block-507">set new Password</div> */}
        <div className="form-block-59 w-form">
          <input
            type="password"
            className="text-field-185-copy w-input"
            maxLength="256"
            name="password"
            data-name="password"
            placeholder="new password"
            id="password"
            {...register("password", validationOptions.password)}
          />
          {errors.password && <ErrorMessage text={errors?.password?.message} />}
          <input
            type="password"
            className="text-field-185-copy w-input"
            maxLength="256"
            name="password-2"
            data-name="Password 2"
            placeholder="retype password"
            id="password-2"
            {...register("cPassword", validationOptions.cPassword)}
          />
          {errors.cPassword && (
            <ErrorMessage text={errors?.cPassword?.message} />
          )}
          <input
            type="submit"
            value="Reset Password"
            data-wait="Please wait..."
            data-w-id="73922387-d7f7-db4f-4881-f04febb6b999"
            className="submit-button-29-copy w-button"
          />
        </div>
      </React.Fragment>
    );
  };

  const displayServerMessages = () => {
    return (
      <React.Fragment>
        <div
          className={
            "error-message-13-copy w-form-fail " +
            (serverError.status ? "d-block" : "")
          }
        >
          <div className="text-block-57">{serverError.message}</div>
        </div>

        <div
          className={
            "success-message-13 w-form-done " + (serverSuccess ? "d-block" : "")
          }
        >
          <div className="text-block-508">
            Done!
            <br />
            You can now log-in
            <br />
            using your new password
          </div>
          <NavLink
            className="div-block-1055-copy w-inline-block"
            to={ROUTES_PATH.ROOT}
          >
            <img src={homeIcon} loading="lazy" width="22" height="22" alt="" />
            <div className="text-block-509">Home</div>
          </NavLink>
        </div>
      </React.Fragment>
    );
  };

  if (!location.state.email) {
    console.log(location.state.email);
    history.push(ROUTES_PATH.FORGOT_PASSWORD);
  }

  return (
    <React.Fragment>
      <div className="div-block-1052">
        <div className="div-block-1053">
          <LanguageSelector type="dark" />

          {!serverSuccess && <img
            src={keyIcon}
            loading="lazy"
            height="66"
            alt=""
            className="image-206"
          />}
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-143-copy"
            onSubmit={handleSubmit(resetPassword)}
          >
            {renderResetCode()}
            {renderResetPassword()}
            {getLoader()}
          </form>
          {displayServerMessages()}
        </div>
      </div>
      <Overlayinfo
        className="helpme_pop"
        overwriteDefaultClass={true}
        title=""
        info=""
        show={showHelp}
        onHide={() => props.hideHelpinfo(!showHelp)}
      >
        <div className="rich-text-block-3 w-richtext">
          <h4>What’s a Rich Text element?</h4>
          <p>
            The rich text element allows you to create and format headings,
            paragraphs, blockquotes, images, and video all in one place instead
            of having to add and format them individually. Just double-click and
            easily create content.
          </p>
          <h6>Static and dynamic content editing</h6>
          <p>
            A rich text element can be used with static or dynamic content. For
            static content, just drop it into any page and begin editing. For
            dynamic content, add a rich text field to any collection and then
            connect a rich text element to that field in the settings panel.
            Voila!
          </p>
          <h6>How to customize formatting for each rich text</h6>
          <p>
            Headings, paragraphs, blockquotes, figures, images, and figure
            captions can all be styled after a class is added to the rich text
            element using the "When inside of" nested selector system.
          </p>
        </div>
      </Overlayinfo>
    </React.Fragment>
  );
};

export default ResetPassword;
