import React, { useEffect, useState } from 'react';
import MenuContext from '../../Providers/Menu/MenuContext';
import { APP_CONFIG } from '../../constants/Config';
import Carousel, { consts } from 'react-elastic-carousel';
import CurrencyFormatter from '../../SharedComponents/Currency';
import { TAG_ICONS } from '../../Mock/mock-data';
import { isEmpty } from '../../Helpers/Utils';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';



export const Overview = (props) => {
    const { t, i18n } = useTranslation();

    const TAB_TYPES = {
        MENU: 'menu',
        OVERVIEW: 'overview',
        PRODUCT: 'product'
    };

    const taggedIcons = TAG_ICONS;
    const imagePath = APP_CONFIG.IMAGE_PATH + '/';

    let carousel = null;

    const [selectedTab, setSelectedTab] = useState(TAB_TYPES.MENU);
    const [productList, setProductsList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [categoryList, setCategoryList] = useState([]);


    const onTabClicked = (tab = TAB_TYPES.MENU) => {
        switch (tab) {
            case TAB_TYPES.MENU:

                break;
            case TAB_TYPES.OVERVIEW:

                break;
            case TAB_TYPES.PRODUCT:

                break;

            default:
                break;
        }

        setSelectedTab(tab);
    }

    const getImageStyles = (product) => {
        let styles = {};
        if(product.images && product.images.length) {
            styles = {
                backgroundImage: `url(${product.images[0]}`
            }
        }
        return styles;

    }

    const renderProductList = () => {
        return productList && productList.map((product, index) => {
            return (
                <React.Fragment key={index}>
                    <div className="m_item-4">
                        {/*  <a href="#" className="itempicture-3 w-inline-block">
                            <div className="text-block-281">{product.price}€</div>
                        </a> */}
                        <div style={getImageStyles(product)} className={"itempicture10-2 " + ((!product.images || !product.images.length) ? ' placeholder-nopicture' : '')}>
                            <div className="text-block-281">{product.price && <CurrencyFormatter price={product.price} />} </div>
                        </div>
                        {product.hide && <div className="div-block-975">
                            <img src="assets/images/hide_lAsset-1.svg" loading="lazy" width="33" height="33" />
                        </div>}
                        <div className="div-block-839">
                            <div className="text-block-435">{product.name}</div>
                            <div className="div-block-856"><img src="assets/images/minusAsset-2.svg" loading="lazy" width="19" />
                                <div className="text-block-329">0</div><img src="assets/images/plusAsset-1.svg" loading="lazy" width="19" />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        })
    }

    const renderCategoryList = () => {
        return categoryList && categoryList.map((data, index) => {
            return <div key={index} className={"todaysspecial" + (data.hide === 'true' ? '_hidden' : '')}>
                {data.categoryName}
            </div>
        })
    }
    

    useEffect(() => {
        if (!props.context.allCategories) return;
        setCategoryList([...props.context.allCategories]);
        onTabClicked(TAB_TYPES.MENU);
    }, [props.context.allCategories])


    useEffect(() => {
        if (!props.context.getSelectedProduct) {
            onTabClicked(TAB_TYPES.MENU);
            return;
        }
        setSelectedProduct(props.context.getSelectedProduct);
        onTabClicked(TAB_TYPES.PRODUCT);
        carousel.goTo(0)
    }, [props.context.getSelectedProduct])


    useEffect(() => {
        if (!props.context.selectedCategory || isEmpty(props.context.selectedCategory) ) {
            onTabClicked(TAB_TYPES.MENU);
            return;
        };
        setProductsList(props.context.selectedCategory.products);
        onTabClicked(TAB_TYPES.OVERVIEW);
        /* if (categoryList.length > 0) {
        } */
    }, [props.context.selectedCategory])

    const mapIcons = (icons) => {
        let tagsHtml = [];
        if(icons && icons.length) {
            taggedIcons.forEach( (tags, index) => {
                icons.forEach( (icon, indexInner) => {                    
                    if(tags.name.toLowerCase() === icon.id.toLowerCase()){
                        tagsHtml.push(<img key={indexInner} src={imagePath + tags.image} loading="lazy" width="22" className="image-20" />)
                    }
                })
            } )
        }
        return tagsHtml;
    } 

    const getStyle = (tab) => {
        let styles = {};
        if (tab === selectedTab) {
            styles = { display: 'flex' }
        } else {
            styles = { display: 'none' }
        }
        return styles;
    }

    const getActiveTabStyle = (tab) => {
        let styles = {};
        if (tab && tab === selectedTab) {
            styles = { 'color': '#ff414d' }
        }
        return styles;
    }

    const PreviewFooter = () => {
        return (
            <div className="mobile_nav-2">
                <div className="div-block-31"></div>
                <div className="text-block-443"><Trans>pages.menu.searchFilter</Trans></div>
                <div className="mobile_menu-2">
                    <div href="#" className="mobile_back-2"><img src="assets/images/house_small_lightAsset-1.svg" width="22" height="22" alt="" className="image-15" />
                        <div className="txticon-3"><Trans>pages.menu.menu</Trans></div>
                    </div>
                    <div className="mobile_bell-2"><img src="assets/images/bell_small_lAsset-1.svg" width="22" height="22" alt="" className="image-15-bell" />
                        <div className="txticon-3"><Trans>pages.menu.waiter</Trans></div>
                    </div>
                    <div href="#" className="mobile_cart-2"><img src="assets/images/cart_lAsset-1.svg" width="22" height="22" alt="" className="image-15" />
                        <div className="txticon-3"><Trans>pages.menu.cart</Trans></div>
                    </div>
                    <div className="div-block-37"><img src="assets/images/bell2_redAsset-1.svg" loading="lazy" width="22" height="22" alt="" className="image-15" />
                        <div className="text-block-431">call waiter?</div>
                    </div>
                    <div data-w-id="43d5db9f-d2b9-6173-bfc3-8059fa0682aa" className="text-block-301-copy">yes</div>
                    <div data-w-id="43d5db9f-d2b9-6173-bfc3-8059fa0682ac" className="text-block-430">no</div>
                </div>
            </div>
        )
    }

    const getCarouselImages = (productImages) => {
        if(!productImages || !productImages.length)
            return <div className="slide-5 w-slide placeholder-nopicture"></div>  


        return productImages && productImages.map((imagePath, index) => {
             var uuid = uuidv4() + ''+ index;
            return <div key={uuid} style={{backgroundImage: `url(${imagePath})`}} className="slide-5 w-slide"></div>
         })

    }

    const getCarousel = (product) => {
        let productImages = product.images ; 
        return (<div className="slider-3 w-slider">
        <div className="mask w-slider-mask">
            <Carousel ref={ref => (carousel = ref)} itemsToShow={1} showArrows={false}
            >
               {getCarouselImages(productImages, product)}
            </Carousel>


        </div>
        <div className="left-arrow w-slider-arrow-left" onClick={() => carousel.slidePrev()}>
            <div className="icon w-icon-slider-left"></div>
        </div>
        <div className="right-arrow w-slider-arrow-right" onClick={() => carousel.slideNext()}>
            <div className="icon-2 w-icon-slider-right"></div>
        </div>
        {/*  <div className="slide-nav w-slider-nav w-slider-nav-invert w-round"></div> */}
    </div>);
    }

    

    return (
        <React.Fragment>
            <MenuContext.Consumer>
                {context => (
                    <React.Fragment>
                        <div className="overview">
                            <div className="text-block-441"><Trans>pages.menu.previewSelect</Trans><br /><Trans>pages.menu.downbelow</Trans></div>

                            <div className="menupreview" style={getStyle(TAB_TYPES.MENU)}>
                                <div className="mobile_mainpic-2 businessImage-section" style={{backgroundImage: `url(${context.businessInfo?.businessImage}`}}>
                                    {context.businessInfo && <div className="text-block-426">{context.businessInfo.businessName}</div>}
                                </div>
                                <div className="div-block-967">
                                    <div className="div-block-31"></div>
                                    {renderCategoryList()}
                                </div>

                                <PreviewFooter />
                            </div>

                            <div className="overviewpreview" style={getStyle(TAB_TYPES.OVERVIEW)}>
                                <div className="div-block-24">
                                    <div className="text-block-434">{props.context.selectedCategory ? props.context.selectedCategory.categoryName : <Trans>pages.menu.spacialToday</Trans>}</div>
                                </div>
                                <div className="mobile_view-3">
                                    <div className="w-layout-grid grid-46">
                                        {renderProductList()}
                                    </div>
                                </div>
                                <PreviewFooter />
                            </div>



                            {<div className="itempreview" style={getStyle(TAB_TYPES.PRODUCT)}>
                                <div className="item_slider-2">
                                        {getCarousel(selectedProduct)}
                                </div>
                                <div className="itemtxtlayer-2">
                                    <div className="iteminfo1-2">{selectedProduct && selectedProduct.name}</div>
                                    <div className="icons">
                                        {mapIcons(selectedProduct.icons)}
                                    </div>
                                    <div className="iteminfo2-2">{selectedProduct.information}</div>
                                    {selectedProduct.extraInformation && <div className="moretxt-2">mehr</div>}
                                    <div className="text-block-436">{selectedProduct.price && <CurrencyFormatter price={selectedProduct.price} />}</div>
                                </div>
                                <PreviewFooter />
                            </div>}

                            <div className="div-block-970-copy">
                                <div className="text-block-439"><Trans>pages.menu.preview</Trans></div>
                                <div className="div-block-1009">
                                <div className="div-block-972">
                                    <div data-w-id="9868faee-b9f0-c9f2-287c-55aa8e7e0044" className="previewtxt" style={getActiveTabStyle(TAB_TYPES.MENU)} onClick={(e) => onTabClicked(TAB_TYPES.MENU)}><Trans>pages.menu.menu</Trans></div>
                                    <div data-w-id="5bbeafcd-2bdd-dc12-0670-13d9da0dc39c" className="previewtxt" style={getActiveTabStyle(TAB_TYPES.OVERVIEW)} onClick={(e) => onTabClicked(TAB_TYPES.OVERVIEW)}><Trans>pages.menu.overview</Trans></div>
                                    <div data-w-id="f52eaa7f-d54f-a375-64ab-9a16bd1c4c1f" className="previewtxt" style={getActiveTabStyle(TAB_TYPES.PRODUCT)} onClick={(e) => onTabClicked(TAB_TYPES.PRODUCT)}><Trans>pages.menu.product</Trans></div>
                                </div>
                                </div>
                                <div className="div-block-971"></div>
                            </div>

                            {!context.isPageLoaded && <div data-w-id="846a3e6d-3dad-6cc5-05fa-521409a26ff0" className="pageloading"></div>}

                        </div>


                    </React.Fragment>
                )}
            </MenuContext.Consumer>

        </React.Fragment>
    )
}

const withContext = (Component) => {
    return (props) => {
        return (
            <MenuContext.Consumer>
                {(context) => {
                    return <Component {...props} context={context} />
                }}
            </MenuContext.Consumer>
        )
    }
}

export default withContext(Overview);


// export default Overview