import React, { Component, createRef } from "react";
import OrderProvider from "../../Providers/Order/OrderProvider";
import OrderContainer from "./OrderContainer";
// import { Link } from "react-router-dom";


export default class Order extends Component {
  qrCodeRef = createRef();

  render() {
    
    return (
      <React.Fragment>
         <OrderProvider qrcodeHtml={this.qrCodeRef}>
          <OrderContainer />
         </OrderProvider>
         <div ref={this.qrCodeRef}></div>
      </React.Fragment>
    );
  }
}
