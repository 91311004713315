import React, { Component, createRef } from "react";
import HomeContext from "./HomeContext";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { authenticationService } from "../../Services/auth.service";
import { API_PATH_NAME } from "../../Config/";
import { APP_CONFIG } from "../../constants/Config";
import * as query from '../../graphql/query';
import Moment from 'moment';

const HOME_API = {
  ITEM_LIST: '/profile/image/update'
};


/**
*
* @param {*} options
* @returns
* Interceptor for the request to modify.
* Might change in the future as per need with new API integration
*/
const awsRequestInterceptor = (options = {}) => {
  let headerOptions;
  // If header is passed then set it otherwise the default one will get applied.

  headerOptions = options.header
    ? options.header
    : { "Content-Type": "application/json" };
  // If response type is passed then set it otherwise the default one will get applied.
  const responseType = options.responseType ? options.responseType : "json";

  const httpParams = options.params ? options.params : {};

  const httpOptions = {
    headers: headerOptions,
    responseType: responseType,
    // queryStringParameters
    params: httpParams,
    timeout: 30000, // Default timeout 30seconds
  };

  // OPTIONAL (return the entire response object instead of only response.data)
  if (options.observe) {
    httpOptions.response = true;
  }

  httpOptions.body = options.body ? options.body : {};

  return httpOptions;
};

export default class HomeProvider extends Component {
  constructor() {
    super();
    setTimeout(() => {
      this.setState({
        showConsider: true
      })
    }, 3000);
  }
  state = {
    itemList: [],
    isPageLoaded: false, // Should be called once
    showConsider: false
  }

  setPageLoaded = (isLoaded) => {
    this.setState({
      isPageLoaded: isLoaded
    })
  }

  closeConsider = () => {
    this.setState({
      showConsider: false
    })
  }

  getItemList = (filterData, firstDay) => {
    let userId = authenticationService.userID;
    let today = new Date();

    // console.log(Moment(818188200000).format("M/DD/YYYY"))
    let payload = {
      from: Moment(firstDay).format("M/DD/YYYY"),
      to: Moment(filterData).format("M/DD/YYYY"),
      userId: userId,
    };

    return API.graphql({
      query: query.topSellerProduct,
      variables: {
        input: payload,
      },
    })
      .then((res) => {
        console.log("confirm waiter data", res);
        this.setState({
          itemList: res.data.TopSellerProducts.topSellers
        })
        return res.data.TopSellerProducts.topSellers;
      })
      .catch((err) => {
        console.error("error", err);
      });

  }

  
  getItemList = (filterData, firstDay) => {
        let userId = authenticationService.userID;
        let today = new Date();

        // console.log(Moment(818188200000).format("M/DD/YYYY"))
        let payload = {
            from: Moment(firstDay).format("YYYY-MM-DD"),
            to: Moment(filterData).format("YYYY-MM-DD"),
            userId: userId,
        };
        
        return API.graphql({
            query: query.topSellerProduct,
            variables: {
              input: payload,
            },
          })
            .then((res) => {
              console.log("confirm waiter data", res);
              this.setState({
                itemList: res.data.TopSellerProducts.topSellers
              })
              return res.data.TopSellerProducts.topSellers;
            })
            .catch((err) => {
              console.error("error", err);
            });
      
    }

    render (){
        return (
            <HomeContext.Provider
              value={{
                isPageLoaded: this.state.isPageLoaded,
                setPageLoaded: this.setPageLoaded,
                itemList: this.state.itemList,
                getItemList: this.getItemList,
              }}>
                  {this.props.children}
              </HomeContext.Provider>
        )
    }

}