import React, { Component } from "react";
import ProfileContext from "./ProfileContext";
import { API } from "aws-amplify";
import { authenticationService } from "../../Services/auth.service";
import { API_PATH_NAME } from "../../Config/";
import { APP_CONFIG } from "../../constants/Config";
import { awsRequestInterceptor } from "../../Helpers/Interceptors";

const PROFILE_API = {
    GET_PROFILE_INFO: "/profile/information/",
    UPDATE_PROFILE_INFO: "/profile/information/update",
    SUPPORT_PROFILE: '/profile/support',
    IMAGE_UPLOAD: '/profile/image/update'
  };

  export default class ProfileProvider extends Component{
      state = {
          profileInfo: {},
          adminInfo: {},
          businessInfo: {},
          openingTimingInfo: {},
          paypalInfo: {},
          cashInfo: {},
          languageInfo: {},
          isPageLoaded: false, // Should be called once
      }   

      setBusinessInfo = (info) => {
          this.setState({
            businessInfo: info
          })
      }

      setPageLoaded = (isLoaded) => {
        this.setState({
            isPageLoaded: isLoaded
        })
    }

      updateAdminInfo = (payload) => {
          let userId = authenticationService.userID;
          payload.userId = userId;
          let options = {
              body: {
                  ...payload
              }
          }
          console.log("before call")
          return API.post(API_PATH_NAME.PROFILE, PROFILE_API.UPDATE_PROFILE_INFO, awsRequestInterceptor({...options}))
          .then(data => {
              console.log("coming here", this.state, data)
             this.setState({
                 ...this.state,
                adminInfo: {adminName: data.adminName,
                adminStreetNr: data.adminStreetNr,
                adminTownZip: data.adminTownZip,
                adminPhone: data.adminPhone,
                adminEmail: data.adminEmail,
                adminWebpage: data.adminWebpage,}
             })
          }).catch(err => {
              console.log("err", err)
              if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
          })
      }

      updateBusinessInfo = (payload) => {
          let userId = authenticationService.userID;
          payload.userId = userId;
          let options = {
              body: {
                  ...payload
              }
          }
          return API.post(API_PATH_NAME.PROFILE, PROFILE_API.UPDATE_PROFILE_INFO, awsRequestInterceptor({...options}))
          .then(data => {
             this.setState({
                 ...this.state,
              businessInfo:  { businessEmail: data.businessEmail,
                businessImage: data.businessImage,
                businessName: data.businessName,
                businessPhone: data.businessPhone,
                businessStreetNr: data.businessStreetNr,
                businessTownZip: data.businessTownZip,
                businessWebpage: data.businessWebpage}
             })
          }).catch(err => {
              console.log("err", err)
              if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
          })
      }

      updateLanguegeInfo = (payload) => {
        let userId = authenticationService.userID;
          payload.userId = userId;
          let options = {
              body: {
                  ...payload
              }
          }
          return API.post(API_PATH_NAME.PROFILE, PROFILE_API.UPDATE_PROFILE_INFO, awsRequestInterceptor({...options}))
          .then(data => {
              console.log("data", data)
             this.setState({
                 ...this.state,
                languageInfo: {
                    languageAdmin: data.languageAdmin,
                    timeFormat: data.timeFormat,
                    currency: data.currency
                }
             })
          }).catch(err => {
              console.log("err", err)
              if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
          })
      }

      updateTimings = (data) => {
        let userId = authenticationService.userID;
        let payload = {};
          payload.userId = userId;
          payload.informationType = "OPENINGTIME";
          payload.timings = {...data}
          let options = {
              body: {
                  ...payload
              }
          }

          console.log(options);
          return API.post(API_PATH_NAME.PROFILE, PROFILE_API.UPDATE_PROFILE_INFO, awsRequestInterceptor({...options}))
          .then(data => {
              console.log("data", data)
              if(data && data.timings){
                  console.log("data.timings",data.timings);
                  this.setState({
                      ...this.state,
                      openingTimingInfo: {...data.timings}
                  })
              }
          }).catch(err => {
              console.log("err", err)
              if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
          })
      }
      
      updatePaymentInfo = (payload) => {
          let userId = authenticationService.userID;
          payload.userId = userId;
          let options = {
              body: {
                  ...payload
              }
          }
          console.log("options", options, payload)

          return API.post(API_PATH_NAME.PROFILE, PROFILE_API.UPDATE_PROFILE_INFO, awsRequestInterceptor({...options}))
          .then(data => {
              console.log("data", data);
              
              let paymentOption = data?.paypal ? {
                            paypalInfo: {
                            paypalId: data.paypal.paypalId,
                            paypalSecret: data.paypal.paypalSecret,
                            merchantId: data.paypal.merchantId,
                            accountStatus: data.paypal.accountStatus,
                            isActive: data.paypal.isActive,
                        }
                    } : {cashInfo: {
                        isActive: ( !data?.cash?.isActive) ? false : true
                    }}
            

             this.setState({
                 ...this.state,
                ...paymentOption

             });
             return data;
          }).catch(err => {
              console.error("err", err)
              if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
          })
      }

      sendSupportService = (payload) => {
        let userId = authenticationService.userID;
        payload.userId = userId;
        let options = {
            body: {
                ...payload
            }
        }
        return API.post(API_PATH_NAME.PROFILE, PROFILE_API.SUPPORT_PROFILE, awsRequestInterceptor({...options}))
          .then(data => {
              console.log("data", data)
            //  this.setState({
                
            //  })
          }).catch(err => {
              console.log("err", err)
              if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
          })
      }

      getProfileInfo = () => {
        let userId = authenticationService.userID;
        return API.get(API_PATH_NAME.PROFILE, PROFILE_API.GET_PROFILE_INFO + userId).then((data) => {
            console.log("atda", data)

            if(data) {
                const businesskey = `BUSINESSINFO`;
                const languageKey = `LANGUAGE`;
                const paymentKey = `PAYMENTS`;
                const adminKey = `ADMININFO`;
                const timingsKey = `OPENINGTIME`;
                this.setState({
                    adminInfo: data[adminKey] || {},
                    businessInfo: data[businesskey] || {},
                    languageInfo: data[languageKey] || {},
                    paypalInfo: {
                        paypalId: data[paymentKey] && data[paymentKey].paypal && data[paymentKey].paypal.paypalId,
                        paypalSecret: data[paymentKey] && data[paymentKey].paypal && data[paymentKey].paypal.paypalSecret,
                        merchantId: data[paymentKey] && data[paymentKey].paypal && data[paymentKey].paypal.merchantId,
                        accountStatus: data[paymentKey] && data[paymentKey].paypal && data[paymentKey].paypal.accountStatus,
                        isActive: (data[paymentKey] && data[paymentKey].paypal && data[paymentKey].paypal.isActive) || false
                    },
                    cashInfo: {
                        isActive: ( !data?.paymentKey?.cash?.isActive) ? false : true
                    },
                    openingTimingInfo: data[timingsKey] && data[timingsKey]['timings']
                }, () => {
                    console.log(this.state);
                })
            }
        })
        .catch((err) => {
            console.log("err", err);
            if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
        })
      }

      toggleActiveInactivePaypal = () => {
          this.setState({
              ...this.state,
              paypalInfo: {
                  ...this.state.paypalInfo,
                  isActive: !this.state.paypalInfo.isActive
              }
          })
      }

      uploadImage = (payload) => {
        let userId = authenticationService.userID;
        payload.userId = userId;
        let options = {
            body: {
                ...payload
            }
        }
        return API.post(API_PATH_NAME.PROFILE, PROFILE_API.IMAGE_UPLOAD, awsRequestInterceptor({...options}))
        .then((data) => {
            if(data) {
                // this.setState({
                  
                // })
            }
        })
        .catch((err) => {
            console.log("err", err);
            if(err === 'No current user' || err.status === 400 || err.code === 'NotAuthorizedException') {
                authenticationService.logout();
                }
        })
      }

      render (){
          return (
              <ProfileContext.Provider
                value={{
                    isPageLoaded: this.state.isPageLoaded,
                    setPageLoaded: this.setPageLoaded,
                    profileInfo: this.state.profileInfo,
                    adminInfo: this.state.adminInfo,
                    businessInfo:this.state.businessInfo,
                    languageInfo: this.state.languageInfo,
                    timingsInfo: this.state.openingTimingInfo,
                    paypalInfo: this.state.paypalInfo,
                    cashInfo: this.state.cashInfo,
                    setBusinessInfo: this.setBusinessInfo,
                    getProfileInfo: this.getProfileInfo,
                    updateBusinessInfo: this.updateBusinessInfo,
                    updateAdminInfo: this.updateAdminInfo,
                    updatePaymentInfo: this.updatePaymentInfo,
                    updateTimings: this.updateTimings,
                    sendSupportService: this.sendSupportService,
                    toggleActiveInactivePaypal: this.toggleActiveInactivePaypal,
                    updateLanguegeInfo: this.updateLanguegeInfo,
                    uploadImage: this.uploadImage
                }}>
                    {this.props.children}
                </ProfileContext.Provider>
          )
      }
  } 