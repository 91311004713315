import React, { Component } from "react";
import { bytesToMB, uploadImageToS3, deleteImageS3 } from "../Helpers/Utils";
import DeleteConfirmation from "../pages/Menu/DeleteConfirmation";

const IMAGE_UPLOAD_STATUS = {
    UPLOAD: 'uploading',
    ERROR: 'error'
}

class ImageUpload extends React.Component {
    inputFile = React.createRef();
    maxFileSize = 5 // 5MB
    maxFilesCount = 4;
    supportedFileTypes = /\.(jpg|jpeg|png|gif)$/i;


    state = {
        files: [],
        imagesPreviewUrls: [],
        customErrors: null,
        showDeleteDialog: false,
        selectedIndex: null
    };

    /* constructor(props){
        super(props);
    } */

    /* static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.description !== nextProps.description) {
          return { description: nextProps.description };
        }
    
        return null;
    } */



    validateFileSize = (files) => {
        let fileSizeExceeded = false;
        files.forEach((file, i) => {
            if (bytesToMB(file.size) > this.maxFileSize) {
                fileSizeExceeded = true;
            }
        });
        return fileSizeExceeded;
    }

    validateSupportedFiles = (files) => {
        let invalidFile = false;
        files.forEach((file, i) => {
            if (!file.name.match(this.supportedFileTypes)) {
                invalidFile = true;
            }
        });

        return invalidFile;
    }

    validateMaxSupportedImages = (files) => {
        if (files && files.length + this.getImageCount() > this.maxFilesCount) {
            return false;
        }
        return true;
    }

    _handleImageChange = e => {
        e.preventDefault();

        // FileList to Array
        let files = Array.from(e.target.files);

        if (!this.validateMaxSupportedImages(files)) {
            this.props.onImageValidate(`Maximum ${this.maxFilesCount} files can be uploded`);
            return false;
        }

        if (this.validateSupportedFiles(files)) {
            this.props.onImageValidate("Only jpg, jpeg, png, gif are supported");
            return false;
        }

        if (this.validateFileSize(files)) {
            this.props.onImageValidate(`We only support file size upto ${this.maxFileSize}Mb and one of the file have size more than ${this.maxFileSize}Mb.`);
            return false;
        }

        this.props.onImageValidate(null);




        // File Reader for Each file and and update state arrays
        files.forEach((file, index) => {
            let reader = new FileReader();

            reader.onloadend = () => {
                console.log(file);
                console.log(index);
                file.status = IMAGE_UPLOAD_STATUS.UPLOAD;
                this.setState(prevState => ({
                    files: [...prevState.files, file],
                    imagesPreviewUrls: [...prevState.imagesPreviewUrls, reader.result]
                }));

                if(this.props.disable) {
                    this.props.disable(true)
                }

                 uploadImageToS3(file).then(data => {
                    this.removeLocalReferenceImagePreview(index);
                    this.props.uploadedImage(data.location);
                    if(this.props.disable) {
                        this.props.disable(false)
                    }
                }).catch(error => {
                    console.log(error);
                    file.status = IMAGE_UPLOAD_STATUS.ERROR;
                    let allFiles = [...this.state.files];
                    allFiles[index] = { ...allFiles[index], ...file };
                    this.setState({
                        files: [...allFiles],
                        imagesPreviewUrls: [...this.state.imagesPreviewUrls]
                    });
                    if(this.props.disable) {
                        this.props.disable(false)
                    }
                });

            }

            reader.readAsDataURL(file);

        });
    }

    // Should only be called when file uploaded to server
    removeLocalReferenceImagePreview = (index) => {

        let files = [...this.state.files];
        let imagesPreviewUrls = [...this.state.imagesPreviewUrls];
        // let index = files.findIndex(file => file.name === fileState.name);
        if (index > -1) {
            files.splice(index, 1);
            imagesPreviewUrls.splice(index, 1);
            this.setState({
                files: [...files],
                imagesPreviewUrls: [...imagesPreviewUrls]
            });
        }
    }

    onDeleteConfirmation = (decision) => {
        console.log(decision);
        if (decision === 'yes') {
            const selectedImageIndex = this.state.selectedIndex;
            this.removeSelected(selectedImageIndex);
        }
        this.setState({
            showDeleteDialog: false,
            selectedIndex: null
        });
    }


    removeSelected = (indexOrPath) => {

        if (indexOrPath === null || indexOrPath === undefined) {
            return;
        }

        if (indexOrPath.toString().indexOf('http') > -1) {
            this.removeImageFromRemote(indexOrPath)
        } else {
            // Local Image from state object
            //Delete Image from local state if not uploaded from to server
            this.removeLocalReferenceImagePreview(indexOrPath);
        }

    }

    removeImageFromRemote = (imagePath) => {
        if (imagePath.indexOf('http') > -1) {
            let fileNameUrl = imagePath;
            let fileName = fileNameUrl.split('/').pop();
            // Server Image
            // Delete Image from server if uploaded to server
            if(this.props.disable) {
                this.props.disable(true)
            }
            deleteImageS3(fileName).then(data => {
                console.log(data);
                // this.removeLocalReferenceImagePreview(index);
                this.props.deleteImage(fileName);
                if(this.props.disable) {
                    this.props.disable(false)
                }
            }).catch(error => {
                console.log(error);
                if(this.props.disable) {
                    this.props.disable(false)
                }
            });
        }
    }

    onRemoveImage = (indexOrPath) => {
        this.setState({
            showDeleteDialog: true,
            selectedIndex: indexOrPath
        });
    }

    removeImageFromlocalState = (index) => {
        let newImages = [...this.state.imagesPreviewUrls];
        newImages.splice(index, 1);
        let newFiles = [...this.state.files];
        newFiles.splice(index, 1);

        this.setState({
            files: [...newFiles],
            imagesPreviewUrls: [...newImages]
        });
    }

    getImageCount = () => {
        let allImagesArr = [...this.props.images, ...this.state.imagesPreviewUrls];
        return allImagesArr.length;
    }

    render() {
        let { imagesPreviewUrls, customErrors, showDeleteDialog, files } = this.state;
        let uploadedImages = [...this.props.images];
        let maxFilesCount = this.maxFilesCount;
        let uploadedFileCount = this.getImageCount();
        let showUploadButon = uploadedFileCount < maxFilesCount;

        return (
            <div className="w-layout-grid grid-13">
                {/*  <label className="adpic7 btn btn-default btn-sm z-depth-0 mr-0 pl-2 pr-2 custom-file-upload waves-effect waves-light" htmlFor="file">
                    <i className="fas fa-image fa-fw" aria-hidden="true"></i>
                </label> */}
                {/** Need to Loop on 2 parts */}
                {uploadedImages && uploadedImages.map((imageUrl, i) => {
                    return <div key={i} className={"picad1"} onClick={() => this.onRemoveImage(imageUrl)} style={{ 'backgroundImage': `url(${imageUrl})` }}></div>
                })}
                {imagesPreviewUrls.map((imagePreviewUrl, i) => {
                    let currentFile = files[i];
                    return <div key={i} className={"picad1" + (currentFile && (currentFile.status === IMAGE_UPLOAD_STATUS.UPLOAD ? '-loading' : (currentFile.status === IMAGE_UPLOAD_STATUS.ERROR) ? '-error': ''))} onClick={() => this.onRemoveImage(i)} style={{ 'backgroundImage': `url(${imagePreviewUrl})` }}></div>
                })}
                { showUploadButon && <div className="adpic7" onClick={() => this.inputFile.current.click()} ></div>}
                <input ref={this.inputFile} style={{ display: 'none' }} className="upload" type="file" onChange={this._handleImageChange} multiple />
                {showDeleteDialog && <DeleteConfirmation show={showDeleteDialog} action={(decision) => this.onDeleteConfirmation(decision)} />}
            </div>
        )
    }
}


export default ImageUpload;