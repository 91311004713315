import React from "react";
import { ICONS } from "../../../Helpers/Icon.service";

export const PageFooter = (props) => {
  const featherIcon = ICONS.FEATHER_ICON;
  const phoneIcon = ICONS.HOTLINE_ICON;
  const cashIcon = ICONS.CASH_ICON;
  const gastronIcon2 = ICONS.GASTRON_LOGO2;

  return (
    <React.Fragment>
      <div id="impressum" className="footer" style={{margin: "auto 11px"}}>
        <div className="columns-16 w-row">
          <div className="column-53 w-col w-col-4 w-col-stack">
            <img
              src={featherIcon}
              loading="lazy"
              width="33"
              height="33"
              alt=""
              className="image-197"
            />
            <div className="text-block-488">We Are Here</div>
            <div className="text-block-486">What we are standing for?</div>
            <div className="text-block-487">
              Vestibulum pharetra volutpat bibendum. Vestibulum sed nibh ac ex
              vestibulueugiat risus quis egestas. Quisque vel metus tincidunt,
              convallis ex quis, lacinia purus. Aliquam ut viverra erat. In sit
              amet dolor at urna tempus convallis nec a nisi. Vestibulum
              ultrices iaculis rutrum. Donec pulvinar volutpat tellus. Nam
              eleifend purus vel tempus aliquet. Maecenas pellentesque lacinia
              aliquet.
            </div>
            <div className="text-block-486">What we are standing for?</div>
            <div className="text-block-487">
              Vestibulum pharetra volutpat bibendum. Vestibulum sed nibh ac ex
              vestibulum commodo. Nam lacinia feugiat risus quis egestas.
              Quisque vel metus tincidunt, convallis ex quis, lacinia purus.
              Aliquam ut viverra erat. In sit amet dolor at urna tempus
              convallis nec a nisi. Vestibulum ultrices iaculis rutrum. Donec
              pulvinar volutpainia aliquet.
            </div>
          </div>
          <div className="column-54 w-col w-col-4 w-col-stack">
            <img
              src={phoneIcon}
              loading="lazy"
              width="33"
              height="33"
              alt=""
              className="image-198"
            />
            <div className="text-block-488">Support</div>
            <div className="text-block-486">What we are standing for?</div>
            <div className="text-block-487">
              Vestibulum pharetra volutpat bibendum. Vestibulum sed nibh ac ex
              vestibulum commodo. Nam lacinia feugiat risus quis egestas.
              Quisque vel metus tincidunt, convallis ex quis, lacinia purus.
              Aliquam ulor at urna tempus convallis nec a nisi. Vestibulum
              ultrices iaculis rutrum. Donec pulvinar volutpat tellus. Nam
              eleifend purus vel tempus aliquet. Maecenas pellentesque lacinia
              aliquet.
            </div>
            <div className="text-block-486">What we are standing for?</div>
            <div className="text-block-487">
              Vestibulum pharetra volutpat bibendum. Vestibulum sed nibh ac ex
              vestibulum commodo. Nam lacinia feugiat risus quis egestas.
              Quisque vel metus tincidunt, convallis ex quis, lacinia purus.
              Aliquam uuet.
            </div>
          </div>
          <div className="column-55 w-col w-col-4 w-col-stack">
            <img
              src={cashIcon}
              loading="lazy"
              width="33"
              height="33"
              alt=""
              className="image-199"
            />
            <div className="text-block-488">Best Plan For You</div>
            <div className="text-block-486">Use The Product For Free</div>
            <div className="text-block-487">
              Pharetra volutpat bibendum. Vestibulum sed nibh ac ex vestibulum
              commodo. Nam lacinia feugiat risus quis egestas. Quisqces iaculis
              rutrum. Donec pulvinar volutpat tellus. Nam eleifend purus vel
              tempus aliquet. Maecenas pellentesque lacinia aliquet.
            </div>
            <div className="text-block-486">Plan Billing Options Freedom</div>
            <div className="text-block-487">
              Vestibulum pharetra volutpat bibendum. Vestibulum sed nibh ac ex
              vestibulum commodo. Nam lacinia feugiat risus quis egestas.
              Quisque vel metus tincidunt, convallis ex quis, lacinia purus.
              Aliquam &nbsp;ultrices iaculis rutrum. Donec pulvinar volutpat
              tellus. Nam eleifend purus vel tempus aliquet. Maecenas
              pellentesque lacinia aliquet.
            </div>
          </div>
        </div>
        <img
          src={gastronIcon2}
          loading="lazy"
          height="55"
          alt=""
          className="image-169"
        />
        <div className="text-block-502">your all-in-one</div>
        <div className="text-block-452">
          A rich text element can be used with static or dynamic content. For
          static content, just drop it into any page and begin editing. For
          dynamic content, add a rich text field to any collection and then
          connect a rich text element to that field in the settings panel.
          <br />
          <br />
          22.06.2021
        </div>
        <div className="powered-by">
          <div className="div-block-1034">
            <div className="webflow"></div>
            <div className="text-block-485">
              Webflow
              <br />
              Engine
            </div>
          </div>
          <div className="div-block-1034">
            <div className="aws"></div>
            <div className="text-block-485">
              Amazon Web
              <br />
              Services
            </div>
          </div>
          <div className="div-block-1034">
            <div className="blockchain"></div>
            <div className="text-block-485">
              BlockChain
              <br />
              Secured
            </div>
          </div>
          <div className="div-block-1034">
            <div className="cloud"></div>
            <div className="text-block-485">
              Cloud
              <br />
              Technology
            </div>
          </div>
        </div>
        <div className="div-block-1036">
          <div className="text-block-489">Support Us</div>
          <div className="text-block-489">Affiliate Program</div>
          <div className="text-block-489">Testers Hub</div>
          <div className="text-block-489">Jobs</div>
          <div className="text-block-489">Privacy Policy</div>
          <div className="text-block-489">Impressum</div>
          <div className="text-block-489">Contact Gastron</div>
        </div>
        <div className="text-block-490">
          All Rights reserved | Gastron Services GmbH | German Trade Mark 2019
        </div>
      </div>
      <div className="div-block-1050"></div>
    </React.Fragment>
  );
};
