import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify, { Auth } from "aws-amplify";
import { config } from "./config";
import { API_PATH_NAME } from "./Config/";
import { I18nextProvider } from "react-i18next";
import i18n from './i18n'

console.log(process.env.REACT_APP_ORDER_API_URL)
console.log('environment',process.env.REACT_APP_AWS_ENVIRONMENT)

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.devCognito.REGION,
    userPoolId: config.devCognito.USER_POOL_ID,
    //identityPoolId: config.devCognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.devCognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: API_PATH_NAME.MENU,
        endpoint: config.devApiGateway.MENU.URL,
        region: config.devApiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: API_PATH_NAME.ORDER,
        endpoint: config.devApiGateway.ORDER.URL,
        region: config.devApiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: API_PATH_NAME.PROFILE,
        endpoint: config.devApiGateway.PROFILE.URL,
        region: config.devApiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
    aws_appsync_region: "eu-west-1", // Stack region
    aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APP_SYNC_API_ENDPOINT, // AWS AppSync endpoint
    aws_appsync_authenticationType: "API_KEY", //Primary AWS AppSync authentication type
    aws_appsync_apiKey: process.env.REACT_APP_AWS_APP_SYNC_API_KEY, // AppSync API Key
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
