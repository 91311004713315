import React, { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ResetPassword from "../../Views/Auth/ResetPassword";
import ForgotPassword from "../../Views/Auth/ForgotPassword";
// import LanguageSelector from "../../SharedComponents/LanguageSelector";
import NavigationWidget from "./NavigationWidget";

const PasswordRecoverLayout = () => {
  let { path, url } = useRouteMatch();

    const [showHelp, toggleHelp] = useState(false);

    const showHelpInfo = () => {
        toggleHelp(true);
    }

    const hideHelpinfo = () => {
        console.log('hideHelpinfo')
        toggleHelp(false);
    }

  return (
    <div className="body-9">
      <NavigationWidget showHelpInfo={showHelpInfo}  />
      <Switch>
        <Route path={`${path}/reset`} render={() => <ResetPassword showHelp={showHelp} hideHelpinfo={hideHelpinfo}/>} />
        <Route path={`${path}/recover`} render={() => <ForgotPassword showHelp={showHelp} hideHelpinfo={hideHelpinfo}/>} />
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/recover`}  />
      </Route>  
      </Switch>
      
    </div>
  );
};

export default PasswordRecoverLayout;
