export const data = {
    id: 1,
    name: 'Kiara Spiegel',
    personalAddress: {
        name: 'Kiara Spiegel',
        address: 'Blücherstr. 32',
        zipcode: '75177',
        city: 'Pforzheim',
        phone1: '0176 700 169 99',
        web: 'spiegelkia.de',
        email: 'kiaraspiegel@web.de',
    },
    businessInfo: {
        name: 'Zum Goldenen Ritter - Restaurant',
        address: 'Ferdinandstr. 77',
        zipcode: '75173',
        city: 'Pforzheim',
        phone1: '0176 700 169 99',
        taxInfo: 'DE/1124588996',
        web: 'www.zumritter.de',
        email: 'zumritter@web.de'
    },
    languageInfo: {
        language: 'English UK',
        guests: 'German DE'
    },
    translatingInfo: {
        language: 'Native',
        guests: 'Google DE'
    },
    stylingInfo: {
        currentStyle: 'Gastron Breeze',
        activeTheme: 'Gastron Breeze'
    },
    contactUs: {
        address1: 'Gastron GmbH & Co. Kg',
        address2: 'Blücherstr. 99',
        zipcode: '75177',
        city: 'Pforzheim',
        phone1: '0169 555 666 999',
        web: 'www.gastron.io',
        umsTs: '999 666 888 DE 88'
    },
    hotLineInfo: {
        fastSupport: '0176 666 999 333',
        technicalSupport: '0176 666 999 332',
        sellings: '0176 888 666 999',
        jobs: '0176 888 666 999'
    },
    cashInfo: 'pages.profile.payment.cashPayInfo',
    // paypalAccount: 'kiaraspiegel@web.de',
    paypalAccount: {
        email: 'kiaraspiegel@web.de',
        isActive: true
    },
    restaurantName: 'COFFEE-HAUS EXCELSIOR',

}

export const regexForEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;