import React from 'react';

const ErrorMessage = (props) => {

    const message = props.text;

    const getStyle = () => {
        let styles = {};
        if(props.show) {
            styles = { display: 'flex' }
        } else {
            styles = { display: 'none' }
        }
        return styles;
    }

    return (
        <React.Fragment>
            <div className="errorMessage">{message}</div>
        </React.Fragment>
    )
}

export default ErrorMessage;