export const config = {
    MAX_ATTACHMENT_SIZE: 5000000,
    cognito: {
        USER_POOL_ID: "eu-west-1_78GaH5hvi",
        REGION: "eu-west-1",
        APP_CLIENT_ID: "50oeegb54t3p5hvlrhn3a8epk3",
        IDENTITY_POOL_ID: "eu-west-1:68d0b645-9e5c-46c1-b7f1-e906b596eb20"
    },
    s3: {
        REGION: "eu-west-1",
        BUCKET: "gastron-imagebucket"
      },
    devApiGateway:{
        REGION: "eu-west-1",
        ORDER: {
            URL: process.env.REACT_APP_ORDER_API_URL
        },
        MENU: {
            URL: process.env.REACT_APP_MENU_API_URL
        },
        PROFILE: {
            URL: process.env.REACT_APP_PROFILE_API_URL
        }
    },
    devCognito: {
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        REGION: process.env.REACT_APP_REGION,
        APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID
    }
}
