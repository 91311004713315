import React from "react";
import { ICONS } from "../Helpers/Icon.service";

export const Overlayinfo = (props) => {
    const closeIcon = ICONS.CLOSE_RED_ICON;
    const {overwriteDefaultClass = false} = props;
    const className = (!overwriteDefaultClass ? 'overlay-info ' :  '' ) +  props.className;

    const title = props.title;
    const info = props.info;
    // const isOpen = props.show;


    const getStyle = () => {
        let styles = {};
        if(props.show) {
            styles = { display: 'flex' }
        } else {
            styles = { display: 'none' }
        }
        return styles;
    }

    const hideOverlay = (e) => {
        props.onHide();
    }


    return (
        <React.Fragment>
            <div className={className} style={getStyle()}>
                <img src={closeIcon} onClick={(e) => hideOverlay(e)} loading="lazy" width="22" height="22" data-w-id="134b48f1-f23d-2757-f2ff-eaf0b7791549" alt="close" className="image-136 cursor-pointer" />
                {title && <div className="text-block-409-copy">{title}</div>}
                {info && <div className="text-block-409">
                    {info}
                </div>}
                {props.children}
            </div>
        </React.Fragment>
    )
}

export default Overlayinfo