
import Moment from 'moment';
import { APP_CONFIG } from '../constants/Config';
import S3 from 'react-aws-s3';
import { authenticationService } from '../Services/auth.service';
import { v4 as uuidv4 } from 'uuid';


export const isEmpty = (obj) => {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export function computeDollars(btc, btcRate) {
    return btc * btcRate;
}

export function formatDollars(dollars) {
    dollars = (Math.round(dollars * 100) / 100).toFixed(2);
    dollars = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return dollars;
}


export const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
}

export const transformDate = (date, format = APP_CONFIG.DATE_FORMAT) => {
    if (new Date(date).toLocaleString() === 'Invalid Date') { return 'Invalid Date'; }
    return Moment(date).format(format);

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
    // Other meothod if moment Js is not required
    /* {new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(customer.firstSale)} */
}

export const transformTime = () => {

}

export const toCurrency = (number, options) => {
    const defaults = { locale: APP_CONFIG.LOCALE, style: 'currency', currency: APP_CONFIG.CURRENCY , minFraction: 0, maxFraction: 0};
    const _options = Object.assign({}, defaults, options)
    return new Intl.NumberFormat(_options.locale, _options).format(number)

    // To avoid using libraries, you can use the following code

/*     const defaultOptions = {
        significantDigits: 2,
        thousandsSeparator: ',',
        decimalSeparator: '.',
        symbol: '$'
    }

    const currencyFormatter = (value, options) => {
        if (typeof value !== 'number') value = 0.0
        options = { ...defaultOptions, ...options }
        value = value.toFixed(options.significantDigits)

        const [currency, decimal] = value.split('.')
        return `${options.symbol} ${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}${options.decimalSeparator}${decimal}`
    }
} */

} 

export const bytesToMB = (sizeInBytes ) => {
    return (sizeInBytes / (1024*1024)).toFixed(2);
} 


const getS3Config = () => {
    let config = {
        ...APP_CONFIG.S3BUCKET,
        dirName: authenticationService.userID
    };
    const ReactS3Client = new S3(config);
    return ReactS3Client;
}

export const uploadImageToS3 = async (file) => {
    const fileName = uuidv4() + '-' + new Date().getTime();
    /* return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({fileName});
        }, 2000);
      }); */
    return getS3Config().uploadFile(file, fileName);
}

export const deleteImageS3 = async (filename) => {
    console.log(filename); 
    return getS3Config().deleteFile(filename);
}


export const validateActiveDay = (dayObject) => {
    let presentDay = Moment().format('dd').toLowerCase();
    return dayObject[presentDay] === true ? true : false;

} 

export const validateActiveTime = (startTime, endTime) => {   
    startTime = Moment(startTime, 'hh:mm');
    endTime = Moment(endTime, 'hh:mm');   
   
    /* console.log('startTime',startTime);
    console.log('endTime',endTime);
    console.log(Moment(currentTime).isBetween(startTime, endTime));
    console.log(Moment().isBefore(endTime));
    console.log(Moment().isAfter(startTime)); */

    return (Moment().isAfter(startTime) && Moment().isBefore(endTime)) ? true : false;
} 

export const convertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

export const exportCSVFile = (items, fileTitle, headers ) => {
    if (headers) {
        items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
