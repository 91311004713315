import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES_PATH } from "../../constants/Routes";
import { ICONS } from "../../Helpers/Icon.service";

const NavigationWidget = (props) => {
  const { showHelpInfo } = props;
  const backIcon = ICONS.BACK_ICON;
  const homeIcon = ICONS.HOME_ICON_LANDING;
  const bellIcon = ICONS.BELL_ICON_WHITE;

  return (
    <div className="div-block-1054">
      <NavLink className="div-block-1055 w-inline-block" to={ROUTES_PATH.ROOT}>
        <img src={backIcon} loading="lazy" width="22" height="22" alt="" />
        <div className="text-block-509">Back</div>
      </NavLink>

      <NavLink className="div-block-1055 w-inline-block" to={ROUTES_PATH.ROOT}>
        <img src={homeIcon} loading="lazy" width="22" height="22" alt="" />
        <div className="text-block-509">Home</div>
      </NavLink>

      <div
        data-w-id="be3edbfb-602c-b4c3-bcbe-83b3bdac6204"
        className="div-block-1055"
        onClick={showHelpInfo}
      >
        <img src={bellIcon} loading="lazy" width="22" height="22" alt="" />
        <div className="text-block-509">Help</div>
      </div>
    </div>
  );
};

export default NavigationWidget;
