import React, { Component } from "react";
import FormErrors from "../FormErrors";
import Validate from "../FormValidation";
import { ROUTES } from "../../constants/Routes";
import { authenticationService } from "../../Services/auth.service";
import { NavLink, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import {ROUTES_PATH} from '../../constants/Routes'

class LogIn extends Component {
  PATH_ROUTES = ROUTES_PATH;
  logged_in_success = ROUTES.SUCCESS_LOGGED_IN;
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {
        cognito: null,
        blankfield: false,
      },
    };

    console.warn({ ...props });
  }

  async componentDidMount() {
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this.props.history.push(this.logged_in_success);
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  redirectToHome = () => {
    console.trace(this.props);
    const { history } = this.props;
    if (history) {
      this.props.history.push(this.logged_in_success);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    }

    authenticationService.login(this.state.username, this.state.password).then(
      (user) => {
        this.redirectToHome();
      },
      (error) => {
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
      }
    );
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  getLoginErrorStyle = () => {
    let style = {};
    if(this.state.errors.cognito !== null){
      style.display = 'block';
    }

    return style;
  }

  render() {
    return (
      <React.Fragment>
        <div id="signin" className="signinblock w-form" style={{margin: 0, padding: '22px 22px 10px 22px'}}>
            <FormErrors formerrors={this.state.errors} />
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            method="get"
            className="form"
            onSubmit={this.handleSubmit}
          >
            <input
              type="text"
              className="text-field-3 w-input"
              maxLength="256"
              name="eMail"
              data-name="E Mail"
              placeholder="eMail"
              id="username"
              required
              value={this.state.username}
              onChange={this.onInputChange}
            />

            <input
              type="password"
              className="text-field-2 w-input"
              maxLength="256"
              name="Password"
              data-name="Password"
              placeholder="Password"
              id="password"
              required
              value={this.state.password}
              onChange={this.onInputChange}
            />

            <input
              type="submit"
              value="login"
              data-wait="Please wait..."
              className="submit-button w-button"
            />
            <div className="text-block-461-copy">
            <NavLink
                className="link-11"
                tabIndex="0"
                to={this.PATH_ROUTES.FORGOT_PASSWORD}
              >
                Forgot Password?
              </NavLink>
            </div>
          </form>
          <div className="success-message w-form-done">
            <div className="text-block-56">Thanks!</div>
          </div>
        </div>
        {/* <div className="div-block-2"></div>
            <div className="form-block w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form" onSubmit={this.handleSubmit}>
                    <input type="text" className="inputField w-input" maxLength="256" name="text" data-name="text" placeholder="user name" id="username" required="" value={this.state.username} onChange={this.onInputChange} />
                    <input type="password" className="inputField w-input" maxLength="256" name="Password" data-name="Password" placeholder="Password" id="password" required="" value={this.state.password} onChange={this.onInputChange} />
                    <input type="submit" value="Einloggen" data-wait="Bitte warten..." className="submit-button w-button" />
                    <a href="registrierung.html" className="w-button registerBtn">Registrieren</a>
                </form>
                <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
            <div id="Video" className="video w-video w-embed"></div>
            <div className="section-2 section-3">
                <div className="text-block-2">All Rights Reserved - Gastron Web Service 2020 - Fries, Tesla GbR</div>
                <div className="text-block-2">
                    <a href="impressum" className="link-2">Impressum</a>
                </div>
            </div> */}
      </React.Fragment>
    );
  }
}

export const withUseFormHook = (Component) => {
  return (props) => {
    const form = useForm();
    return <Component {...props} {...form} />;
  };
};

export default withUseFormHook(LogIn);

// export default withRouter(LogIn);
