import React from 'react';
import LogIn from '../../../Views/Auth/LogIn';
import { Link, NavLink } from "react-router-dom";
import { PageFooter } from './pageFooter';
import { ICONS } from '../../../Helpers/Icon.service';
import { PageHeader } from './pageHeader';
import Content from './content';

const Landing = (props) => {
    console.log("------------Landing-------------")
    console.log(props);

    const homeIcon = ICONS.HOME_ICON_LANDING;

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          }); 
    }

    return (
        <React.Fragment>
            <PageHeader /> 
            <Content />
            <span onClick={scrollToTop} className="div-block-987 w-inline-block cursor-pointer">
                <img src={homeIcon} loading="lazy" width="22" height="22" alt="" className="image-182" />
            </span>
           <PageFooter />
        </React.Fragment>
    )
}

export default Landing;