import React, { useContext, useEffect, useState } from 'react';
import { regexForEmail } from '../constant';
import { APP_CONFIG } from '../../../constants/Config';
import { ICONS } from '../../../Helpers/Icon.service';
import ProfileContext from '../../../Providers/Profile/ProfileContext';
import LoadingIndicator from '../../../SharedComponents/Loading';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const AdminInfo = ({ ...rest }) => {
    const { t, i18n } = useTranslation();
    const profileService = useContext(ProfileContext);
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const { adminInfo } = profileService;
    const settingsIcon = ICONS.SETTINGS_DARK;
    const fieldsIgnoreValidation = ['phone2', 'adminWebpage'];
    const [editForm, setEditform] = useState(false);
    const [adminForm, setAdminForm] = useState({
        "adminName": "",
        "adminWebpage": "",
        "adminTownZip": "",
        "adminStreetNr": "",
        "adminPhone": "",
        "adminEmail": "",
    })

    const handleInputChange = (e, type) => {
        let tempForm = { ...adminForm };
        let value = e.target.value;
        tempForm[type] = value;
        tempForm[`${type}Error`] = null;
        let result = handleValidation(tempForm, type, value);
        setAdminForm(result[1]);
    }

    const handleValidation = (adminForm, type, value) => {
        console.log("validation", adminForm, type, value)
        let valid = true;
        
        if (!value && fieldsIgnoreValidation.indexOf(type)  === -1 ) {
            valid = false;
            adminForm = {
                ...adminForm,
                [`${type}Error`]: `${(type.charAt(0).toUpperCase() + type.slice(1)).split(/(?=[A-Z])/).join(" ")} Required`
            }
        } else {
            valid = true;
            adminForm = {
                ...adminForm,
                [`${type}Error`]: null
            }
        }
        if ((type === 'adminPhone') && value.toString().length < 9 && valid) {
            valid = false;
            adminForm[`${type}Error`] = 'Phone must be 9 characters';
        }
        if ((type === 'adminName' || type === 'adminTownZip' ) && valid) {
            if (!value || value.toString().length < 3) {
                valid = false;
                adminForm[`${type}Error`] = `${(type.charAt(0).toUpperCase() + type.slice(1))} must be 3 characters`
            }
        }
        if (type === 'adminEmail' && valid) {
            let emailRegex = regexForEmail;
            if (!emailRegex.test(value)) {
                valid = false;
                adminForm.adminEmailError = 'Proper Email Required'
            }
        }

        return [valid, adminForm]
    }

    const formValidation = (adminForm) => {
        let tempAdminForm = { ...adminForm };
        let valid = true;
        let tempValid = false;
        let adminProps = Object.entries(adminForm);
        adminProps.map(([key, value]) => {
            let result = [false, tempAdminForm];
            if (key.includes('Error') || fieldsIgnoreValidation.indexOf(key) > -1) {
                return [key, value];
            }
            else {
                result = handleValidation(tempAdminForm, key, value);
            }
            tempValid = result[0];
            tempAdminForm = result[1];
            if (valid && !tempValid) {
                valid = tempValid;
            }
            return [key, value];
        });
        setAdminForm({ ...tempAdminForm });
        return valid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let result = formValidation(adminForm);
        console.log("result", result)
        if (result) {
            profileService.updateAdminInfo({
                adminName: adminForm.adminName,
                adminStreetNr: adminForm.adminStreetNr,
                adminTownZip: adminForm.adminTownZip,
                adminPhone: adminForm.adminPhone,
                adminEmail: adminForm.adminEmail,
                adminWebpage: adminForm.adminWebpage,
                informationType: 'ADMININFO'
            })
            setEditform(false);
        }
    }

    const handleFormDisplay = () => {
        setAdminForm({
            adminName: "",
            adminWebpage: "",
            adminTownZip: "",
            adminStreetNr: "",
            adminPhone: "",
            adminEmail: "",
            ...adminInfo
        });
        setEditform(!editForm);
    }

    useEffect(() => {
        setAdminForm({
            adminName: "",
            adminWebpage: "",
            adminTownZip: "",
            adminStreetNr: "",
            adminPhone: "",
            adminEmail: "",
            ...adminInfo
        })
    }, [])
    if(!profileService.isPageLoaded ){
        return <div className="data-content-section"><LoadingIndicator /></div>;
    }

    return (
        <div id="w-node-_2606e602-1e0c-3e5c-64af-64dd3d4fdbfc-fe54d577" className="div-block-667 data-content-section">
            <img src={`${imgPath}/user_fullAsset-2.svg`} loading="lazy" width="55" height="55" alt="" className="image-194"/>
            {!editForm ? <img src={settingsIcon} loading="lazy" width="17" height="17" onClick={() => handleFormDisplay()}
                data-w-id="2606e602-1e0c-3e5c-64af-64dd3d4fdc0f" alt="" className="image-128" /> : ''}
            {!editForm ? <div className="div-block-951">
                <div className="text-block-390"><Trans>pages.profile.adminInfo.title</Trans></div>
                <div className="text-block-393"><Trans>pages.profile.adminInfo.personal</Trans></div>
                <div className="text-block-391">{adminInfo.adminName}<br />{adminInfo.adminStreetNr}<br />
                   {adminInfo.adminTownZip} {adminInfo.adminCity ? adminInfo.adminCity : ''} <br /><Trans>pages.profile.shared.tel</Trans>{adminInfo.adminPhone ? `${adminInfo.adminPhone} ` : ''}
                </div>
                <div className="text-block-393"><Trans>pages.profile.shared.webEmail</Trans></div>
                <div className="text-block-391">{adminInfo.adminWebpage}<br />{adminInfo.adminEmail}</div>
            </div> :
                <div >
                    <form name="email-form-2" className="form-138">
                        <div className="text-block-391-copy"><Trans>pages.profile.adminInfo.title</Trans></div>
                        <input type="text" value={adminForm.adminName} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.adminInfo.name')} id="name-2" onChange={e => handleInputChange(e, 'adminName')} />
                        {adminForm.adminNameError ? <p className='validationsError'>*{adminForm.adminNameError}</p> : null}
                        <input type="text" value={adminForm.adminStreetNr} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.adminInfo.address')} id="name-2" onChange={e => handleInputChange(e, 'adminStreetNr')} />
                        {adminForm.adminStreetNrError ? <p className='validationsError'>*{adminForm.adminStreetNrError}</p> : null}
                        <input type="text" value={adminForm.adminTownZip} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.adminInfo.zipcode')} id="name-2" onChange={e => handleInputChange(e, 'adminTownZip')} />
                        {adminForm.adminTownZipError ? <p className='validationsError'>*{adminForm.adminTownZipError}</p> : null}
                        <input type="text" value={adminForm.adminPhone} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.adminInfo.phone1')} id="name-2" onChange={e => handleInputChange(e, 'adminPhone')} />
                        {adminForm.adminPhoneError ? <p className='validationsError'>*{adminForm.adminPhoneError}</p> : null}
                        <input type="text" value={adminForm.phone2} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.adminInfo.phone2')} id="name-2" onChange={e => handleInputChange(e, 'phone2')} />
                        {adminForm.phone2Error ? <p className='validationsError'>*{adminForm.phone2Error}</p> : null}
                        <div className="text-block-391-copy"><Trans>pages.profile.shared.emailWeb</Trans></div>
                        <input type="text" value={adminForm.adminEmail} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.adminInfo.email')} id="name-2" onChange={e => handleInputChange(e, 'adminEmail')} />
                        {adminForm.adminEmailError ? <p className='validationsError'>*{adminForm.adminEmailError}</p> : null}
                        <input type="text" value={adminForm.adminWebpage} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.shared.webpage')} id="name-2" onChange={e => handleInputChange(e, 'adminWebpage')} />
                        {adminForm.adminWebpageError ? <p className='validationsError'>*{adminForm.adminWebpageError}</p> : null}
                        <div className="div-block-952">
                            <div className="closepersonal" onClick={() => handleFormDisplay()}><Trans>shared.actions.cancel</Trans></div>
                            <input type="submit" value={t('shared.actions.save')} className="submit-button-25 " onClick={e => handleSubmit(e)} />
                        </div>
                    </form>
                </div>}
        </div>
    ) 
}

export default AdminInfo;