import React from 'react';

const Footer = () => {

    return (
        <div className="div-block-926">
            <div className="div-block-927">
                <div className="text-block-379">Gastron</div>
                <div className="text-block-379-copy">your all in one</div>
                <div className="div-block-1006"></div>
            </div>
            <div className="div-block-927">
                <div className="text-block-379">Test Version</div>
                <div className="text-block-379-copy">under development</div>
                <div className="div-block-1006"></div>
            </div>
            <div className="div-block-927">
                <div className="text-block-379">gastron.io</div>
                <div className="text-block-379-copy">info@gastron.io</div>
            </div>
            <div className="div-block-927">
                <div className="text-block-379">Pre Launch Alpha</div>
                <div className="text-block-379-copy">11.09.2021</div>
            </div>
            <div className="div-block-928">
                <div className="text-block-380">Status</div>
            </div>
        </div>
    )
}

export default Footer;