import React, { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { APP_CONFIG } from "../../src/constants/Config";
import { ROUTES_PATH } from "../constants/Routes";
import { LanguageSelector } from "../SharedComponents/LanguageSelector";
import { DateTimeClock } from "../SharedComponents/DateTimeClock";
import { ICONS } from "../Helpers/Icon.service";
import OutSideCloseHelper from "../SharedComponents/OutSideCloseHelper";
import { authenticationService } from "../Services/auth.service";
import { ROUTES } from "../constants/Routes";
import { useHistory } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

export const NavbarDesktop = (props) => {
  const modalRef = useRef(null);
  const PATH_ROUTES = ROUTES_PATH;
  const homeIcon = ICONS.HOME_ICON;
  const userIcon = ICONS.USER_ICON;
  const userHoverIcon = ICONS.USER_HOVER_ICON;
  const default_route_if_not_logged_in = ROUTES.DEFAULT_IF_NOT_LOGGED_IN;
  const activeLinkCSSClass = "w--current";
  const [displayDropdown, setDisplayDropdown] = useState(false);

  let history = useHistory();


  OutSideCloseHelper(modalRef, () => setDisplayDropdown(false));

  const onLogout = async (event) => {
    
      event.preventDefault();
      hideDropDown();
    await authenticationService.logout();
    // props.history.push(default_route_if_not_logged_in);
    
    // setLoggedOut(true);
    history.push(default_route_if_not_logged_in);
    // userHasAuthenticated(false);
  }

  const hideDropDown = () => {
    setDisplayDropdown(false)
  }

  return (
    <React.Fragment>
      {
        <div id="Navbar" af-el="navbar" className="navbar">
          <NavLink
            activeClassName={activeLinkCSSClass}
            className="div-block-643 w-inline-block"
            to={PATH_ROUTES.HOME}
          >
            <img src={homeIcon} loading="lazy" width="33" height="33" alt="" />
          </NavLink>
          <NavLink
            activeClassName={activeLinkCSSClass}
            className="button-8 w-button"
            to={PATH_ROUTES.HOME}
          >
            <Trans>topMenu.home</Trans>
          </NavLink>
          <NavLink
            activeClassName={activeLinkCSSClass}
            className="buco-copy w-button"
            to={PATH_ROUTES.MENU}
          >
            
            <Trans>topMenu.menu</Trans>
          </NavLink>
          <NavLink
            activeClassName={activeLinkCSSClass}
            className="orderbutton w-button"
            to={PATH_ROUTES.ORDER}
          >
            <Trans>topMenu.order</Trans>
          </NavLink>
          <NavLink
            activeClassName={activeLinkCSSClass}
            className="managebuttom w-button"
            to={PATH_ROUTES.MANAGE}
          >
            <Trans>topMenu.manage</Trans>
          </NavLink>
          <div className="nav-menu-right">
          <LanguageSelector style={{'marginRight': '20px'}}/>
          <div
            data-hover=""
            data-delay="0"
            className="dropdown-27 w-dropdown"
            ref={modalRef}
          >
            <div
              className={
                "dropdown-toggle-24 w-dropdown-toggle " +
                (displayDropdown ? "w--open" : "")
              }
              id="w-dropdown-toggle-1"
              aria-controls="w-dropdown-list-1"
              aria-haspopup="menu"
              aria-expanded="true"
              role="button"
              tabIndex="0"
              onClick={() => setDisplayDropdown(!displayDropdown)}
            ></div>
            <nav
              className={
                "dropdown-list-25 w-dropdown-list " +
                (displayDropdown ? "w--open" : "")
              }
              id="w-dropdown-list-1"
              aria-labelledby="w-dropdown-toggle-1"
            >
              <NavLink
                aria-current="page"
                className="link-block-6 w-inline-block w--current"
                tabIndex="0"
                to={PATH_ROUTES.SETTINGS}
                onClick={hideDropDown}
              >
                <div className="settingslink"><Trans>topMenu.settings</Trans></div>
              </NavLink>

              <NavLink
                aria-current="page"
                className="link-block-5 w-inline-block w--current"
                tabIndex="0"
                to={PATH_ROUTES.PAYMENT}
                onClick={hideDropDown}
              >
                <div className="paymentslink"><Trans>topMenu.payments</Trans></div>
              </NavLink>

              <NavLink
                aria-current="page"
                className="link-block-4 w-inline-block w--current"
                tabIndex="0"
                to={PATH_ROUTES.SUPPORT}
                onClick={hideDropDown}
              >
                <div className="supportlink"><Trans>topMenu.support</Trans></div>
              </NavLink>
              <NavLink
                aria-current="page"
                className="link-block-3 w-inline-block w--current"
                tabIndex="0"
                to={PATH_ROUTES.LOGOUT}
                onClick={(e) => onLogout(e)}
              >
                <div className="logoutlink"><Trans>topMenu.logout</Trans></div>
              </NavLink>
            </nav>
          </div>
          </div>

          {/* {<NavLink className="div-block-383 w-inline-block" to={PATH_ROUTES.PROFILE}>
                    <img src={userIcon} loading="lazy" width="42" height="42" className="image-66" alt="" />
                    <img src={userHoverIcon} loading="lazy" width="33" height="33" alt="" />
                </NavLink> */}
          <DateTimeClock />
        </div>
      }
    </React.Fragment>
  );
};

export default withTranslation()(NavbarDesktop);

