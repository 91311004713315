import React, { useEffect, useState } from "react";
import MenuContext from "../../Providers/Menu/MenuContext";

import Product from "./Product";
import AddEditProduct from "./AddEditProduct";
import { Logger } from "../../Helpers/Logger.service";
import { USER_ACTIONS } from "../../constants/app.constant";
import DeleteConfirmation from "./DeleteConfirmation";
import ConfirmHideDialog from "./ConfirmHideDialog";
import Overlayinfo from "../../SharedComponents/OverlayInfo";
import BoxLayout from "../../SharedComponents/BoxLayout";
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


export const ProductsBox = (props) => {
  const { t, i18n } = useTranslation();
  // const menuService = useContext(MenuContext);

  const ACTIONS = USER_ACTIONS;
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    // manually deep compare here before updating state
    if (!props.context.selectedCategory) return;
    setProductsList(props.context.selectedCategory.products || []);
  }, [props.context.selectedCategory]);

  const [displayAddEdit, setDisplayAddEdit] = useState(false);
  const [displayHide, setDisplayHide] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const [showInfoOverlay, setShowInfoOverlay] = useState(false);

  const onAddNewProduct = () => {
    setDisplayAddEdit(true);
    setSelectedProduct({});
  };

  const onCloseAddEditDialog = () => {
    setDisplayAddEdit(false);
  };

  const onSelectProduct = (product, action) => {
    Logger.log("action::", action, product);
    setSelectedProduct(product);
    props.context.setSelectedProduct(product);
    takeAction(action, product);
  };

  const takeAction = (action, selectedProduct) => {
    switch (action) {
      case ACTIONS.EDIT:
        setDisplayAddEdit(true);
        break;
      case ACTIONS.DELETE:
        setDisplayDelete(true);
        break;
      case ACTIONS.HIDE:
        setDisplayHide(true);
        break;
      case ACTIONS.SYNC:
        onSync(selectedProduct);
        break;
      default:
        break;
    }
  };

  const getSelectedCategoryForProduct = () => {
    if (props.context.selectedCategory) {
      return props.context.selectedCategory.id;
    }
    return false;
  };

  const onDeleteConfirmation = (decision) => {
    if (decision === "yes") {
      let productId = selectedProduct.id;
      // props.context.removeProductApi(getSelectedCategoryForProduct(), productId);
      props.context.persistProductAction(
        ACTIONS.DELETE,
        getSelectedCategoryForProduct(),
        selectedProduct
      );
      console.log(props.context.selectedCategory.products);
      // setProductsList(props.context.selectedCategory.products || []);
      /* props.context.removeProductApi(getSelectedCategoryForProduct(), productId).then(res => {
                onRefreshList(res);
            }); */
    }
    setDisplayDelete(false);
  };

  const onHideConfirmation = (decision) => {
    let x = decision === "yes" ? "" : "";
    if (decision === "yes") {
      selectedProduct.hide = selectedProduct.hide == false ? true : false;
      props.context.persistProductAction(
        ACTIONS.EDIT,
        getSelectedCategoryForProduct(),
        selectedProduct
      );
      // props.context.addUpdatePersistProduct(props.context.selectedCategory.id, selectedProduct);
      /* props.context.updateProductApi(props.context.selectedCategory.id, selectedProduct).then(res => {
                onRefreshList(res);
            }); */
    }
    setDisplayHide(false);

    // updateProductApi
  };

  const onRefreshList = (list) => {
    // console.log(props.context.selectedCategory.products);
    // setProductsList(props.context.selectedCategory.products);
    props.context.setCategoryList(list);
    props.context.selectCategoryById(props.context.selectedCategory.id);
    // Update the list again as
    // setMenuList(menuService.getCategoryList());
  };

  const onSync = (selectedProduct) => {
    props.context.persistProductAction(
      ACTIONS.SYNC,
      selectedProduct.categoryId,
      selectedProduct
    );
    // Delete, hide, Add, update
  };

  const tempRefresh = (product) => {
    console.log(product);
    setSelectedProduct({ ...product });
    // props.context.setSelectedProduct(product);
  };

  const helpInfo = () => {
    return (
      <React.Fragment>
        {
          <Overlayinfo
            show={showInfoOverlay}
            onHide={() => setShowInfoOverlay(!showInfoOverlay)}
          >
            <div className="rich-text-block-3 w-richtext">
              <h4>What’s a Rich Text element?</h4>
              <p>
                The rich text element allows you to create and format headings,
                paragraphs, blockquotes, images, and video all in one place
                instead of having to add and format them individually. Just
                double-click and easily create content.
              </p>
              <h6>Static and dynamic content editing</h6>
              <p>
                A rich text element can be used with static or dynamic content.
                For static content, just drop it into any page and begin
                editing. For dynamic content, add a rich text field to any
                collection and then connect a rich text element to that field in
                the settings panel. Voila!
              </p>
              <h6>How to customize formatting for each rich text</h6>
              <p>
                Headings, paragraphs, blockquotes, figures, images, and figure
                captions can all be styled after a class is added to the rich
                text element using the "When inside of" nested selector system.
              </p>
            </div>
          </Overlayinfo>
        }
      </React.Fragment>
    );
  };

  const getRenderedContent = () => {
    if (props.context && !props.context.isPageLoaded) {
      return (
        <div
          data-w-id="e38f68a5-356d-8ef2-1eab-91d771125f5a"
          className="pageloading"
        ></div>
      );
    } else {
      if (getSelectedCategoryForProduct()) {
        return (
          <React.Fragment>
            <div className="pos-rel">
              {getSelectedCategoryForProduct() && (
                <div className="w-layout-grid griditems">
                  {productsList &&
                    productsList.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Product
                            item={item}
                            isSelected={selectedProduct?.id === item.id}
                            selectProduct={onSelectProduct}
                          />
                        </React.Fragment>
                      );
                    })}
                  <div
                    af-el="formitem"
                    className="_402-22"
                    onClick={() => onAddNewProduct()}
                  ></div>
                </div>
              )}
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className="text-block-455"><Trans>pages.menu.selectCategoryText</Trans></div>
          </React.Fragment>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <BoxLayout
        className="items"
        title={t('pages.menu.products')}
        helpInfo={helpInfo}
        toggleHelpInfo={setShowInfoOverlay}
      >
        {getRenderedContent()}
      </BoxLayout>

      {displayAddEdit && (
        <AddEditProduct
          localRefresh={tempRefresh}
          categoryList={props.context.allCategories}
          refresh={onRefreshList}
          show={displayAddEdit}
          close={onCloseAddEditDialog}
          categoryId={getSelectedCategoryForProduct()}
          product={selectedProduct}
        />
      )}
      {displayDelete && (
        <DeleteConfirmation
          show={displayDelete}
          action={(decision) => onDeleteConfirmation(decision)}
        />
      )}
      {displayHide && (
        <ConfirmHideDialog
          status={selectedProduct.hide}
          name={selectedProduct.name}
          show={displayHide}
          action={(decision) => onHideConfirmation(decision)}
        />
      )}
    </React.Fragment>
  );
};

const withContext = (Component) => {
  return (props) => {
    return (
      <MenuContext.Consumer>
        {(context) => {
          return <Component {...props} context={context} />;
        }}
      </MenuContext.Consumer>
    );
  };
};

export default withContext(ProductsBox);

// export default ItemsBox
