import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ICONS } from '../../Helpers/Icon.service';
import { APP_CONFIG } from '../../constants/Config';
import Logout from './Logout';
import OutSideCloseHelper from '../../SharedComponents/OutSideCloseHelper';
import { Trans } from 'react-i18next';


const SideNavBar = ({ profileInfo, viewLogout, ...rest }) => {
    const logoutDropdownRef = useRef(null);
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const gastronIcon = ICONS.GASTRON_ICON_BLACK;
    const location = useLocation();
    const [navSelected, setNavSelected] = useState(0);
    const handleNavigation = (e) => {
        e.stopPropagation();
    }
    const activeLinkCSSClass = 'w--current';

    OutSideCloseHelper(logoutDropdownRef, () => rest.setViewLogout(false));

    /*  useEffect(() => {
        switch (true) {
            case rest.viewLogout: setNavSelected(3);
                break;
            case location.pathname.includes('payments'): setNavSelected(1);
                break;
            case location.pathname.includes('support'): setNavSelected(2);
                break;
            case location.pathname.includes('profile'): setNavSelected(0);
                break;
            
            default: setNavSelected(3);;
        }
    }, [location]);  */

    return (
        <div className="tabs-menu w-tab-menu">
            <NavLink to={'/profile'} onClick={e => rest.setViewLogout(false)} exact={true} className={`mybusiness w-inline-block w-tab-link`} activeClassName={activeLinkCSSClass} >
                <img src={`${imgPath}/settings_baldAsset-4.svg`} loading="lazy" width="22" height="22" alt=""/>
                <div className="text-block-184" ><Trans>topMenu.settings</Trans></div>
            </NavLink>
            <NavLink to={'/profile/payments'} onClick={e => rest.setViewLogout(false)} className={`mybusiness w-inline-block w-tab-link`} activeClassName={activeLinkCSSClass} >
                <img src={`${imgPath}/moneyAsset-2.svg`} loading="lazy" width="22" height="22" alt=""/>
                <div className="text-block-184"><Trans>topMenu.payments</Trans></div>
            </NavLink>
            <NavLink to={'/profile/support'} onClick={e => rest.setViewLogout(false)} className={`mybusiness w-inline-block w-tab-link`} activeClassName={activeLinkCSSClass} >
                <img src={`${imgPath}/ask_dAsset-2.svg`} loading="lazy" width="22" height="22" alt=""/>
                <div className="text-block-184"><Trans>topMenu.support</Trans></div>
            </NavLink>
            <div ref={logoutDropdownRef} onClick={e => rest.setViewLogout(!rest.viewLogout)} className={`mybusiness w-inline-block w-tab-link ${navSelected == 3 ? activeLinkCSSClass : null}`}>
                <img src={`${imgPath}/lock_dAsset-1.svg`} loading="lazy" width="22" height="22" alt=""/>
                <div className="text-block-184" ><Trans>topMenu.logout</Trans></div>
                {viewLogout && <Logout  /> }
            </div>
            <NavLink to={'/profile/gastron'} onClick={e => rest.setViewLogout(false)} className="tab-link-tab-4 w-inline-block w-tab-link">
                <img src={gastronIcon} loading="lazy" height="33" alt=""/>
                
            </NavLink>
        </div>
    )
}

export default SideNavBar;