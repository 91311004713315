import React from 'react';
import ContactUs from './ContactUs';
import HotlineInfo from './HotlineInfo';

const Support = ({ profileInfo }) => {

    return (
        <div data-w-tab="Support" className="settingstab">
            <div className="grid-26">
                <ContactUs contactInfo={profileInfo.contactUs} />
                <HotlineInfo hotlineInfo={profileInfo.hotLineInfo} />
            </div>
        </div>
    )
}

export default Support;