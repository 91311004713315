import React, { useContext, useEffect, useState } from "react";
import MenuContext from "../../Providers/Menu/MenuContext";
import Category from "./Category";
import AddEditCategory from "./AddEditCategory";
import { Logger } from "../../Helpers/Logger.service";
import { USER_ACTIONS } from "../../constants/app.constant";
import ConfirmHideDialog from "./ConfirmHideDialog";
import DeleteConfirmation from "./DeleteConfirmation";
import Overlayinfo from "../../SharedComponents/OverlayInfo";
import BoxLayout from "../../SharedComponents/BoxLayout";
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


export const CateoriesBox = (props) => {
  const { t, i18n } = useTranslation();
  const [menuList, setMenuList] = useState([]);
  const menuService = useContext(MenuContext);
  const ACTIONS = USER_ACTIONS;

  const [displayAddEdit, setDisplayAddEdit] = useState(false);
  const [displayHide, setDisplayHide] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isLoading, setLoading] = useState(true);

  const [showInfoOverlay, setShowInfoOverlay] = useState(false);

  // Use following to get Records
  useEffect(() => {
    let mounted = true;
    menuService
      .getCategoryListApi()
      .then((menus) => {
        if (mounted) {
          // setMenuList(menus);
          menuService.setPageLoaded(true);
          setLoading(false);
          // setSelectedCategory(menuService.getSelectedCategory());

          //TODO: Redraw Overview Pane
        }
      })
      .catch((error) => {
        console.error(error);
      });

    /* if(menuService.getCategoryList()){
            console.log("------SHould be cakked when updated-----");
            console.log(menuService.getCategoryList());
            setMenuList(menuService.getCategoryList())
        }   */

    /* if(menuService.selectedCategory) {
            setMenuList(menuService.getCategoryList());
        } */
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (!menuService.allCategories) return;
    setMenuList([...menuService.allCategories]);
  }, [menuService.allCategories]);

  const onAddNewCategory = () => {
    setDisplayAddEdit(true);
    setSelectedCategory({});
  };

  const onCloseAddEditDialog = () => {
    setDisplayAddEdit(false);
  };

  const onSelectCategory = (category, action) => {
    Logger.log(action, category);
    menuService.selectCategoryById(category.id);
    setSelectedCategory(category);
    takeAction(action, category);
  };

  const takeAction = (action, selectedCategory) => {
    switch (action) {
      case ACTIONS.EDIT:
        setDisplayAddEdit(true);
        break;
      case ACTIONS.DELETE:
        setDisplayDelete(true);
        break;
      case ACTIONS.HIDE:
        setDisplayHide(true);
        break;
      case ACTIONS.SYNC:
        onSync(selectedCategory);
        // setDisplayHide(true);
        break;
      default:
        break;
    }
  };

  const onSync = (selectedCategory) => {
    menuService.persistActionCategory(ACTIONS.SYNC, selectedCategory);
    // Delete, hide, Add, update
  };

  const onDeleteConfirmation = (decision) => {
    if (decision === "yes") {
      // menuService.removeCategoryApi(selectedCategory);
      menuService.persistActionCategory(ACTIONS.DELETE, selectedCategory);
      menuService.selectCategory({});
      /* menuService.removeCategoryApi(selectedCategory.id).then(res => {
                Logger.log(res);
                setMenuList(res);
                menuService.selectCategory({});
            }); */
    }
    setDisplayDelete(false);
  };

  const onHideConfirmation = (decision) => {
    Logger.log("::onHideConfirmation::", decision);
    if (decision === "yes") {
      let hideStatus = selectedCategory.hide == "false" ? "true" : "false";
      selectedCategory.hide = hideStatus;
      menuService.persistActionCategory(ACTIONS.HIDE, selectedCategory);
      /* menuService.hideCategoryApi(selectedCategory).then(res => {
                Logger.log(res);
                // setMenuList(res);
            }); */
    }
    setDisplayHide(false);
  };

  const onRefreshList = (list) => {
    console.log(menuService.getCategoryList());
    setMenuList(menuService.getCategoryList());
  };

  const helpInfo = () => {
    return (
      <React.Fragment>
        {
          <Overlayinfo
            show={showInfoOverlay}
            onHide={() => setShowInfoOverlay(!showInfoOverlay)}
          >
            <div className="rich-text-block-3 w-richtext">
              <h4>What’s a Rich Text element?</h4>
              <p>
                The rich text element allows you to create and format headings,
                paragraphs, blockquotes, images, and video all in one place
                instead of having to add and format them individually. Just
                double-click and easily create content.
              </p>
              <h6>Static and dynamic content editing</h6>
              <p>
                A rich text element can be used with static or dynamic content.
                For static content, just drop it into any page and begin
                editing. For dynamic content, add a rich text field to any
                collection and then connect a rich text element to that field in
                the settings panel. Voila!
              </p>
              <h6>How to customize formatting for each rich text</h6>
              <p>
                Headings, paragraphs, blockquotes, figures, images, and figure
                captions can all be styled after a class is added to the rich
                text element using the "When inside of" nested selector system.
              </p>
            </div>
          </Overlayinfo>
        }
      </React.Fragment>
    );
  };

  const getRenderedContent = () => {
    if (!isLoading) {
      return (
        <React.Fragment>
          <div className="pos-rel">
            <div className="w-layout-grid grid-9">
              {menuList &&
                menuList.map((menu, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Category
                        menu={menu}
                        isSelected={selectedCategory?.id === menu.id}
                        selectedCategory={selectedCategory}
                        selectCategory={onSelectCategory}
                      />
                    </React.Fragment>
                  );
                })}
              <div
                af-el="formcategory"
                className="_403-1"
                onClick={() => onAddNewCategory()}
              ></div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div
          data-w-id="f7a71ccf-eca6-f728-d23f-86c4d72c7116"
          className="pageloading"
        ></div>
      );
    }
  };

  return (
    <React.Fragment>
      <MenuContext.Consumer>
        {(context) => (
          <React.Fragment>
            <BoxLayout
              className="category"
              title={t('pages.menu.categories')}
              helpInfo={helpInfo}
              toggleHelpInfo={setShowInfoOverlay}
            >
              {getRenderedContent()}
            </BoxLayout>

            {displayAddEdit && (
              <AddEditCategory
                refresh={onRefreshList}
                show={displayAddEdit}
                close={onCloseAddEditDialog}
                category={selectedCategory}
              />
            )}
            {displayDelete && (
              <DeleteConfirmation
                show={displayDelete}
                action={(decision) => onDeleteConfirmation(decision)}
              />
            )}
            {displayHide && (
              <ConfirmHideDialog
                status={selectedCategory.hide}
                name={selectedCategory.categoryName}
                show={displayHide}
                action={(decision) => onHideConfirmation(decision)}
              />
            )}
          </React.Fragment>
        )}
      </MenuContext.Consumer>
    </React.Fragment>
  );
};

export default CateoriesBox;
