import React, { useRef, useState } from "react";
import { INTERNATIONAL_LOCALES, LOCALSTORAGE_KEYS } from "../constants/app.constant";
import i18n from "../i18n";
import OutSideCloseHelper from "./OutSideCloseHelper";



// const getLanguage = () => i18n.language || window.localStorage.getItem(LOCALSTORAGE_KEYS.i18);
const getLanguage = () => "de";

export const LanguageSelector = (props) => {
  let { className = "dropdown-22", type = "light", style } = props;
  const languageDropDownRef = useRef(null);
  const [languageDropDown, setDisplayDropDown] = useState(false);
  const [selectedLang, setSelectedLang] = useState(getLanguage());

  OutSideCloseHelper(languageDropDownRef, () => setDisplayDropDown(false));

  className = className + " language-" + type + " ";

  const onLanguageHandle = (event, newLang) => {
    console.log(newLang);
    i18n.changeLanguage(newLang);
    setSelectedLang(newLang);
    setDisplayDropDown(false);
  };

  return (
    <>
      <style>{".w--open{display:block!important;}"}</style>
      <div id="langSelectorWrapper"
        style={style}
        data-delay="0"
        data-hover="false"
        className={``}
        ref={languageDropDownRef}
      >
        <div
          className={
            "dropdown-toggle-26 w-dropdown-toggle " +
            (languageDropDown ? "w--open" : "")
          }
          onClick={() => setDisplayDropDown(!languageDropDown)}
        >
          <div className="text-block-402">{selectedLang}</div>
        </div>
        <nav id="langSelectorDropdown"
          className={
            // "dropdown-list-27 w-dropdown-list " +
            (languageDropDown ? "w--open" : "")
          }
        >
          <div
            className="text-block-394"
            onClick={(e) => {
              onLanguageHandle(e, INTERNATIONAL_LOCALES.DE.code);
            }}
          >
            Deutsch
          </div>
          <div
            className="text-block-394"
            onClick={(e) => {
              onLanguageHandle(e, INTERNATIONAL_LOCALES.EN.code);
            }}
          >
            English
          </div>
        </nav>
      </div>
      {/* <div data-hover="" data-delay="0" className="dropdown-22 w-dropdown">
                <div className="dropdown-toggle-19 w-dropdown-toggle"><img src={globeIcon} loading="lazy" width="22" height="22" alt="" /></div>
                <nav className="dropdown-list-20 w-dropdown-list">
                    <div className="text-block-394">Deutsch</div>
                    <div className="text-block-394">Русский</div>
                    <div className="text-block-394">عربى</div>
                    <div className="text-block-394">Español</div>
                    <div className="text-block-394">עִברִית</div>
                </nav>
            </div> */}
    </>
  );
};

export default LanguageSelector;
