import React, { Component } from "react";
import { ICONS } from "../../Helpers/Icon.service";
import { APP_CONFIG } from "../../constants/Config";

export default class acknowModal extends Component {

  bellIcon = ICONS.BELL_DARK_ICON;
  thumbsUpIcon = ICONS.THUMBS_UP_ICON;

  divStyle;
  constructor(props) {
    super(props);
    this.divStyle = {
      display: this.props.displayModal ? 'show' : ''
    };

    /* if(this.props.displayModal) {
      document.body.classList.add('model-open');
    } */
  }


  closeModal(e) {
    e.stopPropagation()
    this.props.closeModal()
  }

  onConfirmed(e) {
    this.props.onConfirmation();
  }

  render() {
    const isOpen = this.props.displayModal;
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.divStyle.display = this.props.displayModal ? 'show' : '';
    return (

      <React.Fragment>
        <div className="div-block-778 confirm-customer-call" style={isOpen ? { display: 'flex' } : {display: 'none'}}>
          <div className="div-block-779">
            <img src={this.bellIcon} loading="lazy" width="42" height="" alt="" className="image-80" />
            <div className="checkhelp" onClick={(e) => this.closeModal(e)}>
              <div className="text-block-276 cursor-pointer">Cancel</div>
            </div>
            <div data-w-id="463ae5d4-b7d8-e0ea-f7d3-2c1aef9441ee" className="div-block-780" onClick={(e) => this.onConfirmed(e)}>
              <div className="text-block-276 cursor-pointer">Check</div>
              <img src={this.thumbsUpIcon} loading="lazy" width="17" alt="" className="image-81" />
            </div>
          </div>
        </div>
      </React.Fragment>

    );
  }
}
