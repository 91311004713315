export const TAG_ICONS = [
    {
        name: 'pork',
        image: 'pig.svg',
        id: 1,
        isSelected: false
    },
    {
        name: 'vegan',
        image: 'leaf_dAsset-7.svg',
        id: 2,
        isSelected: false
    },
    {
        name: 'vegetarian',
        image: 'saladAsset-2.svg',
        id: 3,
        isSelected: false
    },
    {
        name: 'homemade',
        image: 'home_s_dAsset-6.svg',
        id: 4,
        isSelected: false
    },
    {
        name: 'alcohol',
        image: 'vine_dAsset-5.svg',
        id: 5,
        isSelected: false
    },
    {
        name: 'beef',
        image: 'beef_dAsset-4.svg',
        id: 6,
        isSelected: false
    },
    {
        name: 'ourtop',
        image: 'starAsset-1.svg',
        id: 7,
        isSelected: false
    },
    {
        name: 'spicy',
        image: 'pepperAsset-1.svg',
        id: 8,
        isSelected: false
    },
    {
        name: 'seafood',
        image: 'fishAsset-3.svg',
        id: 9,
        isSelected: false
    },
    {
        name: 'fowl',
        image: 'chickenAsset-3.svg',
        id: 10,
        isSelected: false
    },
    {
        name: 'organic',
        image: 'organicAsset-4.svg',
        id: 11,
        isSelected: false
    },
    {
        name: 'ethnic',
        image: 'globeAsset-1.svg',
        id: 12,
        isSelected: false
    }
]