
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const ConfirmHideDialog = (props) => {
    const name = props.name || '';
    const hideStatus = ((props.status === true || props.status === 'true') ? 'unhide': 'hide');
    const { t } = useTranslation();

    const confirmHide = () => {
        props.action('yes');
    }

    const cancelHide = () => {
        props.action('no');
    }

    const getStyle = () => {
        let styles = {};
        if (props.show) {
            styles = { display: 'flex' }
        } else {
            styles = { display: 'none' }
        }
        return styles;
    }

    return (
        <React.Fragment>
            <div className="hidedialog" style={getStyle()}>
                <div className="hidedialogpopup">
                    <div className="hidetxt">{t(hideStatus)} &quot;{name}&quot;?</div>
                    <div className="hidediv">
                        <a href="#" className="button-41 w-button">verstecken</a>
                        <a className="button-42 w-button" onClick={confirmHide}><Trans>shared.actions.yes</Trans></a>
                        <a data-w-id="ae0cdda6-5d71-b9ad-4c12-7857494fb67d" className="button-43 w-button" onClick={cancelHide}><Trans>shared.actions.no</Trans></a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ConfirmHideDialog