import React, { useContext } from 'react';
import InfoCard from './InfoCard';
import Footer from './Footer';
import TopSellerContainer from './topSeller/TopSellerContainer';
import GraphSection from './GraphSection';
import { topSellerdata, whatsNew, wishesRealised } from './data';
import { useHistory } from 'react-router-dom';
import HomeProvider from '../../Providers/Home/HomeProvider';
import LoadingIndicator from '../../SharedComponents/Loading';
import HomeContext from '../../Providers/Home/HomeContext';
import { set } from 'lodash';

const Home = () => {
    let history = useHistory();
    let homeService = useContext(HomeContext);
    const { isPageLoaded } = homeService;
    const { showConsider } = homeService;
    const { closeConsider } = homeService;

    const getLoader = () => {
        if (!isPageLoaded) {
            return <LoadingIndicator />;
        }
    };

    // const closeConsider = () => {
        
    // }

    const getConsider = () => {
        if (showConsider) {
            return <div data-w-id="33c5ed76-d1ea-67df-45c3-ee69d5a13e44" id="consider" className="consider" style={{display: "flex"}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="image-215" style={{ fill: "#002d40", height: "33px", width: "33px" }}>
                    <path class="a" d="M569.52,440c18.46,32-4.71,72-41.58,72H48.05c-36.93,0-60-40.05-41.57-72L246.42,24c18.47-32,64.72-32,83.16,0L569.52,440ZM288,354a46,46,0,1,0,46,46A46,46,0,0,0,288,354ZM244.33,188.65l7.42,136a12,12,0,0,0,12,11.35h48.54a12,12,0,0,0,12-11.35l7.41-136a12,12,0,0,0-12-12.65H256.31a12,12,0,0,0-12,12.65Z" />
                </svg>

                <div className="div-block-1068">
                    <div className="text-block-522">Please consider!<br />an early access pre alpha version<br />of Gastron Software is being used.</div>
                    <div className="text-block-522-copy">minor bugs, issues, loading problems, image showing issues<br />and functionality troubles are to expect.</div>
                    <div className="text-block-522-2">first-aid-troubleshoot:<br />refresh &amp; clear browser cache.<br />log-out and log-in again.<br />contact us via support: 0176 700 169 33</div>
                </div>
                <svg onClick={closeConsider} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424.13 423.97" className="image-216" style={{ fill: "#002d40", height: "22px", width: "22px" }}>
                    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M203.82,423.92C102,426.48,5.72,336.38.25,222.42-5.59,100.42,90.31,3.94,204.07.13,322-3.83,418.14,86.47,423.86,201.69a207.14,207.14,0,0,1-24.29,109.24,209.37,209.37,0,0,1-77.09,81.94C289.7,412.83,254.05,423.28,203.82,423.92Zm-54.64-105.3c3.3.28,5.8-1.42,8.13-3.78,16.89-17,33.92-33.93,50.68-51.09,3.2-3.27,4.92-3.3,8.13,0,16.74,17.18,33.73,34.11,50.64,51.12,4.77,4.79,10.74,5.45,15.08,1.21q17.18-16.76,34-33.86c4.7-4.78,4.24-10.77-.51-15.47q-24-23.73-47.86-47.54c-7.42-7.41-7.32-7.42.06-14.71q23.81-23.55,47.5-47.21c5.16-5.16,5.71-10.38,1.28-14.94Q299.43,125,282.11,108c-4.34-4.26-9.13-4.13-13.94-.29a44.27,44.27,0,0,0-4,3.74c-16.28,16.47-32.62,32.88-48.76,49.48-2.67,2.75-4.12,2.69-6.63-.06-3.21-3.5-6.8-6.65-10.1-10.12-13.33-14-27-27.63-40.62-41.35-6-6.07-11.22-6.11-17.23-.15q-15.25,15.15-30.41,30.38c-7.29,7.29-7.29,11.78,0,19,16.8,16.65,33.54,33.37,50.44,49.92,2.65,2.6,2.74,4.09,0,6.73-17.26,16.9-34.34,34-51.51,51-5.44,5.39-5.76,11.11-.36,16.61,10.73,10.94,21.65,21.7,32.48,32.54A9.88,9.88,0,0,0,149.18,318.62Z" />
                    </g></g>
                </svg>
            </div>;
        }
    };

    return (
        <div className="divhome">
            <InfoCard whatsNew={whatsNew} wishesRealised={wishesRealised} />
            <div className="div-block-865">
                {getLoader()}
                <div className="w-layout-grid grid-40">
                    <TopSellerContainer topSellerdata={[...homeService.itemList]} />
                    <GraphSection />
                </div>
                <Footer />
            </div>
            { getConsider() }
        </div>
    )
}

export default Home;