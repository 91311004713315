import React, { Component } from "react";
import MenuContext from './MenuContext';

import { API } from "aws-amplify";
import { authenticationService } from '../../Services/auth.service';
import { Logger } from '../../Helpers/Logger.service';
// import { API_PATH_NAME } from "../../Config/index";
import { API_PATH_NAME } from '../../Config/';

import { CategoryModel, ProductModel } from '../../Models/ProductAndCategory'
import { USER_ACTIONS, SYNC_STATUS } from "../../constants/app.constant";

import { ROUTES } from "../../constants/Routes";
import { awsRequestInterceptor } from "../../Helpers/Interceptors";



/* OLD PATHs
const MENU_API_PATH = {
   PRODUCT: '/product',
   UPDATE_PRODUCT: '/product/update/',
   DELETE_PRODUCT: '/product/delete/',
   HIDE_PRODUCT: '/product/hide/',
   ADD_PRODUCT: '/product/put/',
   ADD_CATEGORY: '/product/putcategory/',
   COPY_CATEGORY: '/product/copycategory/',
   MOVE_ITEM: '/product/moveitem/',
}
*/
const MENU_API_PATH = {
    MENU: '/menu/',
    UPDATE_PRODUCT: '/menu/product/update',
    DELETE_PRODUCT: '/menu/product/delete',
    CREATE_PRODUCT: '/menu/product/create',
    CREATE_CATEGORY: '/menu/category/create',
    HIDE_CATEGORY: '/menu/category/hide',
    DELETE_CATEGORY: '/menu/category/delete',
    UPDATE_CATEGORY: '/menu/category/update'
}


export default class MenuProvider extends Component {
   
    default_route_if_not_logged_in = ROUTES.DEFAULT_IF_NOT_LOGGED_IN;
    state = {
        selectedCategory: null,
        allCategories: [],
        refreshRequired: false,
        isPageLoaded: false, // Should be called once
        businessInfo: null

    };

    get allCategories() {
        return this.state.allCategories;
    }

    setBusinessInfo = (metaData) => {
        if(metaData){
            this.setState({businessInfo: metaData});
        }
    }

    get BusinessInfo() {
        return this.state.businessInfo;
    }

    setPageLoaded = (isLoaded) => {
        this.setState({
            isPageLoaded: isLoaded
        })
    }

    setDefaultSelected = (menulist) => {
        if (menulist && menulist.length) {
            this.setState({ selectedCategory: menulist[0] })
        }
    }

    onSelectCategory = (selectedCategory) => {
        Logger.log("::selectedCategory::", selectedCategory);
        this.setState(state => {
            return {
                ...state,
                selectedCategory: selectedCategory
            }
        }, () => {
            console.log(this.state)
        })
    }

    selectCategoryById = (categoryId) => {
        if (categoryId && this.state.allCategories && this.state.allCategories.length) {

            let categoryToSelect = this.state.allCategories.filter(list => list.id === categoryId);
            if (categoryToSelect && categoryToSelect.length) {
                Logger.warn("::selectCategoryById::")
                /* this.setState({ selectedCategory: categoryToSelect[0] }, () => {
                    console.log(this.state.selectedCategory)
                }) */
                this.setState(state => {
                    return {
                        ...state,
                        selectedCategory: { ...categoryToSelect[0] }
                    }
                }, () => {
                    console.log(this.state.selectedCategory)
                })

            }

        }
    }

    get selectedCategory() {
        return this.state.selectedCategory
    }

    getSelectedCategory = () => {
        return this.selectedCategory;
    }

    setCategoryList = (menus) => {
        if (menus) {
            this.setState({
                allCategories: menus,
                selectedProduct: null
            });
        }
    }

    setSelectedProduct = ({ ...product }) => {
        if (product) {
            this.setState({
                selectedProduct: product
            })
        }
    }

    get getSelectedProduct() {
        return this.state.selectedProduct;
    }

    getCategoryList = () => {
        return this.state.allCategories;
    }

    findCategoryIndexById = (id) => {
        if (!id) {
            throw 'Category Id is required';
        }

        let cList = [...this.allCategories];
        return cList.findIndex(category => category.id === id)
    }

    findProductIndexById = (id, productList) => {
        if (!id) {
            throw 'Product Id is required';
        }

        if (!productList || !productList.length) {
            throw 'Product List is required';
        }

        return productList.findIndex(product => product.id === id)
    }

    // Not in use but can be used in future
    findProdIndexByCategoryIdAndProdId = (catId, prodId) => {
        let catIndex = this.findCategoryIndexById(catId);
        let cList = [...this.allCategories];
        let prodIndex = this.findProductIndexById(prodId, cList[catIndex]['products']);
        if (prodIndex === -1) {
            throw 'Product Doesn\'t exist';
        }
        return prodIndex;
    }


    mapCategoryList = (list) => {
        let mappedCategoryList = [];
        if (list && list.length) {
            list.forEach(element => {
                mappedCategoryList.push(new CategoryModel(element))
            });
        }

        return mappedCategoryList;
    }

    handleBeforeApiCall(object) {
        delete object.status;
        delete object.action;

        return object;
    }


    beforeCategoryAction = (action, { ...categoryObj }, isSync) => {
        // Set IN PROGRESS status as we are updating or adding record
        if (action === USER_ACTIONS.SYNC) {
            categoryObj.status = SYNC_STATUS.SYNC;
        } else {
            if (isSync) {
                categoryObj.status = SYNC_STATUS.SYNC;
            } else {
                categoryObj.status = SYNC_STATUS.INPROGRESS;
            }
            categoryObj.action = action;
        }
        let cList = [...this.allCategories];
        let index = cList.length; // Default would be new record to be append at the very last of the array

        if (categoryObj && categoryObj.id !== 0) {
            index = cList.findIndex(cat => cat.id === categoryObj.id);
        }

        // Following would Add or update Category Array
        cList.splice(index, 1, new CategoryModel(categoryObj));
        this.setCategoryList([...cList]);

        switch (action) {
            case USER_ACTIONS.EDIT:
                this.updateCategory(categoryObj, index);
                break;
            case USER_ACTIONS.ADD:
                this.createCategory(categoryObj, index);
                break;
            case USER_ACTIONS.DELETE:
                this.removeCategory(categoryObj);
                break;
            case USER_ACTIONS.HIDE:
                this.hideCategory(categoryObj);
                break;
            case USER_ACTIONS.SYNC:
                console.warn("Syncing");
                this.beforeCategoryAction(categoryObj.action, categoryObj, true);
                break;
            default:
                break;
        }

    }

    afterCategoryAction = (index, { ...serverCateoryObj }) => {
        // index 0 is valid for array, we need to check only null and undefined
        if (index == null || index == undefined) {
            let id = serverCateoryObj.categoryId || serverCateoryObj.id;
            index = this.findCategoryIndexById(id);
        }

        if (index === -1) {
            throw 'Category Doesn\'t exist';
        }

        let cList = [...this.allCategories];
        serverCateoryObj = { ...cList[index], ...serverCateoryObj }
        cList.splice(index, 1, new CategoryModel(serverCateoryObj));
        this.setCategoryList([...cList]);
        // TODO: Need to handle Products array in case of EDIT category because we dont receive it in update category
    }



    /**
     * 
     * @param {*} catId 
     * Delete Category
     */
    deletCategoryOnClient = (catId) => {
        let index = this.findCategoryIndexById(catId);
        if (index === -1) {
            throw 'Category Doesn\'t exist';
        }

        let cList = [...this.allCategories];
        cList.splice(index, 1);
        this.setCategoryList([...cList]);
    }



    /**
     * 
     * @param {*} catId 
     * @param {*} prodId 
     * Delete Product
     */
    deleteProductOnClient = (catId, prodId) => {
        let catIndex = this.findCategoryIndexById(catId);
        let cList = [...this.allCategories];
        let prodIndex = this.findProductIndexById(prodId, cList[catIndex]['products']);
        if (prodIndex === -1) {
            throw 'Product Doesn\'t exist';
        }

        let products = [...cList[catIndex]['products']];
        products.splice(prodIndex, 1);
        cList[catIndex]['products'] = [...products];
        this.setCategoryList([...cList]);
        this.selectCategoryById(catId);
    }


    beforeProductAction = (action, catId, { ...productObject }, isSync) => {
        // Set IN PROGRESS status as we are updating or adding record

        if (action === USER_ACTIONS.SYNC) {
            productObject.status = SYNC_STATUS.SYNC;
        } else {
            if (isSync) {
                productObject.status = SYNC_STATUS.SYNC;
            } else {
                productObject.status = SYNC_STATUS.INPROGRESS;
            }
            productObject.action = action;
        }

        let catIndex = this.findCategoryIndexById(catId);
        let cList = [...this.allCategories];
        let products = [...cList[catIndex]['products']];
        let prodIndex = products.length || 0; // default index would be Zero

        if (productObject && productObject.id) {
            // Existing Product
            prodIndex = this.findProductIndexById(productObject.id, cList[catIndex]['products'])
        }

        console.log(new ProductModel(productObject));
        // Following would Add or update Array
        console.log(cList[catIndex]);
        // cList[catIndex]['products'].splice(prodIndex, 1, new ProductModel(productObject));
 
        
        products.splice(prodIndex, 1, new ProductModel(productObject));
        cList[catIndex]['products'] = [...products];
        console.log(cList[catIndex]);


        this.setCategoryList([...cList]);
        this.selectCategoryById(catId);


        // Make API Call here
        switch (action) {
            case USER_ACTIONS.EDIT:
                this.updateProduct(prodIndex, catId, productObject);
                break;
            case USER_ACTIONS.ADD:
                this.createProduct(prodIndex, catId, productObject);
                break;
            case USER_ACTIONS.DELETE:
                this.removeProduct(catId, productObject);
                break;
            /* case USER_ACTIONS.HIDE:
               // No separate API for Hide 
                break; */
            case USER_ACTIONS.SYNC:
                console.warn("Syncing");
                this.beforeProductAction(productObject.action, catId, productObject, true);
                break;
            default:
                break;
        }

    }

    afterProductAction = (prodIndex, catId, { ...serverProdObj }) => {

        // index 0 is valid for array, we need to check only null and undefined
        let catIndex = this.findCategoryIndexById(catId);
        let cList = [...this.allCategories];

        if (prodIndex == null || prodIndex == undefined) {
            prodIndex = this.findProductIndexById(serverProdObj.id, cList[catIndex]['products']);
        }

        if (prodIndex === -1) {
            throw 'Product Doesn\'t exist';
        }

        let products = [...cList[catIndex]['products']];
        serverProdObj = {...products[prodIndex], ...serverProdObj};
        cList[catIndex]['products'].splice(prodIndex, 1, new ProductModel(serverProdObj));
        this.setCategoryList([...cList]);
        this.selectCategoryById(catId);
        // this.setSelectedProduct(cList[catIndex]['products'][prodIndex]);
    }


    /**
     * 
     * API Related methods only
     */
    /**
     * 
     * Categories/Menus listing API
     */

    getCategoryListApi = (selectDefault = false) => {
        Logger.log('::getCategoryListApi::');
        const userId = authenticationService.userID;
        let options = {
            body: {
                //id: authenticationService.userID
            }
        }
        return API.get(API_PATH_NAME.MENU, MENU_API_PATH.MENU + userId).then(menus => {
            let list = this.mapCategoryList(menus.Items);
            this.setCategoryList(list);
            if (selectDefault) {
                this.setDefaultSelected(list);
            }
            this.setBusinessInfo(menus.metadata);
            return list;
        }).catch(error => {
            console.log(error);
            if(error === 'No current user' || error.status === 400 || error.code === 'NotAuthorizedException') {
                authenticationService.logout();
                // this.props.history.push(this.default_route_if_not_logged_in);
                // return Promise.reject(error);
            }
        });
    }


    /**
     * 
     * @param {*} categorieId 
     * @param {*} hideStatus 
     * @returns 
     * Hides the Category only
     */
    hideCategory = ({ ...category }) => {
        const userId = authenticationService.userID;
        let options = {
            body: {
                userId: userId,
                categoryId: category.id,
                hide: category.hide
            }
        }
        category = this.handleBeforeApiCall(category);
        return API.post(API_PATH_NAME.MENU, MENU_API_PATH.HIDE_CATEGORY, awsRequestInterceptor(options)).then(servercategory => {
            servercategory['status'] = SYNC_STATUS.SUCCESS;
            this.afterCategoryAction(undefined, servercategory);
            // return response;
            // return this.getCategoryListApi();
        }).catch(error => {
            Logger.log(error);
            category['status'] = SYNC_STATUS.ERROR;
            this.afterCategoryAction(undefined, category);
        });;
    }


    /**
     * 
     * @param {*} category 
     * @returns 
     * Create new category
     */
    createCategory = ({ ...category }, index) => {
        const userId = authenticationService.userID;
        let options = {
            body: {
                userId: userId,
                categoryName: category.categoryName,
                hide: 'false',
                activeTime: category.activeTime
            }
        };

        if (category.id) {
            options.body['id'] = category.id;
        }

        category = this.handleBeforeApiCall(category);
        return API.post(API_PATH_NAME.MENU, MENU_API_PATH.CREATE_CATEGORY, awsRequestInterceptor(options)).then(servercategory => {
            servercategory['status'] = SYNC_STATUS.SUCCESS;
            this.afterCategoryAction(index, servercategory);
            // return this.getCategoryListApi();
        }).catch(error => {
            Logger.log(error);
            category['status'] = SYNC_STATUS.ERROR;
            this.afterCategoryAction(index, category);
        });
    }

    /**
     * 
     * @param {*} category 
     * @returns 
     * Update new category
     */
    updateCategory = ({ ...category }, index) => {
        const userId = authenticationService.userID;
        let options = {
            body: {
                userId: userId,
                categoryId: category.id,
                categoryName: category.categoryName,
                hide: category.hide,
                activeTime: category.activeTime
            }
        };

        if(category.timestamp) {
            options.body.timestamp = category.timestamp;
        }

        category = this.handleBeforeApiCall(category);
        return API.post(API_PATH_NAME.MENU, MENU_API_PATH.UPDATE_CATEGORY, awsRequestInterceptor(options)).then(servercategory => {
            servercategory['status'] = SYNC_STATUS.SUCCESS;
            this.afterCategoryAction(index, servercategory);
            // return this.getCategoryListApi();
        }).catch(error => {
            Logger.log(error);
            category['status'] = SYNC_STATUS.ERROR;
            this.afterCategoryAction(index, category);
        });
    }

    /**
     * 
     * @param {*} categorieId 
     * @returns 
     * Remove Category from the list
     */
    removeCategory = (category) => {
        const userId = authenticationService.userID;
        let options = {
            body: {
                userId: userId,
                categoryId: category.id
            }
        };
        // this.deletCategoryOnClient(category.id);
        return API.post(API_PATH_NAME.MENU, MENU_API_PATH.DELETE_CATEGORY, awsRequestInterceptor(options)).then(response => {
            this.deletCategoryOnClient(category.id);
            // return this.getCategoryListApi();
        }).catch(error => {
            category['status'] = SYNC_STATUS.ERROR;
            Logger.log("removeCategory Failed", error.response, category);
            this.afterCategoryAction(undefined, category);
        });

    }

    // ##########------------ Product API's ----------###########

    /**
     * 
     * Product API's
     * Also used in hide product -- DONE
     * Update Product
     */

    updateProduct = (prodIndex, categoryId, {...product}) => {
        let userId = authenticationService.userID;
        let options = {
            body: {
                userId: userId,
                categoryId: categoryId,
                product: product
            }
        };

        product = this.handleBeforeApiCall(product);
        return API.post(API_PATH_NAME.MENU, MENU_API_PATH.UPDATE_PRODUCT, awsRequestInterceptor(options)).then(response => {
            let serverProductObj = response.product;
            serverProductObj['status'] = SYNC_STATUS.SUCCESS;
            this.afterProductAction(prodIndex, categoryId, serverProductObj);

            // return this.getCategoryListApi();
        }).catch(error => {
            Logger.log(error);
            product['status'] = SYNC_STATUS.ERROR;
            this.afterProductAction(prodIndex, categoryId, product);
        });
    }

    /**
     * 
     * @param {*} categoryId 
     * @param {*} product 
     * @returns 
     * 
     * Create New Product/Item
     */
    createProduct = (prodIndex, categoryId, {...product}) => {
        
        let userId = authenticationService.userID;
        let options = {
            body: {
                userId: userId,
                categoryId: categoryId,
                product: product
            }
        };
        product = this.handleBeforeApiCall(product);
        return API.post(API_PATH_NAME.MENU, MENU_API_PATH.CREATE_PRODUCT, awsRequestInterceptor(options)).then(response => {
            let serverProductObj = response.product;
            serverProductObj['status'] = SYNC_STATUS.SUCCESS;
            this.afterProductAction(prodIndex, categoryId, serverProductObj);
            // return this.getCategoryListApi();
        }).catch(error => {
            Logger.log(error);
            product['status'] = SYNC_STATUS.ERROR;
            this.afterProductAction(prodIndex, categoryId, product);
        });
    }


    /* 
    * Remove Product/Items from the Page.
    */

    removeProduct = (catId, {...product}) => {
        Logger.log('::removeProduct::');
        let userId = authenticationService.userID;
        let options = {
            body: {
                categoryId: catId,
                productId: product.id,
                userId: userId
            }
        };

        return API.post(API_PATH_NAME.MENU, MENU_API_PATH.DELETE_PRODUCT, awsRequestInterceptor(options)).then(response => {
            Logger.log(response);
            this.deleteProductOnClient(catId, product.id);
            // return this.getCategoryListApi();
        }).catch(error => {
            Logger.log(error);
            product['status'] = SYNC_STATUS.ERROR;
            this.afterProductAction(undefined, catId, product);
            /* if (error.response.status === 403) {
                Logger.log("DynamoDB for user is not existing: " + error.response.status)
            } */
        });
    }


    render() {
        return (
            <MenuContext.Provider
                value={{
                    refreshRequired: this.state.refreshRequired,
                    businessInfo: this.BusinessInfo,
                    isPageLoaded: this.state.isPageLoaded,
                    setPageLoaded: this.setPageLoaded,
                    allCategories: this.allCategories,
                    selectedCategory: this.selectedCategory,
                    getCategoryList: this.getCategoryList,
                    setCategoryList: this.setCategoryList,
                    getSelectedCategory: this.getSelectedCategory,
                    getCategoryListApi: this.getCategoryListApi,
                    selectCategoryById: this.selectCategoryById,
                    selectCategory: this.onSelectCategory,
                    hideCategoryApi: this.hideCategory,
                    createCategoryApi: this.createCategory,
                    removeCategoryApi: this.removeCategory,
                    removeProductApi: this.removeProduct,
                    createProductApi: this.createProduct,
                    updateProductApi: this.updateProduct,
                    setSelectedProduct: this.setSelectedProduct,
                    // selectedProduct: this.state.selectedProduct,
                    getSelectedProduct: this.getSelectedProduct,
                    persistActionCategory: this.beforeCategoryAction,
                    persistProductAction: this.beforeProductAction,
                }}
            >
                {this.props.children}
            </MenuContext.Provider>
        );
    }
}
