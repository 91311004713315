export const topSellerProduct = `
query getTopSellingProducts ($input: TopSellerProductsInput!){
  TopSellerProducts(topSellerInput: $input) {
    to
    from
    topSellers {
      imageUrl
      productName
      quantity
      date
      productId
    }
    
  }
}
`;

export const archiveAnalytics = `
query getArchiveAnalytics ($input: manageArchiveInput!) {
  manageArchive(input: $input) {
    total_orders
    total_products 
    total_profit
    userId
    data {
      orderId
      total_price
      timestamp
      products {
        price
        productId
        productName
        quantity
      }
    }
  }
}
`;
