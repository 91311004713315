import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ROUTES } from "../constants/Routes";
import { Auth } from "aws-amplify";
import { authenticationService } from "../Services/auth.service";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isPageLoading, setPageLoading] = useState(true);
  const default_route_if_not_logged_in = ROUTES.DEFAULT_IF_NOT_LOGGED_IN;

  const isLoggedIn = rest.isLoggedIn; //AuthService.isLoggedIn()
  console.log("PROVATE ROUTE", isLoggedIn);
  console.log({ ...rest });

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        setPageLoading(false);
        console.log("#######isLoggedIn###########", data);
      })
      .catch((error) => {
        if (
          error === "No current user" ||
          error.status === 400 ||
          error.code === "NotAuthorizedException"
        ) {
          authenticationService.logout();
          setPageLoading(false);
        }
      });
  }, []);

  if (isPageLoading) {
    return (
      <div
        id="w-node-_1ade66ec-fc8e-5fb9-806b-0c5adeb969bb-999f542a"
        className="data-content-section"
      >
        <div
          data-w-id="f7a71ccf-eca6-f728-d23f-86c4d72c7116"
          className="pageloading"
        ></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            <React.Fragment>
              {/* "iSlogged=true in Protected Route" */}
              <Component {...props} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* "iSlogged=False Redirect in Protected Route" */}
              <Redirect
                to={{
                  pathname: default_route_if_not_logged_in,
                  state: { from: props.location },
                }}
              />
            </React.Fragment>
          )
        }
      />
    </React.Fragment>
  );
};

export default PrivateRoute;
