

import React from 'react';
import CateoriesBox from './CategoriesBox';
import ProductsBox from './ProductsBox';
import Overview from './Overview';
import MenuProvider from './../../Providers/Menu/MenuProvider';
import './menu-page.css';
import { useHistory } from "react-router-dom";

export const Menu = (props) => {
    // const PATH_ROUTES = ROUTES_PATH;
    let history = useHistory();

    return (
        <React.Fragment>
            <div className="w-layout-grid grid-menu">
                <MenuProvider history={history}>
                    <CateoriesBox />
                    <ProductsBox />
                    <Overview />
                </MenuProvider>
            </div>
        </React.Fragment>
    )
}

export default Menu
