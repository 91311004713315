import React, { Component } from "react";
// import "./order.css";
import AcknowModal from "./acknowModal";
import CreateModal from "./createModal";
import SettingModal from "./settingModal";
import { ICONS } from "../../Helpers/Icon.service";
import OrderContext from "../../Providers/Order/OrderContext";
// import './wrapper.css'

export class OrderTable extends Component {
  static contextType = OrderContext;
  gearIcon = ICONS.SETTINGS_DARK;
  bellIcon = ICONS.BELL_DARK_ICON;
  addTableIcon = ICONS.PLUS_ICON;

  constructor(props) {
    super(props);
    this.state = {
      acknowModal: false,
      displayEditTable: false,
      showCreateDialog: false,
    };

    console.log(props);
  }

  onCloseConfirmationDialog = () => {
    console.log("::onCloseConfirmationDialog::");
    this.setState((previousState) => ({
      acknowModal: !this.state.acknowModal,
    })); // true/false toggle
  };

  onCloseEditDialog = () => {
    console.log("::onCloseEditDialog::");
    this.setState((previousState) => ({
      displayEditTable: !this.state.displayEditTable,
    })); // true/false toggle
  };

  onOrderConfirmed = () => {
    const orderService = this.context;
    const attributesToUpdate = { tableName: this.props.selectedTable.tableName, hasCustomerCall: false};
    orderService.confirmWaiterCall(attributesToUpdate);
    this.onCloseConfirmationDialog();
  };

  customerCallReceived(e, item, index) {
    e.stopPropagation();
    this.onSelectTable(item);
    console.log("::customerCallReceived::");
    
    if (item.hasCustomerCall) {
      this.openConfirmationDialog(item, index);
    }
  }

  openConfirmationDialog = (item, index) => {
    this.setState((previousState) => ({
      acknowModal: !this.state.acknowModal,
    })); // true/false toggle
  };

  toggleCreateTableDialog = () => {
    this.setState((previousState) => ({
      showCreateDialog: !this.state.showCreateDialog,
    })); // true/false toggle
  };

  openEditTableDialog = (item, index) => {
    this.onSelectTable(item);
    this.setState({ displayEditTable: !this.state.displayEditTable }); // true/false toggle
  };

  onSelectTable = (table) => {
    const orderService = this.context;
    orderService.setSelectedTable(table);
    this.props.notificationSeen(table.tableName);
  }

  render() {
    const { tables, selectedTable } = this.props;
    const showConfirmationModal = this.state.acknowModal;
    const showCreateModal = this.state.showCreateDialog;
    const showEditDialog = this.state.displayEditTable;
    return (
      <>
        <div className="w-layout-grid tablesview-2">
          {/* <div className="text-block-219">Tables</div> */}
          {tables &&
            tables.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    af-el="category"
                    onClick={() => this.onSelectTable(item)}
                    className={
                      item.hasCustomerCall ? "tablefreecall" : "tablefree"
                    }
                  >
                    <div
                      data-w-id="f691d9ca-d23b-3872-ea37-cc9c7a586899"
                      className={
                        "table-count " +
                        (item.isOverTime && !item.allOrdersFinished
                          ? "overtimetable"
                          : item.allOrdersFinished || !item.orders.length
                          ? "div-block-696 "
                          : "div-block-696-occu")
                      }
                    >
                      <div className="first">{item.tableName}</div>
                      {item.hasNotification ? (
                        <div className="div-block-828"></div>
                      ) : null}
                      <img
                        src={this.gearIcon}
                        loading="lazy"
                        width="17"
                        height="17"
                        alt="edit"
                        data-w-id="b1f5e562-6cce-ce43-1bf9-72d8f62f1c14"
                        className="image-94 table-edit"
                        onClick={() => {
                          this.openEditTableDialog(item, index);
                        }}
                      />
                    </div>
                    <div
                      className={
                        "div-block-697 " +
                        (item.hasCustomerCall ? "cursor-pointer" : "")
                      }
                      onClick={(e) => {
                        this.customerCallReceived(e, item, index);
                      }}
                    >
                      <img
                        src={this.bellIcon}
                        loading="lazy"
                        width="22"
                        height="22"
                        alt="bell"
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

          {/**-------------Create Table button------------*/}
          <div
            af-el="category"
            data-w-id="d794cf49-a785-6a32-150e-5b4d5f57b779"
            className="create-table"
            data-toggle="modal"
            data-target="#createModal"
            onClick={() => {
              this.toggleCreateTableDialog();
            }}
          >
            <img
              src={this.addTableIcon}
              loading="lazy"
              width="33"
              alt="tableicon"
            />
          </div>

          {/**-------------Create Table Dialog------------*/}
          <CreateModal
            showCreateDialog={showCreateModal}
            closeModal={this.toggleCreateTableDialog}
            tables={tables}
          />

          {/**-------------Edit Table Dialog------------*/}
          {selectedTable && (
            <SettingModal
              tables={tables}
              selectedTable={selectedTable}
              displayEditTable={showEditDialog}
              closeModal={this.onCloseEditDialog}
            />
          )}

          {/**-------------Customer Call recieved Dialog ------------*/}
          {
            <AcknowModal
              displayModal={showConfirmationModal}
              closeModal={this.onCloseConfirmationDialog}
              onConfirmation={this.onOrderConfirmed}
            />
          }
        </div>
      </>
    );
  }
}

const withContext = (Component) => {
  return (props) => {
    return (
      <OrderContext.Consumer>
        {(context) => {
          return <Component {...props} context={context} />;
        }}
      </OrderContext.Consumer>
    );
  };
};

export default withContext(OrderTable);
