
import { APP_CONFIG } from '../../constants/Config';
const imgPath = APP_CONFIG.IMAGE_PATH;
export const topSellerdata = [
    { name: "Muay Thay Dish", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 148, img: `${imgPath}/aigars-peda-HEG9RhlLKTY-unsplash.jpg` },
    { name: "Casanova Salad", reccurance: false, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 78, img: `${imgPath}/andrea-riezzo-1hKZ0A182Bk-unsplash.jpg` },
    { name: "Fishermanns Friend", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 55, img: `${imgPath}/louis-hansel-shotsoflouis-6vNYg621xCM-unsplash.jpg` },
    { name: "Fatburner Sandwich", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 34, img: `${imgPath}/joseph-gonzalez-zcUgjyqEwe8-unsplash.jpg` },
    { name: "Fatburner", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 21, img: `${imgPath}/louis-hansel-shotsoflouis-mVZ_gjm_TOk-unsplash.jpg` },
    { name: "Casanova Salad", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 13, img: `${imgPath}/cristina-matos-albers-Ltv7a5m8i4c-unsplash.jpg` },
    { name: "Ayaflatlayükütl", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 12, img: `${imgPath}/ben-kolde-FFqNATH27EM-unsplash.jpg` },
    { name: "Product Without Pic", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "3/1/2021", endDate: "3/10/2021", totalSelled: 12},
    { name: "Very Long Item Name 123 KNdds54", reccurance: true, weekAvailablity: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], startDate: "", endDate: "", totalSelled: 12, img: `${imgPath}/ben-kolde-FFqNATH27EM-unsplash.jpg` },

]

export const todayData = [65, 59, 80, 81, 56, 34, 67, 89, 34, 0, 0, 90];
export const weekData = [23, 63, 43, 54, 85, 73, 44, 23, 92, 23, 3, 83];

export const whatsNew = "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,no sea takimata sanctus est Lorem ipsum dolor sit amet.";
export const wishesRealised = 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.';