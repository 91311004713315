import React from "react";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

export const Header = (props) => {
    console.log("---------------Header-------------------")
    return (
        <>
            <NavbarMobile />
            <NavbarDesktop />
        </>
    )
}

export default Header