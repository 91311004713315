
import React from 'react';
import { Trans } from 'react-i18next';
import { APP_CONFIG } from "../../constants/Config";


export const DeleteConfirmation = (props) => {
    // const PATH_ROUTES = ROUTES_PATH;

    const confirmDelete = () => {
        props.action('yes');
    }

    const cancelDelete = () => {
        props.action('no');
    }

    const getStyle = () => {
        let styles = {};
        if (props.show) {
            styles = { display: 'flex' }
        } else {
            styles = { display: 'none' }
        }
        return styles;
    }


    // style={getStyle()}
    return (
        <React.Fragment>
            <div className="deletedialog" style={getStyle()}>
                <div className="div-block-595">
                    <div className="text-block-147"><Trans>shared.actions.delete</Trans></div>
                    <a data-w-id="cb8fd21f-bf42-b201-607c-e8447feed272" className="button-36 w-button" onClick={cancelDelete}><Trans>shared.actions.no</Trans></a>
                    <a className="button-37 w-button" onClick={confirmDelete}><Trans>shared.actions.yes</Trans></a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DeleteConfirmation
