import React, { useContext } from "react";
import Timer from "react-compound-timer";
import {transformDate } from "../../Helpers/Utils";
import CurrencyFormatter from "../../SharedComponents/Currency";

import OrderContext from "../../Providers/Order/OrderContext";
import OrderSingleItem from "./OrderSingleItem";

const OrderItems = (props) => {

  const timeformat = "HH:mm - DD.MM.YYYY";
  
  const secondsInHour = 3600;
  // const secondsInMinute = 60;
  const orderService = useContext(OrderContext);



  const { order } = props;

  const orderCompleted = (order) => {
    console.log("-----------orderCompleted-----");
    const orderCompletedTimeStamp = new Date().getTime();
    // Make API call to update Order Attributes
    console.log(order);
    // const orderService = this.context;
    orderService.completeOrder(order, orderCompletedTimeStamp);
  };

  // checkIfSingleOrderCompleted

  // checkIfAllItemsCooked

  const timerFormatter = (value) => {
    return `${value < 10 ? `0${value}` : value}:`;
  };

  const secondsFormatter = (value) => {
    return timerFormatter(value).toString().replace(":", "");
  };

  const orderCountdownTimer = (order, timerActions) => {
    if (order.isCompleted && !order.orderCompletionTime) {
      timerActions.stop();
    }
    return (
      <div className="timefororder" timer={order.orderTimer()}>
        <React.Fragment>
          {order.orderTimer() / 1000 > secondsInHour && (
            <Timer.Hours formatValue={timerFormatter} />
          )}
          <Timer.Minutes formatValue={timerFormatter} />
          <Timer.Seconds formatValue={secondsFormatter} />
        </React.Fragment>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Timer
        startImmediately={!order.isCompleted && !order.orderCompletionTime}
        onStop={() => orderCompleted(order)}
        lastUnit="h"
        initialTime={order.orderTimer()}
      >
        {({ start, resume, pause, stop, reset, getTimerState, getTime }) => (
          <div className="div-block-693">
            {/*  <div className="ordernumber">{order.orderId}</div> */}

            {order.orderItems &&
              order.orderItems.map((orderItem, OItemIndex) => {
                return (
                    <React.Fragment
                    key={orderItem.timestampStart + "" + OItemIndex}
                  >
                      <OrderSingleItem orderItem={orderItem} order={order} timerFormatter={timerFormatter} secondsFormatter={secondsFormatter} />
                  </React.Fragment>
                );
              })}
            <div className="div-block-1007">
              <div className="text-block-466">
                {order.orderId.toUpperCase()}
              </div>
              <div className="howmuch">
                {transformDate(order.bookingTime, timeformat)}/
                <CurrencyFormatter price={order.orderTotal} />
              </div>

              {orderCountdownTimer(order, {
                stop,
                getTimerState,
                getTime,
              })}
            </div>
          </div>
        )}
      </Timer>
    </React.Fragment>
  );
};

export default OrderItems;
