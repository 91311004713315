
import React from 'react';
import './404.css';
import { Link } from 'react-router-dom';
import { APP_CONFIG } from '../../../constants/Config';
import { ROUTES_PATH } from "../../../constants/Routes";
import { ICONS } from '../../../Helpers/Icon.service';


const NoMatch = () => {
    const PATH_ROUTES = ROUTES_PATH;
    const logoImage = ICONS.LOGO_ICON;

    return (
        <>
            <div className="body-6">
                <div className="utility-page-wrap">
                    <div className="utility-page-content w-form">
                        <img src={logoImage} width="133" />
                        <h2 className="heading-2">Page Not Found</h2>
                        <div className="div-block-591">The page you are looking for doesn&#x27;t exist or has been moved.</div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default NoMatch