import React, { useContext, useEffect, useState } from "react";
import { APP_CONFIG } from "../../../constants/Config";
import { ICONS } from "../../../Helpers/Icon.service";
import ProfileContext from "../../../Providers/Profile/ProfileContext";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { PaypalIntegration } from "./paypal";
import LoadingIndicator from "../../../SharedComponents/Loading";
import ActivateDeactivateConfirm from "../../../SharedComponents/ActivateDeactivateConfirm";
import { useHistory, useLocation } from "react-router-dom";
import { LOCALSTORAGE_KEYS } from "../../../constants/app.constant";
import ErrorMessage from "../../../SharedComponents/ErrorMessage";
import { ROUTES, ROUTES_PATH } from '../../../constants/Routes';

const PaypalInfo = () => {
  let history = useHistory();
  const PATH_ROUTES = ROUTES_PATH;
  const [paypalUrl, setPaypalUrl] = useState("");
  const [displayLoader, setDisplayLoader] = useState(false);
  // const [isMerchantIdAvailable, setMerchantIdAvailable] = useState(false);
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const [paypalLoading, setPaypalLoading] = useState({
    isLoading: true,
    error: false,
    errorMessage: "",
  });
  const profileService = useContext(ProfileContext);
  const { t } = useTranslation();
  const { paypalInfo } = profileService;
  const imgPath = APP_CONFIG.IMAGE_PATH;
  const settingIcon = ICONS.SETTINGS_DARK;
  const paypalActiveIcon = ICONS.PAYPAL_COLORED_ICON;
  const paypalInactiveIcon = ICONS.PAYPAL_ICON;
  const search = useLocation().search;

  const dialogContent = {
    message: "pages.profile.payment.paypalDeactiveText",
    yesText: "shared.actions.deactivate",
    noText: "shared.actions.cancel",
  };

  const [editForm, setEditform] = useState(false);
  /* const [paypalForm, setPaypalForm] = useState({
        paypalId: paypalInfo?.paypalId || ''
    }); */
  // console.log("sd", paypalInfo, profileService)
 /*  const [activationStatus, setActivationStatus] = useState(
    paypalInfo?.isActive || false
  ); */

  useEffect(() => {
    if (!profileService.isPageLoaded || paypalInfo.paypalId) {
      return;
    }
    
    const merchantIdInPayPal = new URLSearchParams(search).get(
        "merchantIdInPayPal"
      ),
      merchantId = new URLSearchParams(search).get("merchantId"),
      accountStatus = new URLSearchParams(search).get(
        "accountStatus"
      ),
      permissionsGranted = new URLSearchParams(search).get(
        "permissionsGranted"
      ),
      isEmailConfirmed = new URLSearchParams(search).get(
        "isEmailConfirmed"
      );

    async function fetchsellerCredentials() {
      // setMerchantIdAvailable(true);
      const credentials = JSON.parse(
        localStorage.getItem(LOCALSTORAGE_KEYS.paypayl_seller)
      );

      if (!credentials || !credentials.access_token) {
        return fetchPaypalLink();
      }
      const secrets_keys = await PaypalIntegration.getSellerCredentials(
        credentials.access_token,
        merchantIdInPayPal
      );
      console.info(secrets_keys);
      updatePaypayClientSecrets(secrets_keys, merchantId, accountStatus);
    }

    if (merchantIdInPayPal) {
      // alert("merchantIdInPayPal");
      fetchsellerCredentials();
      return;
    } else {

      console.info("/////////////");
      fetchPaypalLink();
    }
  }, [profileService.isPageLoaded, paypalInfo.paypalId]);

  const fetchPaypalLink = async () => {
    try {
      let paypalInstance = new PaypalIntegration();
      const actionUrl = await paypalInstance.generatetPaypalAuthlink();
      console.info(">>>>>>>>>>>>>>>>>>>>>>>", actionUrl);
      setPaypalUrl(actionUrl);
      setPaypalLoading({ isLoading: false, error: false, errorMessage: "" });
    } catch (e) {
      console.error(e);
      const errorMessage = e.message || "shared.messages.genericErrorText";
      setPaypalLoading({
        isLoading: false,
        error: true,
        errorMessage: errorMessage,
      });
    }
  };

  const retryFetchPaypal = () => {
    setPaypalLoading({ isLoading: true, error: false, errorMessage: "" });
    fetchPaypalLink();
  };

  const updatePaypayClientSecrets = (client_secrets, merchantId, accountStatus) => {
    setDisplayLoader(true);
    profileService
      .updatePaymentInfo({
        informationType: "PAYMENTS",
        paypal: {
          paypalId: client_secrets.client_id,
          paypalSecret: client_secrets.client_secret,
          merchantId: merchantId,
          accountStatus: accountStatus,
          isActive: true,
        }
      })
      .then((res) => {
        console.info(res);
        setDisplayLoader(false);
        history.push(PATH_ROUTES.PAYMENT);
          
      });
  };

  const cancelEdit = () => {
    setEditform(false);
  };

  // TODO : Make sure when this is disabled then enable cash or display error messsage to enable the other one
  const onUnlinkPaypalAccount = () => {
    showConfirmDialog();
  };

  const hideConfirmDialog = () => {
    setDisplayConfirmDialog(false);
  };

  const showConfirmDialog = () => {
    setDisplayConfirmDialog(true);
  };

  const unlinkPaypalAccount = () => {
    // show loader
    setDisplayLoader(true);
    profileService
      .updatePaymentInfo({
        informationType: "PAYMENTS",
        paypal: {
          paypalId: "",
          paypalSecret: "",
          merchantId: "",
          accountStatus: "",
          isActive: false,
        },
      })
      .then((res) => {
        
        console.log(res);
        cancelEdit();
        setDisplayLoader(false);
        // Reload Page to resolbe Paypal new Tab bug
        window.location.reload();
        // hide loader
      });
  };

  const getUserAction = (decision) => {
    if (decision === "yes") {
      unlinkPaypalAccount();
      hideConfirmDialog();
    } else {
      hideConfirmDialog();
      cancelEdit();
    }
  };

  const toggleActivationStatus = () => {
    // paypalDetails.isActive = !paypalDetails.isActive;
    // setActivationStatus(paypalInfo.isActive);
    // console.log(paypalInfo.isActive, activationStatus);
    setDisplayLoader(true);
    profileService
      .updatePaymentInfo({
        informationType: "PAYMENTS",
        paypal: {
          paypalId: paypalInfo.paypalId,
          paypalSecret: paypalInfo.paypalSecret,
          merchantId: paypalInfo.merchantId,
          accountStatus: paypalInfo.accountStatus,
          isActive: !paypalInfo.isActive,
        },
      })
      .then((res) => {
        console.info(res);
        setDisplayLoader(false);
      });
  };

  const createPaypalLoginLink = () => {
    if (!profileService.isPageLoaded || paypalInfo.paypalId) {
      return null;
    }

    if (paypalLoading.error) {
      return (
        <React.Fragment>
          <ErrorMessage text={t(paypalLoading?.errorMessage)} />
          <button
            type="button"
            data-wait="Please wait..."
            className="unlink_button w-button"
            onClick={retryFetchPaypal}
          >
            <Trans>shared.actions.retry</Trans>
          </button>
        </React.Fragment>
      );
    }

    const getButtonStyle = () => {
      return paypalLoading.isLoading ? { pointerEvents: "none" } : {};
    };

    const getSpinner = () => {
      return paypalLoading.isLoading ? (
        <div className="spinner loading"></div>
      ) : null;
    };

    return (
      <React.Fragment>
        <a
          style={getButtonStyle()}
          className="paypal-login-btn text-block-393"
          target="PPFrame"
          data-paypal-onboard-complete="onboardedCallback"
          href={`${paypalUrl}&displayMode=minibrowser`}
          data-paypal-button="true"
        >
          <span className="paypaly-logo-white"></span>
          <span className="pp-login-text">
            <Trans>pages.profile.payment.paypalLoginText</Trans>
          </span>
          {getSpinner()}
        </a>
        <div className="text-block-391">
          <Trans>pages.profile.payment.paypalSignupHelpText</Trans>
        </div>
      </React.Fragment>
    );
  };

  const getActiveStatusText = () => {
    if (editForm || !paypalInfo?.paypalId) return null;

    return (
      <div className="activenow" onClick={() => toggleActivationStatus()}>
        {paypalInfo.isActive && (
          <span>
            <Trans>shared.actions.deactivate</Trans>
          </span>
        )}
        {!paypalInfo.isActive && (
          <span>
            <Trans>shared.actions.activate</Trans>
          </span>
        )}
      </div>
    );
  };

  const displayPaypalId = () => {
    return <React.Fragment>
      <div className="text-block-391">
          <b>Email: </b> {paypalInfo?.merchantId} <br />
          <b>Account: </b> {paypalInfo?.accountStatus}
      </div>
    </React.Fragment>

    /* const len = paypalInfo.paypalId.length;
    const initialCharLength = 5;
    const endCharLength = 8;
    const string_to_replace = paypalInfo.paypalId.substring(
      initialCharLength,
      len - endCharLength
    );
    // console.info(string_to_replace);
    // return paypalInfo.paypalId.replace(string_to_replace, '*')
    // return string_to_replace.replace(/[^\s]/g, "*");
    return (
      paypalInfo.paypalId.substr(0, initialCharLength) +
      string_to_replace.replace(/[\S]/g, "*") +
      paypalInfo.paypalId.substr(len - endCharLength, len)
    ); */
  };

  const getStatusIndicator = () => {
    console.info("{{{{{{{{}}}}}}}", paypalInfo.isActive);

    if (!paypalInfo.paypalId) return;
        const paypalIcon =
        paypalInfo.paypalId && paypalInfo.isActive ? paypalActiveIcon : paypalInactiveIcon ;
      // return <div className={"status-indicator " + indicatorClass}></div>;

     return <img
          src={paypalIcon}
          loading="lazy"
          width="55"
          height="55"
          alt=""
          className="image-194"
        />
    };

  if (!profileService.isPageLoaded || displayLoader) {
    return (
      <div className="data-content-section">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div
      id="w-node-_4bf73f05-c81c-3073-ec63-3059bb1d586c-fe54d577"
      className="paypalblock"
    >
      {getStatusIndicator()}
      
      {paypalInfo?.paypalId ? (
        <img
          src={settingIcon}
          loading="lazy"
          width="17"
          height="17"
          onClick={() => setEditform(!editForm)}
          data-w-id="4bf73f05-c81c-3073-ec63-3059bb1d586d"
          alt=""
          className="image-128"
        />
      ) : (
        ""
      )}
      {!editForm ? (
        <div className="div-block-951">
          <div className="text-block-390">
            <Trans>pages.profile.payment.paypal</Trans>
          </div>
          {paypalInfo?.paypalId && (
            <div className="text-block-393">
              <Trans>pages.profile.payment.activeAccount</Trans>
            </div>
          )}
          {
            paypalInfo?.paypalId ? displayPaypalId() : null
          }
        </div>
      ) : (
        <div className="div-block-952">
          <input
            type="button"
            value={t("pages.profile.payment.unlinkAccount")}
            data-wait="Please wait..."
            onClick={onUnlinkPaypalAccount}
            className="unlink_button w-button"
          />
          <div
            onClick={cancelEdit}
            data-w-id="4bf73f05-c81c-3073-ec63-3059bb1d588f"
            className="close_paypal_settings"
          >
            <Trans>shared.actions.cancel</Trans>
          </div>
        </div>
      )}

      {getActiveStatusText()}
      <ActivateDeactivateConfirm
        show={displayConfirmDialog}
        dialogContent={dialogContent}
        action={getUserAction}
      />

      <div dir="ltr" style={{ textAlign: "center" }} trbidi="on">
        {(function (d, s, id) {
          let js,
            ref = d.getElementsByTagName(s)[0];
          if (!d.getElementById(id)) {
            js = d.createElement(s);
            js.id = id;
            js.async = true;
            js.src = process.env.REACT_APP_MERCHANT_ONBOARD_JS;
            ref.parentNode.insertBefore(js, ref);
          }
        })(document, "script", "paypal-js")}
        {createPaypalLoginLink()}
      </div>
    </div>
  );
};

export default PaypalInfo;
