
import React from 'react';
import { Trans } from 'react-i18next';
import { ICONS } from '../../Helpers/Icon.service';

const ConfirmDeleteTable = (props) => {

    const deleteIcon = ICONS.BAR_CODE_TILTED;

    const confirmDelete = () => {
        props.action('yes');
    }

    const cancelDelete = () => {
        props.action('no');
    }

    const getStyle = () => {
        let styles = {};
        if(props.show) {
            styles = { display: 'flex' }
        } else {
            styles = { display: 'none' }
        }
        return styles;
    }

    return (
        <React.Fragment>
            <div className="tableoptions" style={getStyle()}>
                <div className="div-block-830" style={getStyle()}>
                    <img src={deleteIcon} loading="lazy" width="33" height="33" alt="" className="image-101 cursor-pointer" />
                    <div className="text-block-468"><Trans>pages.order.tableDeleteConfrmation</Trans></div>
                    <div className="div-block-838" onClick={confirmDelete}>
                        <div className="text-block-318"><Trans>shared.actions.sure</Trans></div>
                    </div>
                    <div data-w-id="ded8f71b-a5f8-ec69-755f-71ae3402c907" onClick={cancelDelete} className="deletetabletoggle">
                        <div className="text-block-318"><Trans>shared.actions.no</Trans></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ConfirmDeleteTable