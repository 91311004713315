import React, { Component } from "react";
import { ICONS } from "../../Helpers/Icon.service";
import ConfirmDeleteTable from "./ConfirmDeleteTable";
import OrderContext from "../../Providers/Order/OrderContext";
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

class SettingModal extends Component {
  static contextType = OrderContext;
  t = this.props.t;

  closeIcon = ICONS.CLOSE_RED_ICON;
  trashIcon = ICONS.DELETE_ICON;
  qrCodeIcon = ICONS.QR_CODE_DARK_ICON;

  constructor(props) {
    super(props);
    this.divStyle = {
      display: this.props.displayEditTable ? 'show' : ''
    };
    this.state = {
      tableName: '',
      showError: false,
      submitted: false,
      alreadyExist: false,
      showDelete: false
    };
  }


  componentDidMount() {
    //console.log(this.props.selectedTable);
    if (this.props.selectedTable) {
      this.setState({
        tableName: this.props.selectedTable.tableName,
      })
    }

  }

  componentWillReceiveProps(nextProps) {
    this.setState({ tableName: nextProps.selectedTable.tableName })
  }


  componentDidUpdate(oldProps) {
    /* const newProps = this.props
    if(newProps.selectedTable) {
        this.setState({
          tableName: this.props.selectedTable.tableName,
        })
    } */
  }

  checkExistence = (name) => {
    let tables = this.props.tables;
    let selectedTable = this.props.selectedTable;
    let exist = false;
    tables.forEach(table => {
      if (table.tableName === name && selectedTable.id !== table.id) {
        exist = true;
      }
    });

    if (exist) {
      this.setState({
        alreadyExist: true
      })
    } else {
      this.setState({
        alreadyExist: false
      })
    }

    return exist;

  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { tableName } = this.state;
    if (tableName && !this.checkExistence(tableName)) {
      let tableObject = { ...this.props.selectedTable, tableName: tableName };
      tableObject['tableNameOld'] = this.props.selectedTable.tableName;
      tableObject['tableNameNew'] = tableObject.tableName;
      console.log("::RenameTable::");

      const allOrderPaidAndFinished = this.props.selectedTable.orders.every(order => order.isCooked && order.isPaid );
      if(!allOrderPaidAndFinished) {
        alert("All Orders for this table not finished");
        return ;
      }

      this.onRenameOrderTable(tableObject);
      this.resetForm();
      this.closeModal(event);
    }
  }

  onRenameOrderTable = (tableObject) => {
    console.log("::onRenameOrderTable::", tableObject);
    const orderService = this.context;
    orderService.updateTableApi(tableObject);
    // updateTableApi
  };

  resetForm = () => {
    this.setState({
      tableName: '',
      showError: false,
      submitted: false,
      alreadyExist: false,
      showDelete: false
    })
  }

  closeModal(e) {
    if(e) {
      e.stopPropagation()
    }
    this.props.closeModal()
  }


  deleteTable = (e) => {
    this.onDeleteOrderTable(this.props.selectedTable);
    this.closeModal(e);
  }

  onDeleteOrderTable = (tableObject) => {
    console.log("::onDeleteOrderTable::", tableObject);
    const orderService = this.context;
    orderService.deleteTableApi(tableObject).then(res => {
    });

  };

  askConfirmation = () => {
    this.setState({showDelete: true});
  }

  isDeleteConfirmed = (action) => {
    console.log(action);
    (action === 'yes' ) ? this.deleteTable() : this.setState({showDelete: false});
  }

  onDownloadQR = () => {
    console.log("onDownloadQR");
    const orderService = this.context; 
    orderService.createDownloadQRCode(this.props.selectedTable.tableName);
  }


  render() {
    // const selectedTable = this.props.selectedTable;
    // console.log(selectedTable);
    const isOpen = this.props.displayEditTable;
    const { tableName, submitted, alreadyExist, showDelete } = this.state;
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.divStyle.display = this.props.displayEditTable ? 'show' : '';

    return (
      <React.Fragment>
        
       <div className="tableoptions" style={isOpen ? { display: 'flex' } : { display: 'none' }}>
          <div className="tableoptionstoggle">
            <div className="form-block-39 w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form-134"
                onSubmit={(event) => this.handleSubmit(event)} noValidate >
                <input
                      type="text"
                      className="text-field-173 w-input"
                      maxLength={3}
                      minLength={3}
                      name="rename-table"
                      placeholder={this.t('pages.order.placeHoldRename')}
                      id="rename-table"
                      value={this.state.tableName}
                      onChange={event => this.setState({ tableName: event.target.value })}
                      required
                      autoFocus
                    />
                <button className="submit-button-23 w-button" type="submit"><Trans>pages.order.rename</Trans></button>     

              </form>

                {submitted && !tableName && <div style={{display: 'block'}} className="error-message-6 w-form-fail">
                  <div className="text-danger"><Trans>pages.order.tableNameError</Trans></div>
                </div>}
                {alreadyExist && tableName && <div style={{display: 'block'}} className="error-message-6 w-form-fail">
                  <div className="text-danger"><Trans>pages.order.tableNameUsedError</Trans></div>
                </div>}

            </div>
            <div className="div-block-838" onClick={this.onDownloadQR}>
              <img src={this.qrCodeIcon} loading="lazy" width="33" height="33" alt=""  />
              <div className="text-block-318"><Trans>pages.order.downloadQRText</Trans></div>
            </div>
            <div data-w-id="6dcff18c-3c8d-a209-cfbe-8ca27cf4c795" className="deletetabletoggle" onClick={(e) => { this.askConfirmation() }} >
              <img src={this.trashIcon} loading="lazy" width="33" height="33" alt="" />
              <div className="text-block-318"><Trans>pages.order.deleteTable</Trans></div>
            </div>
          </div>
          <ConfirmDeleteTable show={showDelete} action={(action) => this.isDeleteConfirmed(action)} />    
          <img src={this.closeIcon} loading="lazy" width="33" height="33" 
            data-w-id="aa0fedea-46d7-2129-d24d-63c6e63fdd2a" className="image-95 cursor-pointer" onClick={(e) => { this.closeModal(e) }} alt="close" />
        </div>
        <div id="qrCodeCanvas"></div>


      </React.Fragment>




    );
  }
}


export default withTranslation()(SettingModal);