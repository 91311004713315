import React, { useContext, useEffect } from 'react';
import SettingsTopHeader from '../settings/SettingsTopHeader';
import LanguageInfo from './LanguageInfo';
import BusinessInfo from './BusinessInfo';
import ProfileContext from '../../../Providers/Profile/ProfileContext';
import AdminInfo from './AdminInfo';
import StylingInfo from './StylingInfo';
import OpeningTimings from './Timings';

const Settings = ({ profileInfo, ...rest }) => {
    const profileService = useContext(ProfileContext);

    const updateLanguageInfo = (langaugeInfo) => {
        profileInfo.langaugeInfo = { ...langaugeInfo };
        rest.updateInfo(profileInfo);
    }

    const updateAdminInfo = (adminInfo) => {
        const tempProfileInfo = { ...profileInfo };
        tempProfileInfo.personalAddress = { ...adminInfo };
        rest.updateInfo({ ...tempProfileInfo });
    }

    const updateBusinessInfo = (businessInfo) => {
        const tempProfileInfo = { ...profileInfo };
        tempProfileInfo.businessInfo = { ...businessInfo };
        rest.updateInfo({ ...tempProfileInfo });
    }


    return (
        <div data-w-tab="Settings" className="mybusinesstab">
            <div className="grid-26">
                <SettingsTopHeader
                    profileInfo={profileInfo}
                    businessInfo={profileInfo.businessInfo}
                    languageInfo={profileInfo.languageInfo}
                    personalAddress={profileInfo.personalAddress}
                    paypalDetails={profileInfo.paypalAccount} />
                <AdminInfo address={profileInfo.personalAddress} updateInfo={updateAdminInfo} />
                <BusinessInfo address={profileInfo.businessInfo} updateInfo={updateBusinessInfo} />
                <OpeningTimings />
                <LanguageInfo language={profileInfo.languageInfo} translate={profileInfo.translatingInfo} updateInfo={updateLanguageInfo} />
                {/* <StylingInfo styleInfo={profileInfo.stylingInfo} /> */}
            </div>
        </div>
    )
}

export default Settings;