import React, { Component } from "react";
import { ICONS } from "../../Helpers/Icon.service";
import OrderContext from "../../Providers/Order/OrderContext";
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { withTranslation } from "react-i18next";


export class createModal extends Component {
  static contextType = OrderContext;
  t = this.props.t;
  closeIcon = ICONS.CLOSE_ICON;
  tableIcon = ICONS.TABLE_ICON;

  constructor(props) {
    super(props);
    this.state = {
      tableName: "",
      showError: false,
      submitted: false,
      alreadyExist: false,
    };
  }

  /* componentDidMount() {
    const orderService = this.context;
    console.log(orderService);

  }  
 */
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { tableName } = this.state;
    if (tableName && !this.checkExistence(tableName)) {
      console.log("submit Form");
      this.onAddTable(tableName);
      this.resetForm();
      this.closeModal(event);
      // document.getElementById('dismissAddTable').click();
    }
  };

  checkExistence = (name) => {
    let tables = this.props.tables;
    let exist = false;
    tables.forEach((table) => {
      if (table.tableName === name) {
        exist = true;
      }
    });

    if (exist) {
      this.setState({
        alreadyExist: true,
      });
    } else {
      this.setState({
        alreadyExist: false,
      });
    }

    return exist;
  };

  resetForm = () => {
    this.setState({
      tableName: "",
      showError: false,
      submitted: false,
      alreadyExist: false,
    });
  };

  closeModal(e) {
    e.stopPropagation();
    this.props.closeModal();
  }

  onAddTable = (tableName) => {
    console.log("::onAddTable::", tableName);
    const orderService = this.context;
    orderService.createTableApi({ tableName });
  };

  render() {
    const { tableName, submitted, alreadyExist } = this.state;
    const isOpen = this.props.showCreateDialog;

    return (
      <React.Fragment>
        <div
          className="create-table-dialog"
          style={isOpen ? { display: "flex" } : { display: "none" }}
        >
          <div
            className="createnewtable "
            style={isOpen ? { display: "flex" } : { display: "none" }}
          >
           {/*  <div className="text-block-316">Create New Tables</div> */}
            <div className="form-block-40 w-form">
            <div className="text-block-506"><Trans>pages.order.table</Trans></div>
            <img
              src={this.closeIcon}
              loading="lazy"
              width="22"
              height="22"
              data-w-id="33172d72-ceb0-b803-7107-94d7813f2cd6"
              alt=""
              className="image-97 cursor-pointer"
              onClick={(e) => this.closeModal(e)}
            />
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                className="form-133 "
                onSubmit={(event) => this.handleSubmit(event)}
                noValidate
              >
                

                <div className="onetable_name">
                  <img src={this.tableIcon} loading="lazy" width="33" height="33" alt="" />
                  <input
                  type="text"
                  className="text-field-174 w-input"
                  maxLength={3}
                  minLength={1}
                  name="create-table"
                  placeholder={this.t('pages.order.placeHoldName')}
                  id="create-table"
                  value={this.state.tableName}
                  onChange={(event) =>
                    this.setState({ tableName: event.target.value })
                  }
                  required
                  autoFocus
                />
                    </div>

                <button type="submit" className="submit-button-22 w-button">
                <Trans>pages.order.create</Trans>
                </button>
              </form>
              {submitted && !tableName && (
                <div
                  className="error-message-7 w-form-fail"
                  style={
                    !tableName ? { display: "block" } : { display: "none" }
                  }
                >
                  <div className=""><Trans>pages.order.tableNameError</Trans></div>
                </div>
              )}
              {alreadyExist && tableName && (
                <div
                  style={tableName ? { display: "block" } : { display: "none" }}
                  className="error-message-7 w-form-fail"
                >
                  <div className="text-danger"><Trans>pages.order.tableNameUsedError</Trans></div>
                </div>
              )}

            </div>
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(createModal);
