import React, { useContext, useEffect, useState } from 'react';
import ProfileContext from '../../../Providers/Profile/ProfileContext';
import { APP_CONFIG } from '../../../constants/Config';
import { ICONS } from '../../../Helpers/Icon.service';
import LoadingIndicator from '../../../SharedComponents/Loading';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const LanguageInfo = ({  ...rest }) => {
    const profileService = useContext(ProfileContext);
    const {languageInfo} = profileService;
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const settingsIcon = ICONS.SETTINGS_DARK;
    const globeIcon = ICONS.GLOBE_ICON;
    const [editForm, setEditform] = useState(false);
    const { t, i18n } = useTranslation();

    const [langaugeForm, updateForm] = useState({
        languageAdmin: '',
        timeFormat: '',
        currency: ' '

    })

    const handleInputChange = (e, type) => {
        console.log("dsdsds", e.target.value)
        let tempForm = { ...langaugeForm };
        let value = e.target.value;
        tempForm[type] = value;
        updateForm(tempForm)
    }

    const formValidation = (langaugeForm) => {
        let tempLanguageForm = { ...langaugeForm };
        let valid = true;
        let tempValid = false;
        let adminProps = Object.entries(langaugeForm);
        adminProps.map(([key, value]) => {
            let result = [false, tempLanguageForm];
            if (key.includes('Error')) {
                return [key, value];
            }
            else {
                result[0] = value == '' || value == undefined ? false : true;
                result[1][`${key}Error`] = `${key} required`;

            }
            console.log("valuss", result)
            tempValid = result[0];
            tempLanguageForm = result[1];
            if (valid && !tempValid) {
                valid = tempValid;
            }
            return [key, value];
        });
        updateForm({ ...tempLanguageForm });
        return valid;
    }

    const updateLanguage = (e) => {
        e.preventDefault();
        let result = formValidation(langaugeForm);
        console.log("result", result)
        if(result){
            profileService.updateLanguegeInfo({
                "informationType" : "LANGUAGE", 
                "languageAdmin": langaugeForm.languageAdmin,
                "timeFormat": langaugeForm.timeFormat,
                "currency": langaugeForm.currency
            })
            setEditform(false);
        }
    }

    const handleFormDisplay = () => {
        updateForm({
            ...languageInfo
        });
        setEditform(!editForm);
    }

    useEffect(() => {
        updateForm({
            ...languageInfo
        })
    }, [])

    if(!profileService.isPageLoaded ){
        return <div className="data-content-section"><LoadingIndicator /></div>;
    }
    console.log("ssasasa", langaugeForm)
    return (
        <div id="w-node-_066c1521-ce2d-2c15-32de-1cac97504fab-fe54d577" className="div-block-667 data-content-section">
            <img src={settingsIcon} loading="lazy" width="17" height="17" onClick={handleFormDisplay}
                data-w-id="066c1521-ce2d-2c15-32de-1cac97504fac" alt="" className="image-128" />
                <img src={globeIcon} loading="lazy" width="55" height="55" alt="" className="image-194"></img>
            {!editForm ? <div className="div-block-951">
            <div className="text-block-390"><Trans>pages.profile.languageRegion.title</Trans> </div>
              <div className="text-block-393"><Trans>pages.profile.languageRegion.langauge</Trans></div>
              <div className="text-block-391">{languageInfo.languageAdmin}<br/></div>
              <div className="text-block-393"><Trans>pages.profile.languageRegion.timeDate</Trans></div>
              <div className="text-block-391">{languageInfo.timeFormat}<br/></div>
              <div className="text-block-391"><Trans>pages.profile.languageRegion.auto</Trans><br/></div>
              <div className="text-block-393"><Trans>pages.profile.languageRegion.currency</Trans></div>
              <div className="text-block-391">{languageInfo.currency}</div>
            </div> :
                <div className=" w-form">
                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2" className="form-138">
                <select id="field-2" value={langaugeForm.languageAdmin} onChange={e => handleInputChange(e, 'languageAdmin')} name="field-2" className="select-field-3 w-select">
                  <option value="">Language (auto)</option>
                  <option value="English">English</option>
                  <option value="Deutsch">Deutsch</option>
                  <option value="Pycck">Pycck</option>
                  <option value="Arab">Arab</option>
                </select>
                {langaugeForm.languageAdminError ? <p className='validationsError'>*{langaugeForm.languageAdminError}</p> : null}
                <select id="field-2" value={langaugeForm.timeFormat} onChange={e => handleInputChange(e, 'timeFormat')} name="field-2" className="select-field-3 w-select">
                  <option value="">Time Format (auto)</option>
                  <option value="24h (21:41)">24h (21:41)</option>
                  <option value="AM/PM (09:41 PM)">AM/PM (09:41 PM)</option>
                </select>
                {langaugeForm.timeFormatError ? <p className='validationsError'>*{langaugeForm.timeFormatError}</p> : null}
                <select id="field-2" value={langaugeForm.dateFormat} onChange={e => handleInputChange(e, 'dateFormat')} name="field-2" className="select-field-3 w-select">
                  <option value="">Date Format (auto)</option>
                  <option value="First">D/M/Y</option>
                  <option value="Second">M/D/Y</option>
                </select>
                {langaugeForm.dateFormatError ? <p className='validationsError'>*{langaugeForm.dateFormatError}</p> : null}
                <select id="field-2" value={langaugeForm.currency} onChange={e => handleInputChange(e, 'currency')} name="field-2" className="select-field-3 w-select">
                  <option value="">Display Currency (auto)</option>
                  <option value="EUR €">EUR €</option>
                  <option value="USD $">USD $</option>
                  <option value="RUB ₽">RUB ₽</option>
                  <option value="TL ₺">TL ₺</option>
                  <option value="INR ₹">INR ₹</option>
                </select>
                {langaugeForm.currencyError ? <p className='validationsError'>*{langaugeForm.currencyError}</p> : null}
                        <div className="div-block-952">
                            <div data-w-id="066c1521-ce2d-2c15-32de-1cac97504fd0" className="closepersonal" onClick={handleFormDisplay}><Trans>shared.actions.cancel</Trans></div>
                            <input type="submit" value={t('shared.actions.save')} data-wait="Please wait..." onClick={(e) => updateLanguage(e)} className="submit-button-25 w-button" />
                        </div>
                    </form>
                    <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>}
        </div>
    )
}

export default LanguageInfo;