import React, { forwardRef, useContext, useEffect, useState } from "react";
import Overlayinfo from "../../SharedComponents/OverlayInfo";
import BoxLayout from "../../SharedComponents/BoxLayout";
import { Trans, useTranslation } from "react-i18next";
import LoadingIndicator from "../../SharedComponents/Loading";
import { ICONS } from "../../Helpers/Icon.service";
import ManageContext from "../../Providers/Manage/ManageContext";
import { filterConst } from "../Home/constant";
import Moment from "moment";
// import _ from "lodash";
import CurrencyFormatter from "../../SharedComponents/Currency";
import { transformDate, exportCSVFile } from "../../Helpers/Utils";
import { LOCALSTORAGE_KEYS } from "../../constants/app.constant";

/**
 * React Date Picker with Locale
 */
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import { usePrevious } from "../../Shared/Hooks/usePrevious";
registerLocale("en-gb", enGb);

const TABS = {
  analytics: "analytics",
  quicklist: "quicklist",
  download: "download",
};

const ArchiveWidgetAnalytics = () => {
  const csvFileName = "past-orders";

  const privacySettingConfig = {
    dailyAverage: "dailyAverage",
    totalEarnings: "totalEarnings",
  };
  const unhideIcon = ICONS.UNHIDE_ICON;
  const hideIcon = ICONS.HIDE_LIGHT_ICON;
  const [privacySetting, setPrivacySettings] = useState({
    dailyAverage:
      JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.daily_average)) === false ? false : true,
    totalEarnings:
      JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.earnings_total))  === false ? false : true
  });
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  // const [displayHistory, setDisplayHistory] = useState(false);
  const [showInfoOverlay, setShowInfoOverlay] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS.analytics);
  const manageService = useContext(ManageContext);
  const [filterType, setFilterType] = useState(filterConst.Monthly);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [historyRecords, setHistoryRecords] = useState([]);

  const [analyticsData, setAnalyticsData] = useState({
    total_orders: 0,
    total_products: 0,
    total_profit: 0,
    dailyAverage: "0",
  });

  /**
   * Date picker
   */
  const timeformat = "DD.MM.YYYY";
  let startDateRef = null;
  let endDateRef = null;
  const [startDateOpen, toggleStartDate] = useState(false);
  const [endDateOpen, toggleEndDate] = useState(false);
  const [filterDates, setFilterDates] = useState({
    startDate: new Date(Moment().startOf("month").format("MM/DD/YYYY")).setHours(0, 0, 0, 0),
    endDate: new Date(Moment().endOf("month").format("MM/DD/YYYY")).setHours(23, 59, 59, 0),
  });
  const [filterRecordsDates, setRecordFilterDates] = useState();
  // const prevFilterDates = usePrevious(filterDates);
  // startDate = new Date(Moment().startOf("month").format("MM/DD/YYYY"));
     //   endDate = new Date(Moment().endOf("month").format("MM/DD/YYYY"));

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <React.Fragment>
      <div
      id="w-node-_1c7ca751-82d9-2bbf-c924-b91d7da45e3f-409f542d"
        className="archive_link_from w-tab-link"
        onClick={() => {
          startDateRef.setOpen(!startDateOpen);
          toggleEndDate(false)
        }}
      >
        <div className="txt_archive_from">From {transformDate(filterDates.startDate, timeformat)}</div>
      </div>
    </React.Fragment>
  ));

  const EndDateInput = forwardRef(({ value, onClick }, ref) => (
    <React.Fragment>
      <div
      id="w-node-ac2f59c2-4ac6-3c7f-8979-8a5ea2dcb77c-409f542d"
        className="archive_link_to w-inline-block w-tab-link"
        onClick={() => {
          endDateRef.setOpen(!endDateOpen);
          toggleStartDate(false);
        }}
      >
        <div className="txt_archive_to">To {transformDate(filterDates.endDate, timeformat)}</div>
      </div>
    </React.Fragment>
  ));


  useEffect(() => {
    console.info("123-------321");
    if(filterDates && filterRecordsDates) {
      console.log(filterRecordsDates);
      // if(filterDates.startDate !== filterRecordsDates.startDate  || filterDates.endDate !== filterRecordsDates.endDate) {
        const newStartDate = filterDates.startDate;
        const newEndDate = filterDates.endDate;
        if(filterRecordsDates.startDate <= newStartDate && newEndDate <= filterRecordsDates.endDate) { 
          filterData(filterDates.startDate, filterDates.endDate);
        } else {
          setFilterType(null);
          onFilterRecords(filterDates.startDate, filterDates.endDate); 
          // setRecordFilterDates({...filterDates})
        }
      // }
    } else {
      onFilterRecords(filterDates.startDate, filterDates.endDate);
    }
    
  }, [filterDates]);

  const getAnalytics = (firstDay, lastDay) => {
    setLoading(true);
    manageService
      .getArchiveAnalytics(firstDay, lastDay)
      .then((data) => {
        setLoading(false);
        mapAnalyticsData(data);
        setHistoryRecords(data?.data || []);
        setFilteredRecords(data?.data || []);
        setRecordFilterDates({startDate: firstDay, endDate: lastDay})
        manageService.setPageLoaded(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const calculateDailyAverage = (totalProfit, daysCount = 1) => {
    console.info(Math.round(totalProfit / daysCount).toFixed(2));
    return Math.round(totalProfit / daysCount).toFixed(2);
  };

  const mapAnalyticsData = (data) => {
    let tempData = { ...analyticsData };
    tempData.total_orders = data?.total_orders;
    tempData.total_products = data?.total_products;
    tempData.total_profit = data?.total_profit;
    const sDate = Moment(new Date(filterDates.startDate), "MM/DD/YYYY");
    const eDate = Moment(new Date(filterDates.endDate), "MM/DD/YYYY");
    let daysCount =
      Math.abs(
        Moment(sDate, "YYYY-MM-DD")
          .startOf("day")
          .diff(Moment(eDate, "YYYY-MM-DD").startOf("day"), "days")
      ) + 1;
    console.info(daysCount);
    tempData.dailyAverage = calculateDailyAverage(
      tempData.total_profit,
      daysCount
    );
    setAnalyticsData(tempData);
  };

  const onFilterChange = (filterType) => {
    setFilterType(filterType);
    setSelectedTab(TABS.analytics);

    let endDate;
    let startDate;
    switch (filterType) {
      case filterConst.Today:
        startDate = new Date();
        endDate = new Date();
        break;

      case filterConst.Weekly:
        startDate = new Date(Moment().startOf("week").format("MM/DD/YYYY"));
        endDate = new Date(Moment().endOf("week").format("MM/DD/YYYY"));
        break;

      case filterConst.Monthly:
        startDate = new Date(Moment().startOf("month").format("MM/DD/YYYY"));
        endDate = new Date(Moment().endOf("month").format("MM/DD/YYYY"));
        break;

      default:
        startDate = new Date();
        endDate = new Date();
        break;
    }

    if (
      filterDates.startDate === startDate &&
      filterDates.endDate === endDate
    ) {
      return false;
    }

    startDate = new Date(startDate.setHours(0, 0, 0, 0)).getTime();
    endDate = new Date(endDate.setHours(23, 59, 59, 0)).getTime();

    setFilterDates({
      startDate,
      endDate,
    });

  };

  const onFilterRecords = (startDate, endDate) => {
    getAnalytics(startDate, endDate);
  };

  const getActiveTabClass = (tab) => {
    if (tab && tab === filterType) {
      return " w--current";
    }
    return "";
  };

  const filterData = (startDate, endDate) => {
    // console.info("startDate", new Date(startDate))
    // console.info("endDate", new Date(endDate))
    let result = historyRecords.filter((record) => {
      let orderTimestamp = record.timestamp;
      // console.info(new Date(orderTimestamp));
      if (startDate <= orderTimestamp && endDate >= orderTimestamp) {
        return true;
      }
    });


    let total_products = 0;
    let total_profit = result.reduce(
      (sum, item) => {
        total_products = total_products + item.products.length;
        return sum + item.total_price
      },
      0
    );

    console.log("result of filter", result);
    setFilteredRecords([...result]);
    let data = {};
    data.total_orders = result.length;
    data.total_products = total_products;
    data.total_profit = total_profit;
    console.info(data);
    mapAnalyticsData(data)
  };

  const toggleEarningsPrivacy = (type) => {
    if (type === privacySettingConfig.dailyAverage) {
      localStorage.setItem(
        LOCALSTORAGE_KEYS.daily_average,
        !privacySetting.dailyAverage
      );
      setPrivacySettings({
        ...privacySetting,
        dailyAverage: !privacySetting.dailyAverage,
      });
    }
    if (type === privacySettingConfig.totalEarnings) {
      localStorage.setItem(
        LOCALSTORAGE_KEYS.earnings_total,
        !privacySetting.totalEarnings
      );
      setPrivacySettings({
        ...privacySetting,
        totalEarnings: !privacySetting.totalEarnings,
      });
    }
  };

  const onDownloadCSV = () => {
    let records = [];
    filteredRecords.forEach((data) => {
      records = [...records, ...data?.products];
    });
    let headers = {
      price: "Price",
      productId: "Product Id",
      productName: "Product Name",
      quantity: "Quantity"
    }
    exportCSVFile(records, csvFileName, headers);
  };

  const toggleProductSalesRecord = (orderId, index) => {
    const record = filteredRecords[index];
    record.isOpen = !record.isOpen;
    filteredRecords.splice(index, 1, record);
    setFilteredRecords([...filteredRecords]);
  }

  const renderHistoryRecords = () => {
    if (!filteredRecords || !filteredRecords.length) return <div className="text-block-476">No records</div>;

    return (
      <React.Fragment>
        {filteredRecords.map((records, index) => {
          return (
            <React.Fragment key={index}>
              <div
                data-hover="false"
                data-delay="0"
                className="dropdown-19 w-dropdown"
              >
                <div
                  className="dropdown-toggle-16 w-dropdown-toggle"
                  id="w-dropdown-toggle-2"
                  role="button"
                  onClick={() => toggleProductSalesRecord(records.orderId, index)}
                >
                  <div className="text-block-369">
                    {records?.products?.length + " Sales"}
                  </div>
                  <div className="text-block-369-copy">
                    <CurrencyFormatter price={records?.total_price} />
                  </div>
                </div>
                <nav
                  className={"dropdown-list-16 w-dropdown-list " + ( records?.isOpen ? 'w--open' : '')}
                  id="w-dropdown-list-2"
                  aria-labelledby="w-dropdown-toggle-2"
                >
                  <div className="div-block-909">
                    {records?.isOpen}
                    {records?.products.map((product, innerIndex) => {
                      return (
                        <React.Fragment key={innerIndex}>
                          
                            <div className="text-block-370">
                              {product?.productName} - {product?.quantity} -{" "}
                              <CurrencyFormatter price={product?.price} />
                            </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </nav>
              </div>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const helpInfo = () => {
    return (
      <React.Fragment>
        {
          <Overlayinfo
            show={showInfoOverlay}
            className="helphistory"
            onHide={() => setShowInfoOverlay(!showInfoOverlay)}
          >
            <div className="rich-text-block-3 w-richtext">
              <h4>What’s a Rich Text element?</h4>
              <p>
                The rich text element allows you to create and format headings,
                paragraphs, blockquotes, images, and video all in one place
                instead of having to add and format them individually. Just
                double-click and easily create content.
              </p>
              <h6>Static and dynamic content editing</h6>
              <p>
                A rich text element can be used with static or dynamic content.
                For static content, just drop it into any page and begin
                editing. For dynamic content, add a rich text field to any
                collection and then connect a rich text element to that field in
                the settings panel. Voila!
              </p>
              <h6>How to customize formatting for each rich text</h6>
              <p>
                Headings, paragraphs, blockquotes, figures, images, and figure
                captions can all be styled after a class is added to the rich
                text element using the "When inside of" nested selector system.
              </p>
            </div>
          </Overlayinfo>
        }
      </React.Fragment>
    );
  };

  const getOrderAnalytics = () => {
    return (
      <div className="tabs-content-5 w-tab-content">
        {/* <div
      data-w-tab="Tab 1"
      className="w-tab-pane"
      id="w-tabs-0-data-w-pane-0"
      role="tabpanel"
      aria-labelledby="w-node-_1c7ca751-82d9-2bbf-c924-b91d7da45e3f-409f542d"
    >
      <div className="month_archive">
        <div className="div-block-732">
          <div className="div-block-734">
            <img
              src="images/left.svg"
              loading="lazy"
              width="15"
              alt=""
            />
          </div>
          <div className="text-block-238">June 2021</div>
          <div className="div-block-733">
            <img
              src="images/right.svg"
              loading="lazy"
              width="15"
              alt=""
            />
          </div>
        </div>
        <div className="w-layout-grid grid-30">
          
        </div>
        <div className="from_to_time_block w-form">
          <form
            id="email-form-21"
            name="email-form-21"
            data-name="Email Form 21"
            className="from_to_time_grid"
          >
            <div
              id="w-node-_7fd7338f-b583-54eb-2ca8-c2695280bad4-409f542d"
              className="text-block-530"
            >
              Choose exact Time:
            </div>
            <input
              type="text"
              className="from_time w-input"
              maxlength="256"
              name="from-time"
              data-name="from time"
              placeholder="From"
              id="from-time"
            />
            <input
              type="text"
              className="to_time w-input"
              maxlength="256"
              name="from-time-2"
              data-name="From Time 2"
              placeholder="To"
              id="from-time-2"
            />
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>
              Oops! Something went wrong while submitting the form.
            </div>
          </div>
        </div>
      </div>
    </div> */}
        {/* <div
      data-w-tab="Tab 2"
      className="w-tab-pane"
      id="w-tabs-0-data-w-pane-1"
      role="tabpanel"
      aria-labelledby="w-node-ac2f59c2-4ac6-3c7f-8979-8a5ea2dcb77c-409f542d"
    >
      <div className="month_archive">
        <div className="div-block-732">
          <div className="div-block-734">
            <img
              src="images/left.svg"
              loading="lazy"
              width="15"
              alt=""
            />
          </div>
          <div className="text-block-238">June 2021</div>
          <div className="div-block-733">
            <img
              src="images/right.svg"
              loading="lazy"
              width="15"
              alt=""
            />
          </div>
        </div>
        <div className="w-layout-grid grid-30">
          
        </div>
        <div className="from_to_time_block w-form">
          <form
            id="email-form-21"
            name="email-form-21"
            data-name="Email Form 21"
            className="from_to_time_grid"
          >
            <div
              id="w-node-_638e0a05-65eb-f696-b28d-916f8cf8a8f7-409f542d"
              className="text-block-530"
            >
              Choose exact Time:
            </div>
            <input
              type="text"
              className="from_time w-input"
              maxlength="256"
              name="from-time-3"
              data-name="From Time 3"
              placeholder="From"
              id="from-time-3"
            />
            <input
              type="text"
              className="to_time w-input"
              maxlength="256"
              name="from-time-2"
              data-name="From Time 2"
              placeholder="To"
              id="from-time-2"
            />
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>
              Oops! Something went wrong while submitting the form.
            </div>
          </div>
        </div>
      </div>
    </div> */}
        <div
          data-w-tab="Tab 3"
          className={
            "w-tab-pane " +
            (selectedTab === TABS.analytics ? " w--tab-active" : "")
          }
          id="w-tabs-0-data-w-pane-2"
          role="tabpanel"
          aria-labelledby="w-node-_57d68809-6dae-c208-1da3-80b891e5a1e2-409f542d"
        >
          <div className="w-layout-grid archive_display_grid">
            <div
              id="w-node-dd6ce01c-ab33-cb45-ff42-7c807ca28159-409f542d"
              className="booker"
            >
              <div className="text-block-473">
                <Trans>pages.manage.ordersReceived</Trans>
              </div>
              <div className="text-block-472">{analyticsData.total_orders}</div>
              <div className="text-block-474">
                {transformDate(filterDates.endDate, timeformat)}
              </div>
            </div>
            <div
              id="w-node-dd6ce01c-ab33-cb45-ff42-7c807ca28160-409f542d"
              className="booker"
            >
              <div className="text-block-473">
                <Trans>pages.manage.totalEarnings</Trans>
              </div>
              <div
                className={
                  privacySetting.totalEarnings
                    ? "earnings_to_hide"
                    : "text-block-472"
                }
              >
                <CurrencyFormatter price={analyticsData.total_profit} />
              </div>
              <div className="text-block-474">
                {transformDate(filterDates.startDate, timeformat)} -{" "}
                {transformDate(filterDates.endDate, timeformat)}
              </div>
              <img
                src={privacySetting.totalEarnings ? hideIcon: unhideIcon }
                loading="lazy"
                width="22"
                height="22"
                alt=""
                onClick={() =>
                  toggleEarningsPrivacy(privacySettingConfig.totalEarnings)
                }
                className="image-221"
              />
            </div>
            <div
              id="w-node-dd6ce01c-ab33-cb45-ff42-7c807ca28168-409f542d"
              className="booker"
            >
              <div className="text-block-473">
                <Trans>pages.manage.soldProducts</Trans>
              </div>
              <div className="text-block-472">
                {analyticsData.total_products}
              </div>
              <div className="text-block-474">
                {transformDate(filterDates.endDate, timeformat)}
              </div>
            </div>
            <div
              id="w-node-dd6ce01c-ab33-cb45-ff42-7c807ca2816f-409f542d"
              className="booker"
            >
              <div className="text-block-473">
                <Trans>pages.manage.dailyAverage</Trans>
              </div>
              <div
                className={
                  privacySetting.dailyAverage
                    ? "earnings_to_hide"
                    : "text-block-472"
                }
              >
                <CurrencyFormatter price={analyticsData.dailyAverage} />
              </div>
              <div className="text-block-474">
                {transformDate(filterDates.startDate, timeformat)} -{" "}
                {transformDate(filterDates.endDate, timeformat)}
              </div>
              <img
                src={privacySetting.dailyAverage ? hideIcon: unhideIcon }
                loading="lazy"
                width="22"
                height="22"
                alt=""
                onClick={() =>
                  toggleEarningsPrivacy(privacySettingConfig.dailyAverage)
                }
                className="image-221"
              />
            </div>
            {/* <div
              id="w-node-dd6ce01c-ab33-cb45-ff42-7c807ca28177-409f542d"
              className="guests_grid_archive"
            >{transformDate(filterDates.startDate, timeformat)}</div>
            <div
              id="w-node-e02fcbf5-5e3d-f4d8-beaa-0d3e61b1152a-409f542d"
              className="guests_grid_archive"
            >{transformDate(filterDates.endDate, timeformat)}</div> */}
          </div>
        </div>

        <div
          data-w-tab="Tab 6"
          className={
            "w-tab-pane " +
            (selectedTab === TABS.quicklist ? " w--tab-active" : "")
          }
          id="w-tabs-0-data-w-pane-5"
          role="tabpanel"
          aria-labelledby="w-node-f187b5cd-3ca0-9e49-573e-c6de73dc2a3a-409f542d"
        >
          <div className="togglehistory">{renderHistoryRecords()}</div>
        </div>
        <div
          data-w-tab="Tab 7"
          className={
            "w-tab-pane " +
            (selectedTab === TABS.download ? " w--tab-active" : "")
          }
          id="w-tabs-0-data-w-pane-6"
          role="tabpanel"
          aria-labelledby="w-node-e57bfa2e-3a0a-7962-725c-4fa2fd3b5fee-409f542d"
        >
          <div className="div-block-1074">
            <div
              id="w-node-_56863043-111e-3e34-56c3-370b0512a382-409f542d"
              className="text-block-529"
              onClick={onDownloadCSV}
            >
              <Trans>pages.manage.csvFile</Trans>
            </div>
            {/* <div className="text-block-529">
              <Trans>pages.manage.xlsFile</Trans>
            </div>
            <div className="text-block-529">
              <Trans>pages.manage.txtFile</Trans>
            </div>
            <div
              id="w-node-bacab288-062d-8c43-51e8-4036ff17d3ff-409f542d"
              className="text-block-529"
            >
              <Trans>pages.manage.sendPdfEmail</Trans>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const getRenderedContent = () => {
    if (isLoading) {
      return getLoader();
    } else {
      return (
        <React.Fragment>
          <div className="div-block-1073">
            <div
              data-duration-in="300"
              data-duration-out="100"
              data-easing="ease-in-out-quad"
              className="tabs-5 w-tabs"
            >
              <div className="w-tab-menu datepciker-grid" role="tablist">
              <DatePicker
                  popperPlacement='top-start'
                  showPopperArrow={false}
                  selectsStart
                  calendarClassName="appDatePicker"
                  selected={filterDates.startDate}
                  locale="en-gb"
                  onChange={(date) =>
                  setFilterDates({ ...filterDates, startDate: new Date(date).getTime() })
                  }
                  ref={(ref) => (startDateRef = ref)}
                  onCalendarClose={() => toggleStartDate(!startDateOpen)}
                  onCalendarOpen={() => toggleStartDate(!startDateOpen)}
                  customInput={<StartDateInput />}
                />
                 <DatePicker
                      popperPlacement="top-end"
                      showPopperArrow={false}
                      selectsEnd
                      calendarClassName="appDatePicker"
                      selected={filterDates.endDate}
                      locale="en-gb"
                      onChange={(date) =>
                        setFilterDates({ ...filterDates, endDate: new Date(date).getTime() })
                      }
                      ref={(ref) => (endDateRef = ref)}
                      onCalendarClose={() => toggleEndDate(!endDateOpen)}
                      onCalendarOpen={() => toggleEndDate(!endDateOpen)}
                      customInput={<EndDateInput />}
                    />
              </div>      
              <div className="tabs-menu-5 w-tab-menu" role="tablist">
               {/*  <a data-w-tab="Tab 1" id="w-node-_1c7ca751-82d9-2bbf-c924-b91d7da45e3f-409f542d" class="archive_link_from w-tab-link" tabindex="-1" href="#w-tabs-0-data-w-pane-0" role="tab" aria-controls="w-tabs-0-data-w-pane-0" aria-selected="false">
                  <div class="txt_archive_from">From 13.04.21/14:45</div>
                </a> */}
               {/*  <a data-w-tab="Tab 2" id="w-node-ac2f59c2-4ac6-3c7f-8979-8a5ea2dcb77c-409f542d" class="archive_link_to w-inline-block w-tab-link" tabindex="-1" href="#w-tabs-0-data-w-pane-1" role="tab" aria-controls="w-tabs-0-data-w-pane-1" aria-selected="false">
                  <div class="txt_archive_to">To 13.04.21/02:50</div>
                </a> */}
                <div
                  data-w-tab="Tab 3"
                  id="w-node-_57d68809-6dae-c208-1da3-80b891e5a1e2-409f542d"
                  className={
                    "tab-link-tab-1-5 w-inline-block w-tab-link " +
                    getActiveTabClass(filterConst.Today)
                  }
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-2"
                  aria-selected="true"
                  onClick={(e) => onFilterChange(filterConst.Today)}
                >
                  <div className="text-block-528">
                    <Trans>pages.manage.today</Trans>
                  </div>
                </div>
                <div
                  data-w-tab="Tab 4"
                  id="w-node-_14d1cbfe-fe25-ad98-b653-4d075993f9e9-409f542d"
                  className={
                    "tab-link-tab-1-5 w-inline-block w-tab-link" +
                    getActiveTabClass(filterConst.Weekly)
                  }
                  tabIndex="-1"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-3"
                  aria-selected="false"
                  onClick={(e) => onFilterChange(filterConst.Weekly)}
                >
                  <div className="text-block-528">
                    <Trans>pages.manage.week</Trans>
                  </div>
                </div>
                <div
                  data-w-tab="Tab 5"
                  id="w-node-_9817fa5f-e885-69f6-d0bd-cc9a0efde4b0-409f542d"
                  className={
                    "tab-link-tab-1-5 w-inline-block w-tab-link" +
                    getActiveTabClass(filterConst.Monthly)
                  }
                  tabIndex="-1"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-4"
                  aria-selected="false"
                  onClick={(e) => onFilterChange(filterConst.Monthly)}
                >
                  <div className="text-block-528">
                    <Trans>pages.manage.month</Trans>
                  </div>
                </div>
                <div
                  data-w-tab="Tab 6"
                  id="w-node-f187b5cd-3ca0-9e49-573e-c6de73dc2a3a-409f542d"
                  className="tab-link-tab-1-5 w-inline-block w-tab-link"
                  tabIndex="-1"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-5"
                  aria-selected="false"
                  onClick={() => setSelectedTab(TABS.quicklist)}
                >
                  <div className="text-block-528">
                    <Trans>pages.manage.quickList</Trans>
                  </div>
                </div>
                <div
                  data-w-tab="Tab 7"
                  id="w-node-e57bfa2e-3a0a-7962-725c-4fa2fd3b5fee-409f542d"
                  className="tab-link-tab-1-5 w-inline-block w-tab-link"
                  tabIndex="-1"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-6"
                  aria-selected="false"
                  onClick={() => setSelectedTab(TABS.download)}
                >
                  <div className="text-block-528">
                    <Trans>pages.manage.download</Trans>
                  </div>
                </div>
              </div>
              {getOrderAnalytics()}
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  const getLoader = () => {
    if (isLoading) {
      return <LoadingIndicator />;
    }
  };

  return (
    <React.Fragment>
      <div className="history">
        <BoxLayout
          className="tabbed-box"
          title={t("pages.manage.archive")}
          helpInfo={helpInfo}
          toggleHelpInfo={setShowInfoOverlay}
        >
          {getRenderedContent()}
        </BoxLayout>
      </div>
    </React.Fragment>
  );
};

export default ArchiveWidgetAnalytics;
