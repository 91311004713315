import React, { useContext, useEffect, useRef, useState } from "react";
import { Logger } from "../../Helpers/Logger.service";
import { deleteImageS3, isEmpty } from "../../Helpers/Utils";
import { ICONS } from "../../Helpers/Icon.service";
import MenuContext from "../../Providers/Menu/MenuContext";
import TimeField from "react-simple-timefield";
import ImageUpload from "../../SharedComponents/ImageUpload";
import Overlayinfo from "../../SharedComponents/OverlayInfo";
import ProductTagsIcon from "../../SharedComponents/ProductTagsIcon";
import DayPicker from "../../SharedComponents/DayPicker";
import DurationInputMask from "react-duration-input-mask";
import { CustomValidator } from "../../Validator";
import ErrorMessage from "../../SharedComponents/ErrorMessage";
import { USER_ACTIONS } from "../../constants/app.constant";
import SimpleConfirmDialog from "./SimpleConfirm";


import CurrencyInput from 'react-currency-input-field';

import _ from "lodash";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const AddEditProduct = (props) => {
  const closeIcon = ICONS.CLOSE_ICON;
  const { t, i18n } = useTranslation();
  const questionIcon = ICONS.QUESTION_CIRCLE_ICON;

  const menuService = useContext(MenuContext);
  const formRef = useRef();

  const categoryList = props.categoryList || [];

  const activeTimingsDefaults = () => {
    return {
      timeBegin: "00:00",
      timeEnd: "23:59",
      weekdays: {
        mo: false,
        tu: false,
        we: false,
        th: false,
        fr: false,
        sa: false,
        su: false,
      },
    };
  };
  const productDefaults = () => {
    return {
      id: 0,
      information: "",
      name: "",
      price: "",
      icons: [],
      images: [],
      hide: false,
      extraInformation: "",
      activeTime: "",
      duration: "",
      UIDuration: "",
      productNum: "",
      categoryId: props.categoryId,
    };
  };

  const [productForm, setProductForm] = useState(productDefaults());
  const [isEditMode, setEditMode] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  // const [confirmChanges, setConfirmChanges] = useState(false);
  const [openDropDown, setOpenDropdown] = useState(false);
  const [selectedCategoryName, setSelectedCategory] = useState("");
  const [activeTimings, setActiveTimings] = useState(activeTimingsDefaults());
  const [showInfoOverlay, setShowInfoOverlay] = useState(false);

  const [imageError, setImageError] = useState(null);
  const [validationErrors, setValidationError] = useState({});

  useEffect(() => {
    if (props.categoryList && props.categoryList.length) {
      setSelectedCategory(menuService.selectedCategory.categoryName);
    }

    if (!isEmpty(props.product)) {
      setEditMode(true);
      let clonedProduct = _.cloneDeep(props.product);
      clonedProduct.UIDuration = clonedProduct.duration;
      clonedProduct.extraInformation = toggleNewLineCharacter(clonedProduct.extraInformation, false);
      clonedProduct.information = toggleNewLineCharacter(clonedProduct.information, false);
      setProductForm({ ...productForm, ...clonedProduct });
      if (props.product && props.product.activeTime) {
        setActiveTimings(props.product.activeTime);
      }
    } else {
      setEditMode(false);
      setProductForm(productDefaults());
    }
  }, [props.product, props.categoryList]);

  const onSave = (event, closeDialog = true) => {
    if (event) event.preventDefault();
    Logger.log("::SaveAddEdit::");
    let productObject = { ...productForm };
    productObject.activeTime = { ...activeTimings };

    let timeValidations = CustomValidator.validateTimings({
      ...productObject.activeTime,
    });
    let daysValidation = CustomValidator.validateDays(
      productObject.activeTime.weekdays
    );
    let productNumberValidation = CustomValidator.validateProductNumber(
      productObject.productNum,
      productObject.id,
      categoryList
    );

    if (timeValidations.error) {
      validationErrors.timeError = timeValidations.message;
    } else {
      delete validationErrors.timeError;
      // setValidationError({...validationErrors})
    }

    if (daysValidation.error) {
      validationErrors.dayError = daysValidation.message;
    } else {
      delete validationErrors.dayError;
      // setValidationError({...validationErrors})
    }

    if (productNumberValidation.error) {
      validationErrors.prodNumberError = productNumberValidation.message;
    } else {
      delete validationErrors.prodNumberError;
    }

    if (
      daysValidation.error ||
      timeValidations.error ||
      productNumberValidation.error
    ) {
      setValidationError({ ...validationErrors });
      return false;
    }

    // Reset Errors
    setValidationError({});

    delete productObject.UIDuration;

    // productObject.price = parseFloat(productObject.price)
    if(productObject.information){
      productObject.information = toggleNewLineCharacter(productObject.information, true);
    }
    if(productObject.extraInformation) {
      productObject.extraInformation = toggleNewLineCharacter(productObject.extraInformation, true);
    }

    console.info({...productObject});
    
    // menuService.addUpdatePersistProduct(productObject.categoryId, productObject);
    if (isEditMode) {
      menuService.persistProductAction(
        USER_ACTIONS.EDIT,
        productObject.categoryId,
        productObject
      );
    } else {
      menuService.persistProductAction(
        USER_ACTIONS.ADD,
        productObject.categoryId,
        productObject
      );
    }
    if (closeDialog) {
      props.close();
    } else {
      props.localRefresh(productForm);
    }
  };

  const toggleNewLineCharacter = (text = '', remove = true ) => {
    if(remove) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    } else {
      return text.replaceAll('<br />', '\n' );
    }
   
  }

  const onBeginTimeChange = (event, value) => {
    const newTime = value.replace(/-/g, ":");
    const time = newTime.substr(0, 5);
    setActiveTimings({ ...activeTimings, timeBegin: time });
  };
  const onEndTimeChange = (event, value) => {
    const newTime = value.replace(/-/g, ":");
    const time = newTime.substr(0, 5);
    setActiveTimings({ ...activeTimings, timeEnd: time });
  };

  const cancel = () => {
    Logger.log("::CancelAddEdit::");

    if(disableSave) {
      alert(t("pages.menu.disabledProductSaveError"));
      return;
    }

    // Compare Product Object
    if (isEditMode && !_.isEqual(props.product.images, productForm.images)) {
      // setConfirmChanges(true);
      onConfirmAction("yes");
    } else {
      if (!isEditMode) {
        deleteImagesOnCancelAdd();
      }
      props.close();
    }
  };

  const handleInputChange = (e, type) => {
    let tempForm = { ...productForm };
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    tempForm[type] = value;
    setProductForm(tempForm);
  };

  const onActiveDaysChange = (daysObject) => {
    activeTimings.weekdays = { ...activeTimings.weekdays, ...daysObject };
    setActiveTimings({ ...activeTimings });
  };

  const onSelectedIngredient = (iconName) => {
    let tempForm = { ...productForm };
    if (tempForm.icons && tempForm.icons.length) {
      let found = tempForm.icons.findIndex(
        (selectedItem) => selectedItem.id == iconName
      );
      if (found > -1) {
        tempForm.icons.splice(found, 1);
      } else {
        tempForm.icons.push({ id: iconName });
      }
    } else {
      tempForm.icons.push({ id: iconName });
    }

    setProductForm({ ...tempForm, icons: [...tempForm.icons] });
  };

  const onIconSelectDeselect = (icon) => {
    onSelectedIngredient(icon);
  };

  const onSelectCategory = (category) => {
    console.log(category);
    setSelectedCategory(category.categoryName);
    let tempForm = { ...productForm };
    tempForm.categoryId = category.id;
    setProductForm(tempForm);
    setOpenDropdown(false);
  };

  const onImageValidate = (msg) => {
    setImageError(msg);
  };

  const onConfirmAction = (decision) => {
    console.log(decision);
    if (decision === "yes") {
      // let imagesTobeRemoved  = _.difference(productForm.images, props.product.images);
      // For Newly Uploaded Images

      // When No image is there from earlier
      if (!props.product.images.length) {
        let imagesTobeRemoved = productForm.images;
        if (imagesTobeRemoved && imagesTobeRemoved.length) {
          deleteUnsavedImagesFromS3(imagesTobeRemoved);
        }
      }
      // When Image is there from earlier and also newly added images are there
      else if (productForm.images.length && props.product.images.length) {
        let imagesTobeRemoved = _.difference(
          productForm.images,
          props.product.images
        );
        console.log("Existing", props.product.images);
        console.log(imagesTobeRemoved);
        if (imagesTobeRemoved && imagesTobeRemoved.length) {
          deleteUnsavedImagesFromS3(imagesTobeRemoved);
        }
      }

      props.close();
      // formRef.current.submit();
    }
    // setConfirmChanges(false);
  };

  /**
   * Should be called in case of Cancel of Add product  Action
   */
  const deleteImagesOnCancelAdd = () => {
    let imagesTobeRemoved = productForm.images;
    console.log(imagesTobeRemoved);
    if (imagesTobeRemoved && imagesTobeRemoved.length) {
      deleteUnsavedImagesFromS3(imagesTobeRemoved);
    }
  };

  /**
   *
   * Delete Images on the background
   */
  const deleteUnsavedImagesFromS3 = (imagesTobeRemoved) => {
    imagesTobeRemoved.forEach((imagePath, index) => {
      let fileNameUrl = imagePath;
      let fileName = fileNameUrl.split("/").pop();
      deleteImageS3(fileName).then((data) => {
        console.log(data);
      });
    });
    props.close();
  };

  const getStyle = () => {
    let styles = {};
    if (props.show) {
      styles = { display: "flex" };
    } else {
      styles = { display: "none" };
    }
    return styles;
  };

  const openCategoryDropDown = (event) => {
    event.stopPropagation();
    if (!isEditMode) {
      setOpenDropdown(!openDropDown);
    }
  };

  const setDuration = (inseconds, parsedTime) => {
    let tempForm = { ...productForm };
    tempForm.duration = inseconds + "";
    tempForm.UIDuration = parsedTime;
    setProductForm(tempForm);
  };

  const updateUploadedImage = (imagePath) => {
    let tempForm = { ...productForm };
    tempForm.images.push(imagePath);
    setProductForm(tempForm);
  };

  const deleteImageReference = (fileRef) => {
    let tempForm = { ...productForm };
    let index = tempForm.images.findIndex((element) =>
      element.includes(fileRef)
    );
    if (index > -1) {
      tempForm.images.splice(index, 1);
      setProductForm(tempForm);
      // Following should only be called for Edit case
      if (isEditMode) {
        setTimeout(() => {
          onSave(undefined, false);
        }, 100);
      }
    }
  };

  const onDisableSave = (disable) => {
    setDisableSave(disable);
  };

  const handlePriceInput = (value) => {
    // let value = event.target.value;
    
    let tempForm = { ...productForm };
    tempForm.price = value // ).toFixed(2).replace(/[.,]00$/, "");
    /* if (value){
        // console.log(value);
        tempForm.price = parseFloat(parseFloat(value).toFixed(2)) + '' // .replace(/[.,]00$/, "");
        // console.log(tempForm.price)
    } */
    setProductForm(tempForm);
  };

  /* const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            console.log('enter press here! ')
            return;
        }

        let tempForm = { ...productForm };

        var input = event.target.value;
        var oldVal = tempForm.price;
        var regex = new RegExp(event.target.getAttribute('pattern'), 'g');

        setTimeout(function(){
            var newVal = event.target.value;
            if(!regex.test(newVal)){
                return
            }
            handleInputChange(event, 'price');
        }, 0);

      } */

  return (
    <React.Fragment>
      <div className="itemoptions" style={getStyle()}>
        <form
          ref={formRef}
          id="email-form-4"
          name="email-form-4"
          data-name="Email Form 4"
          method="get"
          className="form-113 pos-rel"
          onSubmit={(event) => onSave(event)}
        >
          <div className="popupitem-2">
            <div className="text-block-450">
              {isEditMode ? t('shared.actions.edit') : t('shared.actions.add')} <Trans>pages.menu.productSettingsTitle</Trans>
            </div>
            <div className="columns-10 w-row">
              <div className="column-39 w-col w-col-5 w-col-stack w-col-small-small-stack w-col-tiny-tiny-stack">
                <div
                  data-hover=""
                  data-delay="0"
                  className="dropdown-7 w-dropdown"
                >
                  <div
                    className={
                      "dropdown-toggle-6 w-dropdown-toggle " +
                      (openDropDown ? "w--open" : "")
                    }
                    onClick={(event) => openCategoryDropDown(event)}
                  >
                    <div className="text-block-146">{selectedCategoryName}</div>
                    <img
                      src="/assets/images/down_lAsset-7.svg"
                      loading="lazy"
                      width="11"
                      height="11"
                      className="image-133"
                    />
                  </div>
                  <nav
                    className={
                      "dropdown-list-7 w-dropdown-list " +
                      (openDropDown ? "w--open" : "")
                    }
                  >
                    {categoryList &&
                      categoryList.map((category, index) => {
                        return (
                          <React.Fragment key={index}>
                            <a
                              onClick={() => onSelectCategory(category)}
                              className="dropdown-link-5 w-dropdown-link"
                            >
                              {category.categoryName}
                            </a>
                          </React.Fragment>
                        );
                      })}
                  </nav>
                </div>
                <div className="div-block-593">
                  <div className="text-block-178"><Trans>pages.menu.uploadPicLabel</Trans></div>
                  <ImageUpload
                    disable={onDisableSave}
                    images={productForm.images}
                    deleteImage={deleteImageReference}
                    uploadedImage={updateUploadedImage}
                    onImageValidate={onImageValidate}
                  />
                </div>
                {imageError && <ErrorMessage text={imageError} />}

                <div className="form-block-15 w-form">
                  <input
                    type="text"
                    className="text-field-153 w-input"
                    name="Title"
                    data-name="Title"
                    onChange={(e) => handleInputChange(e, "name")}
                    value={productForm.name}
                    placeholder={t('pages.menu.title')}
                    id="Title"
                    required
                    minLength="2"
                    maxLength="40"
                  />
                  <textarea
                    placeholder={t('pages.menu.placeHoldProductDesc')}
                    id="description"
                    name="description"
                    maxLength="130"
                    data-name="Description"
                    className="textarea-5 w-input"
                    onChange={(e) => handleInputChange(e, "information")}
                    value={productForm.information}
                  ></textarea>
                  <textarea
                    placeholder={t('pages.menu.placeHoldExtraInfo')}
                    id="description-2"
                    name="description-2"
                    data-name="Description 2"
                    maxLength="200"
                    className="text2 w-input"
                    onChange={(e) => handleInputChange(e, "extraInformation")}
                    value={productForm.extraInformation}
                  ></textarea>
                  <div className="div-block-644">
                    <input
                      type="text"
                      className="itemnumber w-input"
                      name="Itemnumber"
                      data-name="Itemnumber"
                      placeholder={t('pages.menu.placeHoldProdNumber')}
                      id="Itemnumber"
                      maxLength="5"
                      onChange={(e) => handleInputChange(e, "productNum")}
                      value={productForm.productNum}
                      required
                    />
                    {
                      <DurationInputMask
                        handleBlur={(parsedValue, maskedValue, rawValue) => {
                          console.log(
                            "handleBlur ",
                            parsedValue,
                            maskedValue,
                            rawValue
                          );
                          setDuration(parsedValue, rawValue);
                        }}
                        handleChange={(parsedValue, maskedValue, rawValue) => {
                          console.log(
                            "handleChange",
                            parsedValue,
                            maskedValue,
                            rawValue
                          );
                        }}
                        value={productForm.UIDuration}
                        className="itemdur w-input"
                        name="Itemnumber-2"
                        placeholder={t('pages.menu.placeHoldProdDuration')}
                        id="Itemnumber-2"
                      />
                    }

                    {/* {
                      <input
                        type="number"
                        className="pricetxtitem w-input"
                        name="priceitem-2"
                        data-name="Priceitem 2"
                        placeholder="Price 00,00€"
                        id="priceitem-2"
                        value={productForm.price}
                        required
                        max="100000"
                        min="0"
                        step="any"
                        onChange={(e) => handlePriceInput(e)}
                      />
                    } */}

                    <CurrencyInput
                      id="input-price"
                      intlConfig={{ locale: 'en-US'}}
                      className="pricetxtitem w-input"
                      name="priceitem-2"
                      placeholder={t('pages.menu.placeHoldProdPrice')}
                      decimalsLimit={2}
                      value={productForm.price}
                      decimalSeparator="."
                      disableGroupSeparators={true}
                      maxLength={9}
                      allowNegativeValue={false}
                      decimalScale={2}
                      required
                      onValueChange={(value, name) => handlePriceInput(value) }
                    />

                  </div>
                  {validationErrors?.prodNumberError && (
                    <ErrorMessage text={validationErrors.prodNumberError} />
                  )}

                  <div className="success-message-3 w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="error-message-3 w-form-fail">
                    <div className="text-block-209">
                      Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-40 w-col w-col-7 w-col-stack w-col-small-small-stack w-col-tiny-tiny-stack">
                <ProductTagsIcon
                  selectedIcons={productForm.icons}
                  onChange={onIconSelectDeselect}
                />
                <div className="form-block-18 w-form">
                  <div
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="duration1"
                  >
                    {/* <DayPicker /> */}
                    <DayPicker
                      onDayChange={onActiveDaysChange}
                      activeDays={activeTimings.weekdays}
                    />
                    {validationErrors?.dayError && (
                      <ErrorMessage text={validationErrors.dayError} />
                    )}
                    <div className="cattime">
                      <TimeField
                        value={activeTimings.timeBegin}
                        onChange={onBeginTimeChange}
                        input={
                          <input
                            type="text"
                            className="begin w-input"
                            name="Anfang-2"
                            data-name="Anfang 2"
                            placeholder="Beginning 00:00"
                            id="Anfang-2"
                          />
                        }
                      />
                      <TimeField
                        value={activeTimings.timeEnd}
                        onChange={onEndTimeChange}
                        input={
                          <input
                            type="text"
                            className="endtime w-input"
                            name="Ende-2"
                            data-name="Ende 2"
                            placeholder="Ending 00:00"
                            id="Ende-2"
                          />
                        }
                      />
                    </div>
                    {validationErrors?.timeError && (
                      <ErrorMessage text={validationErrors.timeError} />
                    )}
                  </div>
                  <div className="success-message-4 w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="error-message-4 w-form-fail">
                    <div className="text-block-412">
                      Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div data-w-id="7af34f8e-d8e0-cf4e-fd44-6eae1bde034f" className="text-block-135" onClick={cancel}>Cancel</div> */}
            <button type="submit" disabled={disableSave} className="savetxt">
              <Trans>shared.actions.save</Trans>
            </button>
            <img
              src={closeIcon}
              loading="lazy"
              width="22"
              height="22"
              className="image-153"
              onClick={cancel}
            />
            <img
              src={questionIcon}
              onClick={() => setShowInfoOverlay(!showInfoOverlay)}
              loading="lazy"
              width="22"
              height="22"
              className="help"
            />

            {showInfoOverlay && (
              <Overlayinfo
                title=""
                info=""
                show={showInfoOverlay}
                onHide={() => setShowInfoOverlay(!showInfoOverlay)}
              >
                <div className="rich-text-block-3 w-richtext">
                  <h4>What’s a Rich Text element?</h4>
                  <p>
                    The rich text element allows you to create and format
                    headings, paragraphs, blockquotes, images, and video all in
                    one place instead of having to add and format them
                    individually. Just double-click and easily create content.
                  </p>
                  <h6>Static and dynamic content editing</h6>
                  <p>
                    A rich text element can be used with static or dynamic
                    content. For static content, just drop it into any page and
                    begin editing. For dynamic content, add a rich text field to
                    any collection and then connect a rich text element to that
                    field in the settings panel. Voila!
                  </p>
                  <h6>How to customize formatting for each rich text</h6>
                  <p>
                    Headings, paragraphs, blockquotes, figures, images, and
                    figure captions can all be styled after a class is added to
                    the rich text element using the "When inside of" nested
                    selector system.
                  </p>
                </div>
              </Overlayinfo>
            )}
          </div>
        </form>
      </div>
      {/* {confirmChanges && <SimpleConfirmDialog show={confirmChanges} action={(decision) => onConfirmAction(decision)} />} */}
    </React.Fragment>
  );
};

export default AddEditProduct;
