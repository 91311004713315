import { INTERNATIONAL_LOCALES } from "./app.constant"

export const APP_CONFIG = {
    IMAGE_PATH : '/assets/images',
    DATE_FORMAT: 'DD.MM.YYYY',
    API_DATE_FORMAT: 'DD.MM.YYYY, hh:mm',
    DEFAULT_LANGUAGE: INTERNATIONAL_LOCALES.DE.code, 
    DEFAULT_LOCALE: 'de-DE',
    get LOCALE() {
        return this.DEFAULT_LOCALE
    },
    /* set LOCALE(value) {
        this._LOCALE = value;
    }, */
    CURRENCY: 'EUR',
    S3BUCKET: {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        // dirName: authenticationService.userID, //UserId
        region: 'eu-west-1',
        accessKeyId: process.env.REACT_APP_S3_BUCKET_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */,
    },
    MOBILE_APP_URL: process.env.REACT_APP_MOBILE_APP_URL
}


//export default 
//export default Config;