
import React, { useContext, useEffect, useState } from 'react';
import { Logger } from '../../Helpers/Logger.service';
import { isEmpty } from '../../Helpers/Utils';
import MenuContext from '../../Providers/Menu/MenuContext';
import { ICONS } from '../../Helpers/Icon.service';
import TimeField from 'react-simple-timefield';
import DayPicker from '../../SharedComponents/DayPicker';
import ErrorMessage from '../../SharedComponents/ErrorMessage';
import { CustomValidator } from '../../Validator';
import { useForm, Controller  } from "react-hook-form";
import { USER_ACTIONS } from '../../constants/app.constant';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

 

const AddEditCategory = (props) => {
    const { t, i18n } = useTranslation();
    const closeIcon = ICONS.CLOSE_ICON;
    const menuService = useContext(MenuContext);
    const activeTimingsDefaults = () => {
        return {
            timeBegin: "00:00",
            timeEnd: "23:59",
            weekdays: {
                mo: false,
                tu: false,
                we: false,
                th: false,
                fr: false,
                sa: false,
                su: false
            }
        }
    }

    const catFormDefaults = () => {
        return {
            categoryName: '',
            hide: "false",
            id: 0,
            activeTime: activeTimingsDefaults()
        }
    }


    const [categoryForm, setCategoryForm] = useState(catFormDefaults());
    const [isEditMode, setEditMode] = useState(false);
    const [activeTimings, setActiveTimings] = useState(activeTimingsDefaults());
    const [customErrors, setCustomError] = useState({});

    const { register, formState: { errors }, handleSubmit, setValue, getValues , reset, control } = useForm({ mode: "onChange" });

    const fieldValidator = {
        categoryName: {
            required: t('pages.menu.errorCategoryName'),
            minLength: {
                value: 2,
                message:  t('pages.menu.errorCategoryMinLength')
            },
            maxLength: {
                value: 40,
                message: t('pages.menu.errorCategoryMaxLength')
            }
        }
    }

    useEffect(() => {
        if (!isEmpty(props.category)) {
            setEditMode(true);
           /*  setCategoryForm(props.category);
            if (props.category.activeTime) {
                setActiveTimings(props.category.activeTime);
            } */

            setTimeout(() => {
                console.log(props.category);
                reset(props.category);
                // setValue(props.category);
                // setValue('categoryName', props.category.categoryName)
               /*  setValue('activeTime.weekdays', props.category.activeTime.weekdays)
                setValue('activeTime.weekdays', props.category.activeTime.weekdays) */
            });


        } else {
            setEditMode(false);
            // console.log({...catFormDefaults(), ...activeTimingsDefaults() });
            setTimeout(() => {
                reset({...catFormDefaults() });
                console.log(getValues());
               
            });
           /*  setCategoryForm(catFormDefaults());
            setActiveTimings(activeTimingsDefaults()) */
        }

    }, [props.category]);


    const onSave = (data) => {
        // event.preventDefault();
        Logger.log("::SaveAddEdit::");

        // let categoryObject = { ...categoryForm };
        let categoryObject = getValues();
        // categoryObject.activeTime = {...categoryObject.activeTime, ...activeTimings };

        let timeValidations = CustomValidator.validateTimings(categoryObject.activeTime);
        let daysValidation = CustomValidator.validateDays(categoryObject.activeTime.weekdays);

        if (timeValidations.error) {
            customErrors.timeError = timeValidations.message;
        } else {
            delete customErrors.timeError;
            setCustomError({...customErrors})
        }

        if (daysValidation.error) {
            customErrors.dayError = daysValidation.message;
        }

        setCustomError({ ...customErrors });

        if (daysValidation.error || timeValidations.error) {
            return false;
        }

        // Reset Errors
        setCustomError({});

        console.warn(data);
        // console.warn(categoryObject);
        
        if (isEditMode) {
            menuService.persistActionCategory(USER_ACTIONS.EDIT, categoryObject);
        } else {
            menuService.persistActionCategory(USER_ACTIONS.ADD, categoryObject);
        }
    
        props.close();


    }


    /**
     * Close Modal Dialog
     */
    const cancel = () => {
        Logger.log("::CancelAddEdit::");
        props.close();
    }

    /* const handleInputChange = (e, type) => {
        let tempForm = { ...categoryForm };
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        tempForm[type] = value;
        tempForm[`${type}Error`] = null;
        setCategoryForm(tempForm);
    } */


    /**
     * 
     * @param {*} event 
     * @param {*} value 
     * Time Change handling
     * Should take input in hh:mm
     */
    const onBeginTimeChange = (event, value) => {
        const newTime = value.replace(/-/g, ':');
        const time = newTime.substr(0, 5);
        setValue('activeTime.timeBegin', time);
        /* activeTimings.weekdays = { ...activeTimings.weekdays, timeBegin: time }
        setActiveTimings({ ...activeTimings }); */
    }
    const onEndTimeChange = (event, value) => {
        const newTime = value.replace(/-/g, ':');
        const time = newTime.substr(0, 5);
        setValue('activeTime.timeEnd', time);
        /* activeTimings.weekdays = { ...activeTimings.weekdays, timeEnd: time }
        setActiveTimings({ ...activeTimings }); */
    }

    const onActiveDaysChange = (daysObject) => {
        // console.log(daysObject);
        // let days = { ...activeTimings.weekdays, ...daysObject };
        setValue('activeTime.weekdays', {...daysObject});
        // console.log(getValues());
        // setActiveTimings({ ...activeTimings });

    }


    const getStyle = () => {
        let styles = {};
        if (props.show) {
            styles = { display: 'flex' }
        } else {
            styles = { display: 'none' }
        }
        return styles;
    }



    return (
        <React.Fragment>

            <div className="blurred1" style={getStyle()}>
                <div className="popup_cat_name blurred2-2">
                    <div>
                        <div className="text-block-115"><Trans>pages.menu.categorySettings</Trans></div>
                    </div>
                    <div className="form-block-18 w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" className="form-12"
                            onSubmit={handleSubmit(onSave)} >
                            <input
                                name="categoryName"
                                maxLength="40"
                                className="txtcat w-input" data-name="Bezeichnung 2" placeholder={t('pages.menu.title')} id="Bezeichnung-2" autoComplete="off"
                                {...register("categoryName", fieldValidator.categoryName)}
                            />
                            {errors.categoryName && <ErrorMessage text={errors?.categoryName?.message} />}

                            <div className="cattime">

                            <Controller
                                control={control}
                                name="activeTime.timeBegin"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TimeField
                                    value={value}
                                    onChange={onBeginTimeChange}
                                    input={<input type="text" className="begin w-input" name="Anfang" data-name="Anfang" placeholder="Begin at 00:00" id="Anfang" />}
                                />
                                )}
                            />
                            <Controller
                                control={control}
                                name="activeTime.timeEnd"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TimeField
                                    value={value}
                                    onChange={onEndTimeChange}
                                    input={<input type="text" className="endtime w-input" name="Ende" data-name="Ende" placeholder="End at 00:00" id="Ende" />}
                                />
                                )}
                            /> 
                            
                                {/* <TimeField
                                    
                                    onChange={onBeginTimeChange}
                                    {...register("activeTime.weekdays.timeBegin")}
                                    input={<input type="text" className="begin w-input" name="Anfang" data-name="Anfang" placeholder="Begin at 00:00" id="Anfang" />}
                                />
                                <TimeField
                                    {...register("activeTime.weekdays.timeEnd")}
                                    onChange={onEndTimeChange}
                                    input={<input type="text" className="endtime w-input" name="Ende" data-name="Ende" placeholder="End at 00:00" id="Ende" />}
                                /> */}
                            </div>
                            {customErrors?.timeError && <ErrorMessage text={customErrors?.timeError} />}
                            
                            {getValues()?.activeTime && <Controller
                                control={control}
                                name="activeTime.weekdays"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DayPicker onDayChange={onActiveDaysChange} refs={register("activeTime.weekdays")} activeDays={getValues()?.activeTime?.weekdays || {}} />
                                )}
                            />  } 

                            {/* <DayPicker onDayChange={onActiveDaysChange} activeDays={activeTimings.weekdays} 
                             refs={register("activeTime.weekdays")}/>   */}
                            {/* <DayPicker onDayChange={onActiveDaysChange} activeDays={activeTimings.weekdays} /> */}
                            {customErrors?.dayError && <ErrorMessage text={customErrors?.dayError} />}

                            <input type="submit" value={t('shared.actions.save')} data-wait="bitte warten..." className="submit-button-11 w-button" />
                            <div className="success-message-5 w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="error-message-5 w-form-fail">
                                <div>Something went wrong while submitting the form.</div>
                            </div>
                        </form>
                    </div>
                    <img src={closeIcon} loading="lazy" width="22" className="image-28" onClick={cancel} />
                </div>
            </div>

        </React.Fragment>
    );
}

export default AddEditCategory