import React from "react";
import { Link } from 'react-router-dom';
import { ROUTES_PATH } from "../constants/Routes";
import { ICONS } from "../Helpers/Icon.service";

import { withTranslation } from "react-i18next";
import { Trans } from 'react-i18next';


export const NavbarMobile = (props) => {

    const PATH_ROUTES = ROUTES_PATH;
    const homeIcon = ICONS.HOME_LIGHT_ICON;
    const menuIcon = ICONS.LINES_ICON;
    const orderIcon = ICONS.CART_ICON;
    const manageIcon = ICONS.EDIT_ICON;
    const adminIcon = ICONS.USER_ICON;

    return (
        <React.Fragment>
            <div className="navbarmobile">
                <Link className="div-block-573 w-inline-block" to={PATH_ROUTES.HOME}>
                    <img src={homeIcon} loading="lazy" width="17" alt="" />
                    <div className="text-block-231"><Trans>topMenu.home</Trans></div>
                </Link>
                <Link className="div-block-573 w-inline-block" to={PATH_ROUTES.MENU}>
                    <img src={menuIcon} loading="lazy" width="17" alt="" />
                    <div className="text-block-231"><Trans>topMenu.menu</Trans></div>
                </Link>
                <Link className="div-block-573 w-inline-block" to={PATH_ROUTES.ORDER}>
                    <img src={orderIcon} loading="lazy" width="17" alt="" />
                    <div className="text-block-231"><Trans>topMenu.order</Trans></div>
                </Link>
                <Link className="div-block-573 w-inline-block w--current" to={PATH_ROUTES.MANAGE}>
                    <img src={manageIcon} loading="lazy" width="17" alt="" />
                    <div className="text-block-231"><Trans>topMenu.manage</Trans></div>
                </Link>
                <Link className="settings w-inline-block" to={PATH_ROUTES.PROFILE}>
                    <img src={adminIcon} loading="lazy" width="17" alt="" />
                    <div className="text-block-231"><Trans>topMenu.admin</Trans></div>
                </Link>

            </div>
        </React.Fragment>
    )
}

export default withTranslation()(NavbarMobile);

