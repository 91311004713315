import React, { useState } from "react";
import Overlayinfo from "../../SharedComponents/OverlayInfo";
import BoxLayout from "../../SharedComponents/BoxLayout";
import TableOrders from "./TableOrders";
import { ICONS } from "../../Helpers/Icon.service";
import { Trans } from "react-i18next";
import { useTranslation } from 'react-i18next';


const OrdersBox = (props) => {
  const { t, i18n } = useTranslation();
  const { selectedTable } = props;
  const arrowLeftIcon = ICONS.ARROW_LEFT;
  const boxTitle = t("pages.order.orders") + (selectedTable ? ` ${t("pages.order.for")} ${selectedTable.tableName}`: '') 

  const [showHelp, toggleHelpInfo] = useState(false);

  const helpInfo = () => {
    return (
      <React.Fragment>
        {
          <Overlayinfo show={showHelp} onHide={() => toggleHelpInfo(!showHelp)}>
            <div className="rich-text-block-3 w-richtext">
              <h4>What’s a Rich Text element?</h4>
              <p>
                The rich text element allows you to create and format headings,
                paragraphs, blockquotes, images, and video all in one place
                instead of having to add and format them individually. Just
                double-click and easily create content.
              </p>
              <h6>Static and dynamic content editing</h6>
              <p>
                A rich text element can be used with static or dynamic content.
                For static content, just drop it into any page and begin
                editing. For dynamic content, add a rich text field to any
                collection and then connect a rich text element to that field in
                the settings panel. Voila!
              </p>
              <h6>How to customize formatting for each rich text</h6>
              <p>
                Headings, paragraphs, blockquotes, figures, images, and figure
                captions can all be styled after a class is added to the rich
                text element using the "When inside of" nested selector system.
              </p>
            </div>
          </Overlayinfo>
        }
      </React.Fragment>
    );
  };

  const getContent = () => {
    if (props.isLoading) {
      return (
        <div
          data-w-id="f7a71ccf-eca6-f728-d23f-86c4d72c7116"
          className="pageloading"
        ></div>
      );
    }

    if(!selectedTable)
        return <div data-w-id="478d2e92-001b-3663-f941-c58c9e5d78ed" className="select_table_message">
          <img src={arrowLeftIcon} loading="lazy" width="33" height="33" alt="" className="image-192" />
        <div className="text-block-476"><Trans>pages.order.selectTableText</Trans></div>
      </div>

    if(selectedTable && !selectedTable.orders.length)
      return  <div data-w-id="478d2e92-001b-3663-f941-c58c9e5d78ed" className="select_table_message">
      <div className="text-block-476"><Trans>pages.order.noOrder</Trans></div>
    </div> 

    return <TableOrders {...props} />
  };

  return (
    <React.Fragment>
      <BoxLayout
        className="orders-2"
        title={boxTitle}
        helpInfo={helpInfo}
        toggleHelpInfo={toggleHelpInfo}
      >
        {getContent()}
      </BoxLayout>
    </React.Fragment>
  );
};

export default OrdersBox;
