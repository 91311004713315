import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../Helpers/handle-response";
import { Auth } from "aws-amplify";
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  isLoggedIn: false,
  login,
  logout,
  signUp,
  recoverPassword,
  resetPassword,
  currentUser: currentUserSubject.asObservable(),
  get userID() {
    return currentUserSubject.value.attributes.sub;
  },
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

async function login(username, password) {
  // AWS Cognito integration here
  try {
    const user = await Auth.signIn(username, password);
    console.log(user);
    localStorage.setItem("currentUser", JSON.stringify(user));
    currentUserSubject.next(user);
    authenticationService.isLoggedIn = true;
  } catch (error) {
    let err = null;
    !error.message ? (err = { message: error }) : (err = error);
    // throw new Error(err);
    console.log(err);
    return Promise.reject(err);
  }
}

async function signUp(username, password) {
  try {
    const { user } = await Auth.signUp({
      username, // email will be username
      password
    });
    console.log(user);
  } catch (error) {
    console.log("error signing up:", error);
    let err = null;
    !error.message ? (err = { message: error }) : (err = error);
    // throw new Error(err);
    console.log(err);
    return Promise.reject(err);
  }
}

async function recoverPassword(username) {
  try {
    // Send confirmation code to user's email
    const response = await Auth.forgotPassword(username);
    console.log(response);
    return response
  } catch (error) {
    console.log("error signing up:", error);
    let err = null;
    !error.message ? (err = { message: error }) : (err = error);
    // throw new Error(err);
    console.log(err);
    return Promise.reject(err);
  }
}


async function resetPassword(username, code, new_password) {
    try {
      // Collect confirmation code and new password, then
      const response = await Auth.forgotPasswordSubmit(username, code, new_password);
      console.log(response);
      return response;
    } catch (error) {
      console.log("error signing up:", error);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      // throw new Error(err);
      console.log(err);
      return Promise.reject(err);
    }
  }

  

async function logout() {
  // remove user from local storage to log user out
  await Auth.signOut();
  authenticationService.isLoggedIn = false;
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
