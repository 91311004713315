import React from "react";
import { Trans } from "react-i18next";

const BoxLayout = (props) => {
  const { title, helpInfo = null, toggleHelpInfo, className = 'tables-2' } = props;
  return (
    <React.Fragment>
      <div className={className}>
        {props.children}

        {/**
         * Help Info block
         */}
        <div className="div-block-970">
          <div className="text-block-439">{title}</div>
          {helpInfo && (
            <div className="div-block-1009">
              <div
                data-w-id="36120118-9663-9734-c176-e2b63ab875ad"
                className="help_table" onClick={toggleHelpInfo}
              >
                <div className="text-block-440" ><Trans>shared.actions.help</Trans></div>
              </div>
            </div>
          )}
          <div className="div-block-971"></div>
        </div>
        {/**
         * Call Overlay
         */}
        {helpInfo && helpInfo()}
      </div>
    </React.Fragment>
  );
};

export default BoxLayout;
