import React, { useState } from 'react';
import Timer from 'react-compound-timer';



export const DateTimeClock = (props) => {
    
    const calculateClockStartTime = () => {
        let currentDate = new Date();
        let hh = currentDate.getHours();
        let mm = currentDate.getMinutes();
        let ss = currentDate.getSeconds();

        // console.log((hh * mm * 3660 + ss) * 1000);
        return ((hh * 3600) + (mm * 60) + ss) * 1000;
    }

    /**
     * Generated Date should be on DD/MM/YYYY format
     */
    const getCurrentDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;
        // console.warn(today);
        return today;
    }

    const currentTimeStamp = calculateClockStartTime(); // Default Valure would be 1 Second;
    const lastTimeOfDay = 86400000;
    // console.log(currentTimeStamp);
    const [currentDate, setcurrentDate] = useState(getCurrentDate());

    /**
     * Calculates total number of seconds in present time  
     */

    return (
        <>
            <div className="div-block-716">
                <Timer initialTime={currentTimeStamp}
                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)} `}
                    checkpoints={
                        [{
                            time: lastTimeOfDay,
                            callback: () => setcurrentDate(getCurrentDate()),
                        }]
                    } >
                    <div className="text-block-233">
                        <Timer.Hours formatValue={(value) => `${(value < 10 ? `0${value}` : value)}:`} />
                        <Timer.Minutes />
                        {/* <Timer.Seconds /> */}
                    </div>
                    <div className="text-block-232">{currentDate}</div>
                </Timer>
            </div>
        </>
    )
}

export default DateTimeClock

