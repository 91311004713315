import React, { useContext, useEffect, useState } from "react";
import TopSellerCard from "./TopSellerCard";
import "./topSeller.css";
import { filterConst, days } from "../constant";
import HomeContext from "../../../Providers/Home/HomeContext";
import Moment from "moment";
import _ from "lodash";

const TopSellerContainer = () => {
  const homeService = useContext(HomeContext);
  const [topSellerDisplayList, setTopSellerDisplayList] = useState([]);
  const [filterType, setFilterType] = useState("Today");

  const filterData = (startDate, endDate, type) => {
    startDate = new Date(startDate).setHours(0, 0, 0, 0);
    endDate = new Date(endDate).setHours(0, 0, 0, 0);
    let result = homeService.itemList.filter((product) => {
      let productDate = new Date(product.date).setHours(0, 0, 0, 0);
      if (type == filterConst.Monthly) {
        return true;
      } else {
        if (startDate <= productDate && endDate >= productDate) {
          return true;
        }
      }
    });
    console.log("result of filter", result);
    groupByRecords(result);
    if (type != filterType) {
      setFilterType(type);
    }
  };

  const groupByRecords = (result) => {
    // console.log(result);
    let newRecords = _(result)
      .groupBy((item) => item.productId)
      .map(function (value, key) {
        // console.log(value, key);
        return {
          quantity: value.reduce(
            (sum, item) => sum + parseInt(item.quantity, 10),
            0
          ),
          productId: key,
          productName: value[0].productName,
          imageUrl: value[0].imageUrl,
          // date: value[0].imageUrl,
        };
      })
      .value();
      newRecords = _.sortBy(newRecords, item => item.quantity).reverse();

    // console.log('newRecords', newRecords);
    setTopSellerDisplayList([...newRecords]);
  };

  const filterTopSellerData = (type) => {
    // console.log('=========================', Moment().startOf('week').format('MM/DD/YYYY'))
    // console.log()

    let endDate;
    let startDate;
    switch (type) {
      case filterConst.Today:
        startDate = new Date();
        endDate = new Date();
        break;

      case filterConst.Weekly:
        startDate = new Date(Moment().startOf("week").format("MM/DD/YYYY"));
        endDate = new Date(Moment().endOf("week").format("MM/DD/YYYY"));
        break;

      case filterConst.Monthly:
        startDate = new Date(Moment().startOf("month").format("MM/DD/YYYY"));
        endDate = new Date(Moment().endOf("month").format("MM/DD/YYYY"));
        break;

      default:
        endDate = new Date();
        break;
    }
    filterData(startDate, endDate, type);
    console.log("type", type);
  };

  const renderTopSellerList = () => {
    return topSellerDisplayList.map((data, index) => {
      return <TopSellerCard cardDetails={data} key={index} />;
    });
  };

  useEffect(() => {
    filterTopSellerData(filterConst.Today);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(homeService.itemList)]);

  useEffect(() => {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    homeService.getItemList(lastDay, firstDay).then((res) => {
      homeService.setPageLoaded(true);
    });
  }, []);
  console.log("itemList", homeService.itemList, topSellerDisplayList);

  const getActiveTabStyle = (tab) => {
    let styles = {};
    if (tab && tab === filterType) {
      styles = { color: "#ff414d" };
    }
    return styles;
  };

  return (
    <div
      id="w-node-a1e3843a-48df-3072-f934-860874b51d67-999f542c"
      className="div-block-877"
    >
      <div className="div-block-881">
        <div className="todaysell topSellContainer">
          <div className="w-layout-grid gridsellertoday">
            {renderTopSellerList()}
          </div>
        </div>
      </div>
      <div className="div-block-878">
        <div className="text-block-345">Topseller</div>
        <div
          className="text-block-346-today"
          style={getActiveTabStyle(filterConst.Today)}
          onClick={(e) => filterTopSellerData(filterConst.Today)}
        >
          Today
        </div>
        <div
          className="text-block-346-week"
          style={getActiveTabStyle(filterConst.Weekly)}
          onClick={(e) => filterTopSellerData(filterConst.Weekly)}
        >
          Week
        </div>
        <div
          className="text-block-346-month"
          style={getActiveTabStyle(filterConst.Monthly)}
          onClick={(e) => filterTopSellerData(filterConst.Monthly)}
        >
          Month
        </div>
      </div>
    </div>
  );
};

export default TopSellerContainer;
