import React from 'react';

const TopSellerCard = ({ cardDetails }) => {
    
    const getStyles = () => {
        if(cardDetails.imageUrl){
            return {
                backgroundImage: `url(${cardDetails.imageUrl})`
            }
        }
        return {};
        
    }

    return (
        <div className={(!cardDetails.imageUrl ? 'top7-nopic': 'topSellerCard top7')} style={getStyles()}>
            <div className="div-block-876">
                <div className="text-block-344">{cardDetails.productName}</div>
                <div className="text-block-520">{cardDetails.quantity}</div>
            </div>
        </div>
    )
}

export default TopSellerCard;