


import React, { useEffect, forwardRef,  useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, toCurrency } from "../Helpers/Utils";
import { Trans } from 'react-i18next';

const DayPicker = forwardRef((props, ref) => {

    const activeTimingsDefaults = () => {
        return {
                mo: false,
                tu: false,
                we: false,
                th: false,
                fr: false,
                sa: false,
                su: false
            }
    }

    const [activeTimings, setActiveTimings] = useState(activeTimingsDefaults());

    useEffect(() => {
        if (!isEmpty(props.activeDays)) {
            if (props.activeDays) {
                setActiveTimings(props.activeDays);
            }
        }

        // console.warn(categoryForm);
    }, [props.activeDays]);


    const onSelectDeselectActiveDays = (day) => {
        activeTimings[day] = !activeTimings[day];
        // setActiveTimings({ ...activeTimings });
        props.onDayChange({...activeTimings});
    }

    const getActiveDayStyle = (day) => {
        let styles = {};
        if (activeTimings && activeTimings[day]) {
            styles = { 'backgroundColor': '#17a5b6' }
        }
        return styles;
    }


    return (
        <React.Fragment>
            <div className="text-block-144"><Trans>shared.days.activeDays</Trans></div>
            <div className="div-block-581" >
                <div className="text-block-140" style={getActiveDayStyle('mo')} onClick={() => onSelectDeselectActiveDays('mo')}><Trans>shared.days.mo</Trans></div>
                <div className="text-block-140" style={getActiveDayStyle('tu')} onClick={() => onSelectDeselectActiveDays('tu')}><Trans>shared.days.tu</Trans></div>
                <div className="text-block-140" style={getActiveDayStyle('we')} onClick={() => onSelectDeselectActiveDays('we')}><Trans>shared.days.we</Trans></div>
                <div className="text-block-140" style={getActiveDayStyle('th')} onClick={() => onSelectDeselectActiveDays('th')}><Trans>shared.days.th</Trans></div>
                <div className="text-block-140" style={getActiveDayStyle('fr')} onClick={() => onSelectDeselectActiveDays('fr')}><Trans>shared.days.fr</Trans></div>
                <div className="text-block-140" style={getActiveDayStyle('sa')} onClick={() => onSelectDeselectActiveDays('sa')}><Trans>shared.days.sa</Trans></div>
                <div className="text-block-140" style={getActiveDayStyle('su')} onClick={() => onSelectDeselectActiveDays('su')}><Trans>shared.days.su</Trans></div>
            </div>
        </React.Fragment>
    )
});

DayPicker.propTypes = {
    activeDays: PropTypes.object.isRequired,
}; 

/* DayPicker.defaultProps = {
    currency: APP_CONFIG.CURRENCY
};

DayPicker.propTypes = {
    price: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
    currency: PropTypes.string,
    locale: PropTypes.string
}; */

export default DayPicker;

