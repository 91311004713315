import React from 'react';
import { Trans } from 'react-i18next';
import { ICONS } from '../../../Helpers/Icon.service';

const HotlineInfo = ({ hotlineInfo }) => {
    const hotlineIcon = ICONS.HOTLINE_ICON;

    return (
        <div id="w-node-_875c5b9c-5eec-b57e-771d-894f367d49be-fe54d577" className="div-block-667 data-content-section">
            <div className="text-block-390"><Trans>pages.profile.support.hotlines</Trans></div>
            <div className="text-block-391"><Trans>pages.profile.support.fastSupport</Trans>{hotlineInfo.fastSupport}</div>
            <div className="text-block-391"><Trans>pages.profile.support.techSupport</Trans>{hotlineInfo.technicalSupport}</div>
            <div className="text-block-391"><Trans>pages.profile.support.sellingSupport</Trans>{hotlineInfo.sellings}</div>
            <div className="text-block-391"><Trans>pages.profile.support.jobSupport</Trans>{hotlineInfo.jobs}</div>
            <img src={hotlineIcon} loading="lazy" width="55" height="55" alt="" className="image-194"></img>
        </div>
    )
}

export default HotlineInfo;