import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../constants/app.constant";
import { authenticationService } from "../../Services/auth.service";
import ErrorMessage from "../../SharedComponents/ErrorMessage";

export const SignUp = (props) => {
  const passwordRef = useRef(null);
  const { isOpen } = props;
  const [serverError, setserverError] = useState({
    status: null,
    message: ''
  });
  const [serverSuccess, setserverSuccess] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
  } = useForm({ mode: "onSubmit" });

  const resetServerMessages = () => {
    setserverError({
      status: null,
      message: ''
    });
    setserverSuccess(false);
  }


  const onRegisterUser = (data) => {
    console.log(data);
    resetServerMessages();

    authenticationService.signUp(data.email, data.password).then(
      (user) => {
        console.log(user);
        resetServerMessages();
        setserverSuccess(true);
      },
      (error) => {
        console.log(error);
        setserverError({
          status: 'error',
          message: error.message
        });
      }
    );
  }

  const validationOptions = {
    // username: { required: "Username is required" },
    email: { 
      required: "Email is required",
      pattern: {
        value: EMAIL_REGEX,
        message: "Email is invalid",
      }
    },
    password: {
      required: "Password is required",
      pattern: {
        value: PASSWORD_REGEX,
          message: 'Should have at least One Uppercase, one lower case, One digit, One special character and at least 8 charcters',
      }
    },
    cPassword: {
      required: "Retype Password is required",
      validate: value =>
            value === getValues('password') || "The passwords do not match"
    },
    acceptTerms: {
      required: "Please accept terms and conditions"
    }
  };

  


  return (
    <nav
      className={
        "dropdown-list-26 w-dropdown-list " + (isOpen ? "w--open" : "")
      }
      id="w-dropdown-list-2"
      aria-labelledby="w-dropdown-toggle-2"
    >
      <div id="signin" className="signinblock w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          method="get"
          className="form"
          onSubmit={handleSubmit(onRegisterUser)}
        >
          {/* <input
            type="text"
            className="text-field-3 w-input"
            maxLength="256"
            name="eMail-2"
            data-name="E Mail 2"
            placeholder="Username"
            id="username"
            {...register( 'username' , validationOptions.username)}
          /> 
          {errors.username && <ErrorMessage text={errors?.username?.message} />}*/}
          <input
            type="text"
            className="text-field-3 w-input"
            maxLength="256"
            name="email"
            data-name="E Mail 2"
            placeholder="eMail"
            id="eMail"
            {...register( 'email' , validationOptions.email)}
          />
          {errors.email && <ErrorMessage text={errors?.email?.message} />}
          <input
            type="password"
            className="text-field-2 w-input"
            maxLength="256"
            name="password"
            data-name="Password"
            placeholder="Password"
            id="Password"
            ref={passwordRef} 
            {...register('password', validationOptions.password)}
          />
          {errors.password && <ErrorMessage text={errors?.password?.message} />}
          <input
            type="password"
            className="text-field-2 w-input"
            maxLength="256"
            name="cPassword"
            data-name="Password 2"
            placeholder="Retype Password"
            id="Password-2"
            {...register("cPassword", validationOptions.cPassword)}
          />
          {errors.cPassword && <ErrorMessage text={errors?.cPassword?.message} />}
          <label className="w-checkbox checkbox-field-15">
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              data-name="Checkbox"
              className="w-checkbox-input checkbox-15"
              {...register('acceptTerms', validationOptions.acceptTerms)}
            />
            <span className="checkbox-label-16 w-form-label">AGB´s/Policy</span>
          </label>
          {errors.acceptTerms && <ErrorMessage text={errors?.acceptTerms?.message} />}
          <input
            type="submit"
            value="sign up now"
            data-wait="Please wait..."
            className="submit-button w-button"
          />
        </form>
        
        <div className={"success-message w-form-done " + (serverSuccess ? 'd-block': '')}>
          <div className="text-block-56">Registered successfully. Kindly check your email.</div>
        </div>
        <div className={"error-message w-form-fail " + (serverError.status ? 'd-block' : '')}>
          <div className="text-block-57">{serverError.message}</div>
        </div>


        <div className="div-block-1001">
          <div className="text-block-461-copy">
            <a href="#impressum" className="link-12">
              Privacy Policy
            </a>
          </div>
          <div className="text-block-461-copy">
            <a href="#impressum" className="link-11">
              Impressum
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
