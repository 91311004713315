

export const filterConst = {
    Today: 'Today',
    Weekly: 'Weekly',
    Monthly: 'Monthly'
}

export const months = ['January', 'February', 'March',
    'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novermber', 'December'];

export const graphFeatures = {
    pointBackgroundColor: 'unset',
    pointBorderWidth: '0px',
    pointRadius: '0px',
    fill: false,
    lineTension: 0.5,
    showLine: true,
    backgroundColor: 'rgba(75,192,192,1)',
    borderColor: "#a80303",
    borderWidth: 2,
}

export const scaleFeatures = {
    drawBorder: true,
    drawOnChartArea: false,
    color: '#04048d'
}

export let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];