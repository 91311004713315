import React from "react";
import BoxLayout from "../../SharedComponents/BoxLayout";
import Wishes from "./Wishes";
import { useTranslation } from 'react-i18next';


const WishesBox = (props) => {
  const { t, i18n } = useTranslation();
  const { selectedTable } = props;

  const getContent = () => {
    if (props.isLoading) {
      return (
        <div
          data-w-id="f7a71ccf-eca6-f728-d23f-86c4d72c7116"
          className="pageloading"
        ></div>
      );
    }
    return (
      <React.Fragment>{selectedTable && <Wishes {...props} />}</React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <BoxLayout className="wish" title={t('pages.order.wishes')}>{getContent()}</BoxLayout>
    </React.Fragment>
  );
};

export default WishesBox;
