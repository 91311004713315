


import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { APP_CONFIG } from '../constants/Config';
import { TAG_ICONS } from '../Mock/mock-data';
import ErrorMessage from './ErrorMessage';
import { useTranslation } from 'react-i18next';



const ProductTagsIcon = (props) => {

    const { t, i18n } = useTranslation();
    const imagePath = APP_CONFIG.IMAGE_PATH + '/';
    const iconItems = TAG_ICONS;
    const title = props.title || t('pages.menu.tagIconLabel', { count:  props.maxSelect } ) // || t(`select up to  Icons`);

    const [iconList, setIconList] = useState(iconItems);

    const [customErrors, setCustomError] = useState({});

    useEffect(() => {
        if (props.selectedIcons) {
            mapSelectedItems(props.selectedIcons);
        }
    }, [props.selectedIcons]);

    const mapSelectedItems = ([...selectedItems]) => {
        if(selectedItems) {
            let chosenItemString = JSON.stringify(selectedItems).toLowerCase();
            let tempList = [...iconList];
            tempList.forEach(icon => {
                if(chosenItemString.indexOf(icon.name.toLowerCase()) > -1) {
                    icon.isSelected = true;
                }  else {
                    icon.isSelected = false;
                }
            });
            setIconList([...tempList]);
        }
    }


    const onSelectedDeselectIngredient = (name) => {

        let tempIconList = [...iconList];

        // Change this to ID rather then name in future
        // let foundIndex = tempIconList.findIndex(icon => icon.name.toLowerCase() === name.toLowerCase());
        let selectedItems = 0;
        let foundIndex = -1;
        tempIconList.forEach((icon, index) => {
            if(icon.name.toLowerCase() === name.toLowerCase()) {
                foundIndex = index;
            } 
            if(icon.isSelected) {
                selectedItems++;
            }
        });
        
        if (foundIndex > -1) {
            let item = tempIconList[foundIndex];
            if(selectedItems >= props.maxSelect && !item.isSelected) {
                customErrors.selectionError = t('pages.menu.errorMaxIcon', { count:  props.maxSelect } ); 
                setCustomError({ ...customErrors });

                    setTimeout(() => {
                        setCustomError({});
                    }, 5000);

                return ;
            } else {
                setCustomError({});
            } 
           
            // tempIconList[foundIndex] = {...item, isSelected: !item.isSelected  }
            // setIconList(tempIconList);
            props.onChange(name);
        }

    }



    return (
        <React.Fragment>
            <div className="div-block-580">
                <div className="text-block-137">{title}</div>
                <div className="w-layout-grid grid-12">
                    {iconList && iconList.map((icon, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className={"div-block-589 " + (icon.isSelected ? "active-item" : "")} onClick={() => onSelectedDeselectIngredient(icon.name)}>
                                    <img alt="image" src={imagePath + icon.image} loading="lazy" width="33" height="33" className="image-21" />
                                    <div className="text-block-143">{t(`pages.menu.${icon.name}`)}</div>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
            {customErrors?.selectionError && <ErrorMessage text={customErrors?.selectionError} />}
        </React.Fragment>
    )
}

ProductTagsIcon.propTypes = {
    selectedIcons: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
}; 

ProductTagsIcon.defaultProps = {
    maxSelect: 5,
    minSelect: 1
};
/* 
DayPicker.propTypes = {
    maxSelect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
    currency: PropTypes.string,
    locale: PropTypes.string
}; */


/* AppButton.propTypes = {
    message: PropTypes.string,
    onClick: PropTypes.func
  };
  
  AppButton.defaultProps = {
    message: 'Hello',
    onClick: function(){ alert("Hello"); }
  }; */

export default ProductTagsIcon;

