import React, { Component } from "react";
// import "./order.css";

/* import { APP_CONFIG } from "../../constants/Config";
import { toCurrency, transformDate } from "../../Helpers/Utils";
import CurrencyFormatter from "../../SharedComponents/Currency"; */
import OrderContext from "../../Providers/Order/OrderContext";
import OrderItems from "./OrderItems";

function convertMillisecondsIntoMin(milseconds) {
  if (milseconds && !isNaN(milseconds)) {
    var minutes = Math.floor(milseconds / 60000);
    var seconds = ((milseconds % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }
  return milseconds;
}

export default class index extends Component {
  static contextType = OrderContext;

/*   componentDidMount() {
    if (this.props.selectedTable) {
      // need to be called when Table is selected
      // this.checkIfAllItemsCooked();
    }
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.selectedTable.tableName !== newProps.selectedTable.tableName) {
      // this.getOrdersForSelectedTable()
    }
  } */

  
  

  render() {
    const allOrders = this.props.orders;
    console.log(allOrders);
    return (
      <React.Fragment>
        <div className="allorders" style={{ display: "flex" }}>
          {allOrders &&
            allOrders.map((order, OIndex) => {
              return (
                <React.Fragment key={order.orderId + "" + OIndex}>
                  <OrderItems order={order}/>
                </React.Fragment>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

/**
 * 
 * Different timers on the page
 * 1) Timer Single or multiple for individual order
 * 2) Timer for every single item in order
 * 3) Over time timer when item is going overtime
 */
