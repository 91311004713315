
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import './LoginLayout.css'
import Landing from '../../pages/Public/Landing';
import { ROUTES, ROUTES_PATH } from '../../constants/Routes';
import LogIn from '../../Views/Auth/LogIn';
import PasswordRecoverLayout from '../PasswordRecoverLayout';

/**
 * ToDo
 * 1) Try "Root" path and display landing page without changing route if Not Logged In 
 * 2) Display "Home" page without changing route if Logged In
 * 
 */


export class LoginLayout extends React.Component {
    default_route_if_logged_in = ROUTES.DEFAULT_IF_LOGGED_IN;
    constructor(props) {
        super(props);
        console.warn({...props});
    }

    render() {
        console.log("LoginLayout:: RENDER - 1")
        return (
            <>
            {console.log("LoginLayout:: RENDER - 2")}
                <Route
                    {...this.props}
                    render={ props =>
                        !this.props.isLoggedIn ? (
                           <React.Fragment>
                               {/* "isLoggedIn=false , LoginLayout" */}
                                <Switch>
                                   {/*  { <Route exact path="/" component={Login}/>} */}
                                    <Route exact path="/" render={(props) => <Landing {...this.props} />} />
                                    {/* <Route path={ROUTES_PATH.LOGIN} {...props} component={LogIn} /> */}
                                    {/* <Route path={ROUTES_PATH.FORGOT_PASSWORD} render={(props) => <PasswordRecoverLayout {...this.props} />} /> */}
                                    <Route path={ROUTES_PATH.PASSWORD} render={(props) => <PasswordRecoverLayout {...this.props} />} />
                                    <Route path={ROUTES_PATH.LOGIN} render={(props) => {
                                        return (
                                            <React.Fragment>
                                                {console.log("==>", this.props)}
                                                <LogIn {...this.props} />
                                            </React.Fragment>
                                        )
                                    } } />
                                    <Route path={ROUTES_PATH.LANDING} render={(props) => <Landing {...this.props} />} />
                                </Switch>
                           </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    {/* "isLoggedIn=true , Redirect in LoginLayout" */}
                                    {<Redirect
                                        to={{
                                            pathname: this.default_route_if_logged_in,
                                            state: { from: props.location }
                                        }}
                                    />}
                                </React.Fragment>
                            )
                    }
                />
            </>
        );
    }
}
