import React, { useContext, useEffect, useState } from 'react';
import { regexForEmail } from '../constant';
import { APP_CONFIG } from '../../../constants/Config';
import { ICONS } from '../../../Helpers/Icon.service';
import ProfileContext from '../../../Providers/Profile/ProfileContext';
import LoadingIndicator from '../../../SharedComponents/Loading';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


const BusinessInfo = ({ ...rest }) => {
    const { t, i18n } = useTranslation();
    const settingsIcon = ICONS.SETTINGS_DARK;
    const imgPath = APP_CONFIG.IMAGE_PATH;
    const profileService = useContext(ProfileContext);
    const { businessInfo } = profileService;
    const [editForm, setEditform] = useState(false);
    const [adminForm, setAdminForm] = useState({
        businessName: "",
        businessImage: "", 
        businessWebpage: "",
        businessTownZip: "",
        businessStreetNr: "",
        businessCountry: "",
        businessPhone: "",
        businessEmail: "",
        businessTaxInfo: ''
    })
    const handleInputChange = (e, type) => {
        let tempForm = { ...adminForm };
        let value = e.target.value;
        tempForm[type] = value;
        tempForm[`${type}Error`] = null;
        let result = handleValidation(tempForm, type, value);
        setAdminForm(result[1]);
    }

    const handleValidation = (adminForm, type, value) => {
        let valid = true;
        console.log("admin valdato", adminForm, type, value)
        if (value == '') {
            valid = false;
            adminForm = {
                ...adminForm,
                [`${type}Error`]: `${(type.charAt(0).toUpperCase() + type.slice(1)).split(/(?=[A-Z])/).join(" ")} Required`
            }
        } else {
            valid = true;
            adminForm = {
                ...adminForm,
                [`${type}Error`]: null
            }
        }
        if (type === 'businessPhone' && value.toString().length < 9 && valid) {
            valid = false;
            adminForm.businessPhoneError = 'Phone must be 9 character';
        }
        if ((type === 'businessName' || type === 'businessStreetNr' || type === 'businessTownZip' || type === 'businessWebpage' ) && valid) {
            if (value == '' || value.toString().length < 3) {
                valid = false;
                adminForm[`${type}Error`] = `${(type.charAt(0).toUpperCase() + type.slice(1))} must be 3 characters`
            }
        }
        if (type === 'businessEmail' && valid) {
            let emailRegex = regexForEmail;
            if (!emailRegex.test(value)) {
                valid = false;
                adminForm.businessEmailError = 'Proper Email Required'
            }
        }

        return [valid, adminForm]
    }

    const formValidation = (adminForm) => {
        let tempAdminForm = { ...adminForm };
        let valid = true;
        let tempValid = false;
        let adminProps = Object.entries(adminForm);
        adminProps.map(([key, value]) => {
            let result = [false, tempAdminForm];
            if (key.includes('Error') || key === 'businessTaxInfo' || key === 'businessCountry' || key === "businessImage") {
                return [key, value];
            }
            else {
                result = handleValidation(tempAdminForm, key, value);
            }
            tempValid = result[0];
            tempAdminForm = result[1];
            if (valid && !tempValid) {
                valid = tempValid;
            }
            return [key, value];
        });
        setAdminForm({ ...tempAdminForm });
        return valid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let result = formValidation(adminForm);
        if (result) {
            profileService.updateBusinessInfo({
                informationType : "BUSINESSINFO",
                businessName: adminForm.businessName,
                businessImage: businessInfo.businessImage,
                businessWebpage: adminForm.businessWebpage,
                businessTownZip: adminForm.businessTownZip,
                businessStreetNr: adminForm.businessStreetNr,
                businessCountry: adminForm.businessCountry,
                businessPhone: adminForm.businessPhone,
                businessEmail: adminForm.businessEmail,
            });
            setEditform(false);
        }
    }

    const handleFormDisplay = () => {
        setAdminForm({
            businessName: "",
        businessImage: "", 
        businessWebpage: "",
        businessTownZip: "",
        businessStreetNr: "",
        businessCountry: "",
        businessPhone: "",
        businessEmail: "",
        businessTaxInfo: '',
            ...businessInfo
        });
        setEditform(!editForm);
    }

    useEffect(() => {
        setAdminForm({
            businessName: "",
        businessImage: "", 
        businessWebpage: "",
        businessTownZip: "",
        businessStreetNr: "",
        businessCountry: "",
        businessPhone: "",
        businessEmail: "",
        businessTaxInfo: '',
            ...businessInfo
        })
    }, [])  

    if(!profileService.isPageLoaded ){
        return <div className="data-content-section"><LoadingIndicator /></div>;
    }

    return (
        <div id="w-node-ab21de08-7743-47ab-c5be-cb0bf8c84324-fe54d577" className="div-block-667 data-content-section">
            <img src={`${imgPath}/house_small_lightAsset-1.svg`} loading="lazy" width="55" height="55" alt="" className="image-194"/>
            {!editForm ? <img src={settingsIcon} loading="lazy" width="17" height="17" onClick={() => handleFormDisplay()}
                data-w-id="ab21de08-7743-47ab-c5be-cb0bf8c84325" alt="" className="image-128" /> : ''}
            {!editForm ? <div className="div-block-951">
                <div className="text-block-390"><Trans>pages.profile.businessInfo.title</Trans></div>
                <div className="text-block-393"><Trans>pages.profile.businessInfo.business</Trans></div>
                <div className="text-block-391">{businessInfo.businessName}<br />{businessInfo.businessStreetNr}
                    <br />{businessInfo.businessTownZip} {(businessInfo.businessCountry ? businessInfo.businessCountry : '')}
                    <br /><Trans>pages.profile.shared.tel</Trans> {businessInfo.businessPhone}<br /><Trans>pages.profile.businessInfo.taxreg</Trans>{businessInfo.businessTaxInfo}</div>
                <div className="text-block-393"><Trans>pages.profile.shared.webEmail</Trans></div>
                <div className="text-block-391">{businessInfo.businessWebpage}<br />{businessInfo.businessEmail}</div>
            </div> :
                <div className=" w-form">
                    <form name="email-form-2" data-name="Email Form 2" className="form-138">
                        <div className="text-block-391-copy"><Trans>pages.profile.businessInfo.title</Trans></div>
                        <input type="text" value={adminForm.businessName} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.businessInfo.placeholdBusinessName')} id="name-2" onChange={e => handleInputChange(e, 'businessName')} />
                        {adminForm.businessNameError ? <p className='validationsError'>*{adminForm.businessNameError}</p> : null}
                        <input type="text" value={adminForm.businessStreetNr} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.businessInfo.placeholdAddress')} id="name-2" onChange={e => handleInputChange(e, 'businessStreetNr')} />
                        {adminForm.businessStreetNrError ? <p className='validationsError'>*{adminForm.businessStreetNrError}</p> : null}
                        <input type="text" value={adminForm.businessTownZip} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.businessInfo.placeholdZip')} id="name-2" onChange={e => handleInputChange(e, 'businessTownZip')} />
                        {adminForm.businessTownZipError ? <p className='validationsError'>*{adminForm.businessTownZipError}</p> : null}
                        <input type="text" value={adminForm.businessPhone} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.businessInfo.placeholdPhone1')} id="name-2" onChange={e => handleInputChange(e, 'businessPhone')} />
                        {adminForm.businessPhoneError ? <p className='validationsError'>*{adminForm.businessPhoneError}</p> : null}
                        <input type="text" value={adminForm.businessTaxInfo} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.businessInfo.placeholdTax')} id="name-2" onChange={e => handleInputChange(e, 'businessTaxInfo')} />
                        {adminForm.businessTaxInfoError ? <p className='validationsError'>*{adminForm.businessTaxInfoError}</p> : null}
                        <div className="text-block-391-copy"><Trans>pages.profile.shared.emailWeb</Trans></div>
                        <input type="text" value={adminForm.businessEmail} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.businessInfo.placeholdEmail')} id="name-2" onChange={e => handleInputChange(e, 'businessEmail')} />
                        {adminForm.businessEmailError ? <p className='validationsError'>*{adminForm.businessEmailError}</p> : null}
                        <input type="text" value={adminForm.businessWebpage} className="text-field-177 w-input" maxLength="256" name="name-2" data-name="Name 2"
                            placeholder={t('pages.profile.shared.webpage')} id="name-2" onChange={e => handleInputChange(e, 'businessWebpage')} />
                        {adminForm.businessWebpageError ? <p className='validationsError'>*{adminForm.businessWebpageError}</p> : null}
                        <div className="div-block-952">
                            <div className="closepersonal" onClick={() => handleFormDisplay()}><Trans>shared.actions.cancel</Trans></div>
                            <input type="submit" value={t('shared.actions.save')} onClick={e => handleSubmit(e)} className="submit-button-25 w-button" />
                        </div>
                    </form>
                </div>}
        </div>
    )
}

export default BusinessInfo;