import React from "react";
import { ICONS } from "../../Helpers/Icon.service";

const InfoCard = ({ wishesRealised, whatsNew }) => {
  const gastronIcon = ICONS.GASTRON_ICON;
  const youtubeIcon = ICONS.YOUTUBE_ICON;
  const instaIcon = ICONS.INSTAGRAM_ICON;
  const facebookIcon = ICONS.FACEBOOK_ICON;
  const calenderIcon = ICONS.CALENDER_ICON;
  const refreshIcon = ICONS.REFRESH_LIGHT_ICON;
  const commentIcon = ICONS.COMMENT_ICON;
  const infoIcon = ICONS.INFO_ICON;

  return (
    <div className="div-block-866">
      <div className="div-block-1062">
        <img
          src={gastronIcon}
          loading="lazy"
          height="66"
          alt=""
          className="image-112"
        />
        <div className="text-block-342">your all-in-one</div>
        <div className="div-block-920">
          <a
            href="https://www.youtube.com/"
            className="link-block-7 w-inline-block"
          >
            <img
              src={youtubeIcon}
              loading="lazy"
              width="22"
              height="22"
              alt=""
            />
          </a>
          <a
            href="https://www.instagram.com/"
            className="link-block-8 w-inline-block"
          >
            <img src={instaIcon} loading="lazy" width="22" height="22" alt="" />
          </a>
          <a
            href="https://www.facebook.com/"
            className="link-block-9 w-inline-block"
          >
            <img
              src={facebookIcon}
              loading="lazy"
              width="22"
              height="22"
              alt=""
            />
          </a>
        </div>
      </div>

      <div className="div-block-867">
        <img
          src={infoIcon}
          loading="lazy"
          width="22"
          height="22"
          alt=""
          className="image-116"
        />
        <div className="text-block-336">This Weeks Summary</div>
        <div className="text-block-337">
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
          amet.
        </div>
        <div className="text-block-336">Top News</div>
        <div className="text-block-337">
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren.
        </div>
        <div className="text-block-337">
          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
          amet...
        </div>
        <div className="text-block-515">
          <a
            href="home.html"
            target="_blank"
            aria-current="page"
            className="link-17 w--current"
          >
            more
          </a>
        </div>
      </div>

      <div className="div-block-869">
        <div className="text-block-338-copy">Gastron Hotline</div>
        <div className="text-block-338">-49 555 1562 32 554</div>
      </div>
    </div>
  );
};

export default InfoCard;
