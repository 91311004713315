import React from 'react';

const GastronView = (props) => {

    return (
        <React.Fragment>
             <div data-w-tab="Gastron" className="tab-pane-gastron">
                <div>coming soon...</div>
            </div>
        </React.Fragment>
    )
}

export default GastronView;