

import React from 'react';
import Header from '../LayoutComponents/Header';
import { Switch, Route, useHistory } from 'react-router-dom';
import { ROUTES_PATH } from "../constants/Routes";
import Home from '../pages/Home';
import Manage from '../../src/pages/Manage'
import Profile from '../../src/pages/Profile'
import Order from '../../src/pages/Order'
import Menu from '../pages/Menu';
import ProfileProvider from '../Providers/Profile/ProfileProvider';
import './mainLayout.css'
import HomeProvider from '../Providers/Home/HomeProvider';

export const MainLayout = (props) => {
    const PATH_ROUTES = ROUTES_PATH;
    console.log("---------------MainLayout-------------------")
    console.log({...props});
    let history = useHistory();

    return (
        <React.Fragment>
            <Header />
            <React.Fragment>
                <div style={{display: 'contents'}}>
                    <Switch>
                        <Route path={PATH_ROUTES.MENU} render={() => <Menu />} />
                        <Route path={PATH_ROUTES.MANAGE} render={() => <Manage />} />
                        <Route path={PATH_ROUTES.ORDER} render={() => <Order />} />
                        <Route path={PATH_ROUTES.HOME} render={() => <HomeProvider history={history}> 
                            <Home />
                            </HomeProvider>} />
                        {/* <Route path={PATH_ROUTES.PROFILE}>
                            <Profile />
                        </Route> */}
                       {<Route path={PATH_ROUTES.PROFILE} render={() =>  <ProfileProvider history={history}>
                           <Profile/>
                       </ProfileProvider>} />}
                    </Switch>
                </div>
            </React.Fragment>

        </React.Fragment>
    )
}

