import React, { useState } from 'react';
import StatsGraphOverlay from './StatsGraphOverlay';
import { Line } from 'react-chartjs-2';
import { months, graphFeatures, filterConst, scaleFeatures } from '../constant';
import { weekData } from '../data';

const StatsGraph = () => {
    const [graphState, setGraphState] = useState({
        labels: [...months],
        datasets: [
            {
                ...graphFeatures,
                label: filterConst.Weekly,
                data: [...weekData]
            }
        ]
    });
    const [showInspect, setShowInspect] = useState(false);

    return (
        <React.Fragment>
            <div className="div-block-882">
                <div className="graphstoday"></div>
                <div className="graphsweek"></div>
                <div className="overlay">
                    <div className="div-block-964">
                        <div className="div-block-963">
                            <div data-hover="false" data-delay="0" className="dropdown-17-copy w-dropdown">
                                <div className="dropdown-toggle-15-copy w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <div className="text-block-355">select</div>
                                </div>
                                <nav className="dropdown-list-15 w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2">
                                    <div className="form-block-60 w-form">
                                        <form id="email-form" name="email-form" data-name="Email Form" className="form-144">
                                            <div className="div-block-1063">
                                                <div className="text-block-516">Products</div>
                                                <label className="w-checkbox checkbox_cat">
                                                    <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" className="w-checkbox-input checkbox-18" />
                                                    <span className="checkbox-label-19 w-form-label">Sells Profit</span>
                                                </label>
                                                <label className="w-checkbox checkbox_cat">
                                                    <input type="checkbox" id="checkbox-2" name="checkbox-2" data-name="Checkbox 2" className="w-checkbox-input checkbox-18" />
                                                    <span className="checkbox-label-19 w-form-label">Sells Overall</span>
                                                </label>
                                                <label className="w-checkbox checkbox_cat">
                                                    <input type="checkbox" id="checkbox-2" name="checkbox-2" data-name="Checkbox 2" className="w-checkbox-input checkbox-18" /><span className="checkbox-label-19 w-form-label">Quantity</span></label><label className="w-checkbox checkbox_cat">
                                                    <input type="checkbox" id="checkbox-2" name="checkbox-2" data-name="Checkbox 2" className="w-checkbox-input checkbox-18" /><span className="checkbox-label-19 w-form-label">Soft Drinks</span></label>
                                            </div>
                                            <div className="div-block-1063">
                                                <div className="text-block-516">Guests</div><label className="w-checkbox checkbox_cat">
                                                    <input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3" className="w-checkbox-input checkbox-18" /><span className="checkbox-label-19 w-form-label">Check-Ins</span></label><label className="w-checkbox checkbox_cat">
                                                    <input type="checkbox" id="checkbox-2" name="checkbox-2" data-name="Checkbox 2" className="w-checkbox-input checkbox-18" /><span className="checkbox-label-19 w-form-label">Waitings</span></label><label className="w-checkbox checkbox_cat">
                                                    <input type="checkbox" id="checkbox-2" name="checkbox-2" data-name="Checkbox 2" className="w-checkbox-input checkbox-18" /><span className="checkbox-label-19 w-form-label">Overtimes</span></label>
                                            </div>
                                        </form>
                                        <div className="w-form-done">
                                            <div>Thank you! Your submission has been received!</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>Oops! Something went wrong while submitting the form.</div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="dropdown-17-copy w-dropdown">
                                <div className="dropdown-toggle-15-copy w-dropdown-toggle" id="w-dropdown-toggle-3" aria-controls="w-dropdown-list-3" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <div className="text-block-355">from</div>
                                </div>
                                <nav className="dropdown-list-15 w-dropdown-list" id="w-dropdown-list-3" aria-labelledby="w-dropdown-toggle-3">
                                    <div className="month">
                                        <div className="div-block-732">
                                            <div className="div-block-734"><img src="images/left.svg" loading="lazy" width="15" alt="" /></div>
                                            <div className="text-block-238">Februar 2021</div>
                                            <div className="div-block-733"><img src="images/right.svg" loading="lazy" width="15" alt="" /></div>
                                        </div>
                                        <div className="w-layout-grid grid-30">
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">2</div>
                                            <div className="text-block-228">3</div>
                                            <div className="text-block-228">4</div>
                                            <div className="text-block-228">5</div>
                                            <div className="text-block-228">6</div>
                                            <div className="text-block-228">7</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">11</div>
                                            <div className="text-block-228">12</div>
                                            <div className="text-block-228">13</div>
                                            <div className="text-block-228">14</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228-copy">20</div>
                                            <div className="resfor1">1</div>
                                            <div className="resfor2">1</div>
                                            <div className="text-block-228">23</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">31</div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="dropdown-18-copy w-dropdown">
                                <div className="dropdown-toggle-15-copy w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <div className="text-block-355">to</div>
                                </div>
                                <nav className="dropdown-list-15 w-dropdown-list" id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4">
                                    <div className="month">
                                        <div className="div-block-732">
                                            <div className="div-block-734"><img src="images/left.svg" loading="lazy" width="15" alt="" /></div>
                                            <div className="text-block-238">Februar 2021</div>
                                            <div className="div-block-733"><img src="images/right.svg" loading="lazy" width="15" alt="" /></div>
                                        </div>
                                        <div className="w-layout-grid grid-30">
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">2</div>
                                            <div className="text-block-228">3</div>
                                            <div className="text-block-228">4</div>
                                            <div className="text-block-228">5</div>
                                            <div className="text-block-228">6</div>
                                            <div className="text-block-228">7</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">11</div>
                                            <div className="text-block-228">12</div>
                                            <div className="text-block-228">13</div>
                                            <div className="text-block-228">14</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228-copy">20</div>
                                            <div className="resfor1">1</div>
                                            <div className="resfor2">1</div>
                                            <div className="text-block-228">23</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">1</div>
                                            <div className="text-block-228">31</div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="text-block-421">11/09/01 - 14/07/21</div>
                            <div className="text-block-421-reset">reset</div>
                        </div>
                    </div>
                    <div className="aa">A</div>
                    <div className="bb">B</div>
                    <div className="dd">2</div>
                    <div className="ee">1</div>
                    <div className="text-block-422">Mouseover Pop<br />Item Description<br />Data I<br />Data II<br />Data III</div>
                </div>
            </div>
            <div className="div-block-878">
                <div className="text-block-345">Stats</div>
                <div data-w-id="d3ba4482-98c0-fda9-5e3c-7f1cc2892cac" className="showtoggle">Inspect</div>
                <div data-w-id="35f9b19c-1383-c025-800b-5c915d1208cf" className="text-block-346">Day</div>
                <div data-w-id="d3ba4482-98c0-fda9-5e3c-7f1cc2892cae" className="text-block-346">Week</div>
                <div data-w-id="e9158ca0-6dcb-87be-3163-a3459aaade62" className="text-block-346">month</div>
                <div className="text-block-346">Reset</div>
            </div>
        </React.Fragment>

    )
}

export default StatsGraph;