import React, { useRef, useState } from "react";
import { SignUp } from "../../../Views/Auth/SignUp";
import { ICONS } from "../../../Helpers/Icon.service";
import LanguageSelector from "../../../SharedComponents/LanguageSelector";
import OutSideCloseHelper from "../../../SharedComponents/OutSideCloseHelper";
import LogIn from "../../../Views/Auth/LogIn";
import "../Landing/style/style.css";

export const PageHeader = (props) => {
  const LOGO_FULL = ICONS.GASTRON_LOGO2;
  const plusIcon = ICONS.PLUS_ICON;
  const closeRedIcon = ICONS.CLOSE_RED_ICON;
  const modalRef = useRef(null);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [signupDropdown, setSignupDropdown] = useState(false);
  OutSideCloseHelper(modalRef, () => setDisplayDropdown(false));
  return (
    <>
      <div id="navbar" className="navbarHeader">
        <img
          className="navBarLogo"
          src={LOGO_FULL}
          loading="lazy"
          alt=""
        />
        <LanguageSelector type="medium" className="dropdown-29" />
        <div data-hover="" data-delay="0" id="loginWrapper" className="" ref={modalRef}>
          <div
            className="dropdown-toggle-23 w-dropdown-toggle"
            // id="w-dropdown-toggle-1"
            aria-controls="w-dropdown-list-1"
            aria-haspopup="menu"
            aria-expanded="false"
            role="button"
            tabIndex="0"
            onClick={() => {
              setDisplayDropdown(!displayDropdown);
            }}
          >
            <div className="text-block-445">login</div>
          </div>
          <nav
            id="w-dropdown-list-1"
            
            aria-labelledby="w-dropdown-toggle-1"
            className={
              "dropDownLogin " +
              (displayDropdown ? "w--open" : "")
            }
          >
            <LogIn />
            <div data-hover="" data-delay="0" className="dropdown-28 w-dropdown">
              { /* <div
                className={"dropdown-toggle-25 w-dropdown-toggle " + (signupDropdown ? 'w--open' : '')}
                id="w-dropdown-toggle-2"
                aria-controls="w-dropdown-list-2"
                aria-haspopup="menu"
                aria-expanded="false"
                role="button"
                tabIndex="0"
                onClick={() => {
                  console.log('click');
                  setSignupDropdown(!signupDropdown)
                }}
              >
                <div className="text-block-460">SignUp</div>
                <img
                  src={plusIcon}
                  loading="lazy"
                  width="9"
                  height="9"
                  alt=""
                  className="image-170"
                />
              </div> */}
              <SignUp isOpen={signupDropdown} />
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
