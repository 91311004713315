export const updateTableAttributes = `
mutation updateAttrubutesTable($input: InputAttributesToUpdate!) {
    updateAttributes(attributesToUpdate: $input) {
      tableAttributes {
        hasNotification
        allOrdersFinished
        tableName
      }
    }
  }
  `
;

// attributesToUpdate: InputAttributesToUpdate!
export const updateOrderAttributes = `
mutation updateAttributesOrder($input: InputAttributesToUpdate!) {
  updateAttributes(attributesToUpdate: $input) {
    productAttributes {
      isCooked
      isCooking
      orderId
      finishedItemTs
    }
  }
}

`

// {tableAttributes: {}, orderAttributes: {isCooked: false, isCooking: false, orderId: "", productName: ""}, updateType: ORDER, userId: ""}


export const confirmWaiterCall = `
mutation confirmWaiterCall($input: confirmWaiterInput!) {
  confirmWaiterCall(confirmWaiterInput: $input) {
    table {
      hasCustomerCall
      tableName
      userId
    }
    userId
  }
}
  `
;


export const updateOrderCompleteDuration = `
mutation onOrderCompleteDuration($input: InputAttributesToUpdate!) {
  updateAttributes(attributesToUpdate: $input) {
    userId
    updateType
    orderAttributes {
      orderDurationTime
      orderId
    }
  }
}

`

// {orderAttributes: {orderDurationTime: 5000, orderId: "4vgQI2Io5"}, updateType: ORDER, userId: "43d2c0c2-60cd-40c0-85c4-326b6ea746a6"}


  // {tableAttributes: {hasNotification: false, tableName: ""}, userId: "", updateType: TABLE}